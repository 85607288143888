import React, { FunctionComponent } from 'react';
import styled from 'styled-components';

interface LoadingProps {
  readonly width?: string;
  readonly height?: string;
}

const Container = styled.div<LoadingProps>`
  display: flex;
  flex-shrink: 0;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: ${(props) => props.width ?? '100%'};
  height: ${(props) => props.height ?? '100%'};
  background: ${(props) => props.theme.lightBlue};
`;

const Line = styled.span`
  display: inline-block;
  font-size: 13px;
  font-weight: bold;
  color: ${(props) => props.theme.marineBlue};
`;

export const LoaderArea: FunctionComponent<LoadingProps> = (props) => (
  <Container width={props.width} height={props.height}>
    <Line>Please wait...</Line>
    <Line>Loading image</Line>
  </Container>
);
