import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { Close as CloseIcon } from '@instech/icons';
import * as constants from './constants';
import { ClearButton, SearchInput } from './Components';

const InputWrapper = styled.div`
  position: relative;
  flex-grow: 1;
  display: flex;
`;

interface Props {
  onReset: () => void;
  onSearch: (text: string) => any;
  hintText?: string;
  hasLeftActions?: boolean;
}
export const Search = ({ onReset, onSearch, hintText = 'Search', hasLeftActions = false }: Props) => {
  const [searchText, setSearchText] = useState('');

  const inputEl = useRef<HTMLInputElement>(null);
  useEffect(() => {
    if (inputEl.current) {
      inputEl.current.focus();
    }
  }, []);

  const searchTextChanged = (newSearchText: string) => {
    setSearchText(newSearchText);
  };

  const search = (text: string) => {
    onSearch(text);
  };

  const reset = () => {
    if (searchText) {
      onReset();
      setSearchText('');
    }
  };

  const keyUpHandler = (event: React.KeyboardEvent) => {
    if (event.key === constants.ENTER_KEY) {
      search(searchText);
    } else if (event.key === constants.ESCAPE_KEY) {
      reset();
    }
  };

  return (
    <>
      <InputWrapper>
        <ClearButton
          type="button"
          hidden={searchText === ''}
          roundLeft={hasLeftActions}
          onClick={reset}
          disabled={searchText === ''}
        >
          <CloseIcon />
        </ClearButton>
        <SearchInput
          aria-label="Search"
          ref={inputEl}
          type="text"
          placeholder={hintText}
          onChange={(e) => {
            searchTextChanged(e.target.value);
            search(e.target.value);
          }}
          onKeyUp={keyUpHandler}
          value={searchText}
        />
      </InputWrapper>
    </>
  );
};
