import React from 'react';
import { Guid } from '../../../types';
import { useAnnouncementIdFromUrl } from '../../../hooks/useAnnouncementIdFromUrl';
import { useImageList } from '../../../services/imageServices/imageServices';
import { useModalContext } from '../../../components/modal/ModalContext';
import { Loader } from '../../shared/Loader';
import { ModalHandler } from '../../../components/modal/Modal';
import { ImageInsert } from './views/ImageInsert';
import { UploadImageContainer } from './core/UploadImageContainer';

interface ImagesModalProps {
  selectedImages: Guid[];
  selectOneImage: boolean;
  onSelect: (ids: Guid[]) => void;
}
export const ImagesModal = ({ selectedImages, selectOneImage, onSelect }: ImagesModalProps) => {
  const announcementId = useAnnouncementIdFromUrl();
  const { images, deleteImage } = useImageList(announcementId);
  const { close } = useModalContext();

  const insertImagesHandler = async (ids: Guid[]) => {
    onSelect(ids);
    close();
  };

  if (!images) return <Loader />;

  return (
    <ModalHandler>
      <UploadImageContainer />
      <ImageInsert
        imageIds={images}
        selectOneImage={selectOneImage}
        selectedImages={selectedImages}
        insertImages={insertImagesHandler}
        deleteImage={deleteImage}
      />
    </ModalHandler>
  );
};
