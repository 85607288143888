import { ColumnMetadataProps, HeaderItemProps, FacetProps } from '../../types';

const getColumnFromMetadata = (propertyName: string, metadata: ColumnMetadataProps[]) =>
  metadata
    .filter((column) => column.propertyName === propertyName)
    .map((column) => column.facets)
    .flat()
    .map((item) => item.value);

const getFacetsFromMetadata = (propertyName: string, metadata: ColumnMetadataProps[]): FacetProps[] => {
  const column = getColumnFromMetadata(propertyName, metadata);
  const facets = column.filter((facet) => facet !== '').map((facet) => ({ id: facet, label: facet }));
  return facets;
};

export const getCompaniesHeaderItems = (metadata: ColumnMetadataProps[]): HeaderItemProps[] => {
  const headerItems: HeaderItemProps[] = [
    {
      facets: null,
      title: 'Company Name',
      property: 'selectedCompanyNames',
      filterable: false,
      sortProperty: 'Name',
    },
    {
      facets: getFacetsFromMetadata('Type', metadata),
      title: 'Type',
      property: 'selectedTypes',
      filterable: true,
      sortProperty: 'Type',
    },
    {
      facets: null,
      title: 'CRM Number',
      property: 'selectedCompanyNumbers',
      filterable: false,
      sortProperty: 'CompanyNumber',
    },
    {
      facets: getFacetsFromMetadata('City', metadata),
      title: 'City',
      property: 'selectedCities',
      filterable: true,
      sortProperty: 'City',
    },
    {
      facets: getFacetsFromMetadata('Country', metadata),
      title: 'Country',
      property: 'selectedCountries',
      filterable: true,
      sortProperty: 'Country',
    },
    {
      facets: null,
      title: 'Email',
      property: 'selectedEmails',
      filterable: false,
    },
  ];

  return headerItems;
};
