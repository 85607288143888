import React, { createContext, useContext, useMemo } from 'react';
import useSWR from 'swr';

export interface Props {
  children: React.ReactNode;
}

interface FeatureFlags {
  updatesTab: boolean;
  portalSelector: boolean;
}

export type FeatureNames = keyof FeatureFlags;

const featureFlagRoute = '/featureFlags';

const FeatureFlagContext = createContext({} as FeatureFlags);

export const FeatureFlagProvider = ({ children }: Props) => {
  const { data } = useSWR<FeatureFlags>(featureFlagRoute);

  const value = useMemo(() => data || {}, [data]) as FeatureFlags;

  return (
    <FeatureFlagContext.Provider value={value}>
      {children}
    </FeatureFlagContext.Provider>
  );
};

export const useFeatureFlagsContext = () =>
  useContext<FeatureFlags>(FeatureFlagContext);

export const useFeatureFlag = (key: FeatureNames) => {
  const ctx = useFeatureFlagsContext();
  return !!ctx[key];
};
