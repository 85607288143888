import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { CompanyPage } from './components/CompanyPage/CompanyPage';
import { ProtectedRoute } from './authentication/routing/ProtectedRoute';
import { NoAccessPage } from './authentication/pages/NoAccessPage';
import { UserPage } from './components/UserPage/UserPage';
import { Onboarding } from './components/OnboardingTab/Onboarding';
import { AnnouncementsPage } from './components/AnnouncementsPage/AnnouncementsPage';
import { NewAnnouncementRoutes } from './components/NewAnnouncementPage/NewAnnouncementPage';
import { UpdatesPage } from './UpdatesPage';

export const App = () => {
  return (
    <Routes>
      <Route element={<ProtectedRoute />}>
        <Route path="/" element={<Onboarding />} />
        <Route path="/companies" element={<Onboarding />} />
        <Route path="/users" element={<Onboarding />} />
        <Route path="/companies/:companyId" element={<CompanyPage />} />
        <Route path="/users/:userId?" element={<UserPage />} />
        <Route path="/announcementsPage" element={<AnnouncementsPage />} />
        <Route path="/announcements" element={<UpdatesPage />} />
        {NewAnnouncementRoutes()}
      </Route>
      <Route path="/no-access" element={<NoAccessPage />} />
    </Routes>
  );
};
