import React from 'react';
import styled from 'styled-components';
import { useField } from 'formik';
import { WarningFilled } from '@instech/icons';
import { UploadImageButton } from '../../core/ProgressButtons';
import { CardImage } from './CardImage';

const ImageCardField = styled.div`
  display: flex;
  align-items: flex-end;
  gap: 17px;
`;

const Wrapper = styled.div`
  width: fit-content; // To align the error message
`;

const ErrorMessage = styled.span`
  display: flex;
  gap: 3px;
  height: 15px;
  margin-top: 5px;
  color: ${(props) => props.theme.warning};
  font-size: 14px;
`;

const ErrorComponent = ({ message }: { message: string }) => (
  <>
    <WarningFilled />
    {message}
  </>
);

export const CardImageField = ({ name, onClick }: { name: string; onClick: () => void }) => {
  const [field, meta, helpers] = useField(name);

  const handleClick = () => {
    helpers.setTouched(true);
    onClick();
  };

  return (
    <Wrapper>
      <ImageCardField>
        <CardImage imageId={field.value} />
        <UploadImageButton onClick={handleClick} />
      </ImageCardField>
      <ErrorMessage>{meta.error && meta.touched ? <ErrorComponent message={meta.error} /> : ''}</ErrorMessage>
    </Wrapper>
  );
};
