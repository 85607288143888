import { Update as UpdatesPageWidget } from 'insify-remote-component-loader';
import { useNavigate } from 'react-router';
import { createBrowserHistory } from 'history';
import { useAuthContext } from './authentication/AuthContext';

export const UpdatesPage = () => {
  const { account } = useAuthContext();
  const navigate = useNavigate();
  const history = createBrowserHistory();
  history.push = (to, state) => navigate(to, state);
  history.replace = (to, state) => navigate(to, { ...state, replace: true });
  const portal = {
    displayName: 'Administration',
    name: 'Administration',
  };

  return <UpdatesPageWidget account={account} history={history} portal={portal} />;
};
