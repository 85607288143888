import React, { useState } from 'react';
import * as Yup from 'yup';
import { Formik, Form } from 'formik';
import { ButtonGroup, SlimButton, TextField } from '@instech/components';
import { ModalLayout } from './ModalLayout';
import { Check } from '@instech/icons';
import { SuccessMessageWrapper, ErrorMessageWrapper, Column } from '../shared/Components';
import { updateCompanyAsync } from '../../services/companiesService';
import { Company } from '../../types';
import { useLocalStorage, currentTime } from '../../hooks/useLocalStorage';

interface Props {
  company: Company;
  handleCloseModal: () => void;
  setRefreshCompaniesCount?: React.Dispatch<React.SetStateAction<number>>;
  mutate?: () => void;
}
export const EditCompanyModal = ({ company, handleCloseModal, mutate, setRefreshCompaniesCount }: Props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isError, setIsError] = useState(false);
  const { getItem, setItem } = useLocalStorage('companies');
  const localStorageCompanies = getItem() || [];

  const modalOptions = {
    size: 'medium',
    title: 'Edit Company',
  };

  const initialValues = {
    id: company?.id ?? '',
    name: company?.name ?? '',
    type: company?.type ?? '',
    companyNumber: company?.companyNumber ?? '',
    city: company?.city ?? '',
    country: company?.country ?? '',
    email: company?.email ?? '',
  };
  const handleSubmit = async (values: Company) => {
    setIsError(false);
    setIsLoading(true);
    try {
      const response = await updateCompanyAsync(values);
      const updatedCompany = {
        ...response,
        isLocalStorageItem: true,
        timestamp: currentTime,
      };
      const restCompanies = localStorageCompanies.filter((c: Company) => c.id !== updatedCompany.id);
      const updatedCompanies = [updatedCompany, ...restCompanies];
      setItem(updatedCompanies);
      setIsSubmitted(true);
      setIsLoading(false);
      mutate && mutate();
      setRefreshCompaniesCount && setRefreshCompaniesCount((prev) => prev + 1);
    } catch (err) {
      console.error(err);
      setIsError(true);
      setIsSubmitted(false);
      setIsLoading(false);
    }
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().max(100, 'Must be 100 characters or less').required('Required'),
    type: Yup.string().max(50, 'Must be 50 characters or less').required('Required'),
    companyNumber: Yup.string().required('Required'),
    externalId: Yup.string().uuid('Invalid UUID'),
    city: Yup.string(),
    country: Yup.string(),
    email: Yup.string().email('Invalid email address'),
  });

  return (
    <ModalLayout closeModal={handleCloseModal} options={modalOptions}>
      <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={validationSchema}>
        {() => {
          return (
            <Form>
              <Column>
                <TextField name="name" label="Name" required placeholder="Name" />
                <TextField name="type" label="Type" required placeholder="Type" />
                <TextField
                  name="companyNumber"
                  label="Company Number"
                  required
                  placeholder="Company Number"
                  disabled={true}
                />
                <TextField name="city" label="City" placeholder="City" />
                <TextField name="country" label="Country" placeholder="Country" />
                <TextField name="email" label="Email Address" placeholder="Email" />
              </Column>
              <Column>
                <ButtonGroup alignRight buttonGap="16px" marginTop="32px">
                  <SlimButton variant="secondary" onClick={handleCloseModal}>
                    CANCEL
                  </SlimButton>
                  <SlimButton type="submit" loading={isLoading}>
                    SAVE
                  </SlimButton>
                </ButtonGroup>
                {isSubmitted && (
                  <SuccessMessageWrapper>
                    <Check />
                    Successfully saved.
                  </SuccessMessageWrapper>
                )}
                {isError && <ErrorMessageWrapper>Something went wrong.</ErrorMessageWrapper>}
              </Column>
            </Form>
          );
        }}
      </Formik>
    </ModalLayout>
  );
};
