import React from 'react';
import styled from 'styled-components';

const StyledReactTooltip = styled.div`
  &.marineBlueTooltip {
    padding: 8px 12px;
    &.show {
      opacity: 1;
      max-width: 230px;
    }
  }
`;

interface RoleTooltipProps {
  id: string;
}

export const RoleTooltip = ({ id }: RoleTooltipProps) => <StyledReactTooltip id={id} className="marineBlueTooltip" />;
