import { SelectedUser } from '../components/UsersPage/userTypes';

export const sortUsers = (a: SelectedUser, b: SelectedUser) => {
  if (a.name < b.name) {
    return -1;
  }
  if (a.name > b.name) {
    return 1;
  }

  return 0;
};
