import React from 'react';
import { Search } from './core/Search';
import { SearchWrapper } from './core/Components';

interface Props {
  onSearch: (text: string | null) => void;
  onReset: () => void;
}

export const UserSearch = ({ onSearch, onReset }: Props) => {
  return (
    <SearchWrapper>
      <Search onReset={onReset} onSearch={onSearch} />
    </SearchWrapper>
  );
};
