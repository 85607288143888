import axios, { AxiosError, AxiosResponse } from 'axios';

const client = axios.create({
  baseURL: '/api/',
  timeout: 30000,
});

client.defaults.headers.common['x-csrf'] = '1';
client.defaults.headers.common['Content-Type'] = 'application/json';

export const getJsonAsync = <Response = any>(endpoint: string, params?: any) =>
  client.get<Response>(endpoint, { params }).then((response) => response.data);

export const getJsonAsyncWithHeaders = <Response = any>(endpoint: string, params?: any) =>
  client.get<Response>(endpoint, { params });

export const postJsonAsync = <T = any>(endpoint: string, payload: any) =>
  client
    .post<T>(endpoint, payload)
    .then((response) => response.data)
    .catch((error) => {
      console.error('An error occurred during the POST request:', error);
      throw error;
    });

export const deleteJsonAsync = <T = any>(endpoint: string, payload: any) =>
  client
    .delete<T>(endpoint, { data: payload })
    .then((response) => response.data)
    .catch((error) => {
      console.error('An error occurred during the POST request:', error);
      throw error; // Re-throw the error to indicate that an error occurred.
    });

export const postAsync = (endpoint: string) => client.post(endpoint);

export function putJsonAsync<ReturnType = any>(endpoint: string, payload: any) {
  return client.put<ReturnType>(endpoint, payload).then((response) => response.data);
}

export const postFormAsync = (endpoint: string, form: any) =>
    client
        .post(endpoint, form, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        })
        .then((response) => response.data);

export function putFormAsync<ReturnType = any>(endpoint: string, form: any) {
  return client
    .put<ReturnType>(endpoint, form, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    .then((response) => response.data);
}

function timeout(ms: number) {
  return new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
}

const defaultErrorRetryCount = 10;
const defaultErrorRetryInterval = 800;

function defaultRetryCondition<ReturnType>(response?: AxiosResponse<ReturnType>) {
  return response?.status === 404;
}

export interface RetryConfig<ReturnType> {
  retryCount?: number;
  errorRetryInterval?: number;
  retryCondition?: (errResponse: AxiosResponse<ReturnType>) => boolean;
}

export async function getWithRetry<ReturnType = any>(
  endpoint: string,
  config?: RetryConfig<ReturnType>
): Promise<AxiosResponse<ReturnType>> {
  const retryCondition = config?.retryCondition ?? defaultRetryCondition;
  const retryCount = config?.retryCount ?? defaultErrorRetryCount;
  const retryInterval = config?.errorRetryInterval ?? defaultErrorRetryInterval;

  let response: AxiosResponse<ReturnType>;
  try {
    response = await client.get<ReturnType>(endpoint);
  } catch (err) {
    const typeErr = err as AxiosError<ReturnType>;

    if (!typeErr.response) throw typeErr;
    if (retryCount === 0 || !retryCondition(typeErr.response)) throw typeErr;
    response = typeErr.response;
  }

  const shouldRetry = retryCount !== 0 && retryCondition(response);

  if (shouldRetry) {
    await timeout(retryInterval);
    const nextConfig = {
      ...config,
      retryCount: retryCount - 1,
    };
    return getWithRetry(endpoint, nextConfig);
  }

  return response;
}
export default client;
