import React, { useState } from 'react';
import { SlimButton, CollapsiblePane, LinkButton } from '@instech/components';
import { Check } from '@instech/icons';
import {
  SelectedUser,
  AuthenticationMethodsProps,
  UserAuthMethodProps,
  UserIdentityProps,
  ActionType,
} from '../UsersPage/userTypes';
import { postUsersAuthenticationMethods, deleteUsersAuthenticationMethods } from '../../services/authenticationMethods';
import { SuccessMessageWrapper, ErrorMessageWrapper, StyledButtonGroup } from '../shared/Components';
import { useLocalStorage, currentTime } from '../../hooks/useLocalStorage';

const updateSignInMethods = (user: SelectedUser, methodId: string, methodName: string, action: ActionType) => {
  let newUser = user;
  if (action === 'Removal') {
    newUser = {
      ...user,
      identities: user.identities.filter((method: UserIdentityProps) => method.id !== methodId),
    };
  } else {
    newUser = {
      ...user,
      identities: user.identities
        ? [{ id: methodId, displayName: methodName }, ...user.identities]
        : [{ id: methodId, displayName: methodName }],
    };
  }
  return newUser;
};

interface SignInMethodProps {
  selectedUsers: SelectedUser[];
  setRefreshUsersCount?: React.Dispatch<React.SetStateAction<number>>;
  handleCloseModal: () => void;
  allAuthenticationMethods: AuthenticationMethodsProps[];
}

export const SignInMethods = ({
  selectedUsers,
  allAuthenticationMethods,
  setRefreshUsersCount,
  handleCloseModal,
}: SignInMethodProps) => {
  const [clickedButtonId, setClickedButtonId] = useState<string | null>(null);
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const { setItem, getItem } = useLocalStorage('users');
  const localStorageUsers: SelectedUser[] | [] = getItem() ?? [];

  const changeUsersSignInMethods = async (
    authenticationMethodId: string,
    authenticationMethodDisplayName: string,
    action: ActionType
  ) => {
    const usersToChange = selectedUsers
      .filter((user) => {
        const hasMethod = user.identities?.some((method: UserAuthMethodProps) => method.id === authenticationMethodId);
        return action === 'Provisioning' ? !hasMethod : hasMethod;
      })
      .map((user) => updateSignInMethods(user, authenticationMethodId, authenticationMethodDisplayName, action));

    const filteredLocalstorageUsers = localStorageUsers.filter(
      (localStorageUser) =>
        !usersToChange.some((userToChange: SelectedUser) => userToChange.subjectId === localStorageUser.subjectId)
    );

    const payload = usersToChange.map((u) => ({
      authenticationMethodId,
      subjectId: u?.subjectId,
    }));

    setClickedButtonId(authenticationMethodId);
    setIsError(false);
    setIsSubmitted(false);

    try {
      switch (action) {
        case 'Removal': //Remove
          await deleteUsersAuthenticationMethods(payload);
          setClickedButtonId(null);
          break;
        case 'Provisioning': //Add
          await postUsersAuthenticationMethods('', payload);
          break;
        case 'PasswordManagement': // Reset password
          await postUsersAuthenticationMethods('/reset/password', payload);
          break;
        case 'MfaManagement': //reset MFA
          await postUsersAuthenticationMethods('/reset/mfa', payload);
          break;

        default:
          break;
      }
      setItem([...usersToChange, ...filteredLocalstorageUsers]);
      const updatedLocalStorageUsers = usersToChange.map((u) => ({
        ...u,
        isLocalStorageItem: true,
        timestamp: currentTime,
      }));
      setItem([...updatedLocalStorageUsers, ...filteredLocalstorageUsers]);
      setRefreshUsersCount && setRefreshUsersCount((prev) => prev + 1);
      setIsSubmitted(true);
      setTimeout(() => {
        handleCloseModal();
      }, 2000);
    } catch (error) {
      if (error instanceof Error) {
        console.error(error.message);
        setErrorMessage(error.message);
      } else {
        console.error('An unknown error occurred');
        setErrorMessage('An unknown error occurred');
      }
      setIsError(true);
      setIsSubmitted(false);
    }
  };

  //if user is in local storage, use it in selectedUsers
  selectedUsers = selectedUsers.map((user) => {
    const localStorageUser = localStorageUsers.find(
      (localStorageUser) => localStorageUser.subjectId === user.subjectId
    );
    return localStorageUser ? localStorageUser : user;
  });

  const everyUserHasMethod = (id: string) =>
    selectedUsers.every((user) => user.identities?.find((method: UserAuthMethodProps) => method.id === id));
  const someUsersHaveMethod = (id: string) =>
    selectedUsers.some((user) => user.identities?.find((method: UserAuthMethodProps) => method.id === id));

  const capabilityLabels = {
    MfaManagement: 'Reset MFA',
    PasswordManagement: 'Reset Password',
    Removal: 'Remove',
    Provisioning: 'Add',
    SyncTo: 'Sync To',
    SyncFrom: 'Sync From',
    LinkingExternalSub: 'Link External Sub',
  };

  return (
    <>
      {allAuthenticationMethods?.map((method: AuthenticationMethodsProps) => {
        const isAddButtonDisabled = everyUserHasMethod(method.id);
        const hasCapabilities = method.capabilities.some(
          (item) => !['SyncFrom', 'SyncTo', 'LinkingExternalSub'].includes(item)
        );
        const isRemoveButtonEnabled = someUsersHaveMethod(method.id);
        return (
          <div key={method.id}>
            {hasCapabilities ? (
              <CollapsiblePane title={method.displayName} color="blue" startCollapsed={false}>
                {method.capabilities?.includes('Provisioning') && (
                  <SlimButton
                    disabled={(clickedButtonId === method.id && !isError) || isAddButtonDisabled}
                    onClick={() => changeUsersSignInMethods(method.id, method.displayName, 'Provisioning')}
                  >
                    Add {method.displayName}
                  </SlimButton>
                )}
                {(isAddButtonDisabled || isRemoveButtonEnabled) && (
                  <StyledButtonGroup marginTop="0">
                    {method.capabilities?.map((capability: ActionType) => {
                      const buttonLabel = capabilityLabels[capability] || capability;
                      if (['MfaManagement', 'PasswordManagement', 'Removal'].includes(capability)) {
                        return (
                          <LinkButton
                            underline
                            key={capability}
                            onClick={() => changeUsersSignInMethods(method.id, method.displayName, capability)}
                          >
                            {buttonLabel}
                          </LinkButton>
                        );
                      } else {
                        return null;
                      }
                    })}
                  </StyledButtonGroup>
                )}
                {isError && clickedButtonId === method.id && (
                  <ErrorMessageWrapper>Something went wrong. {errorMessage} </ErrorMessageWrapper>
                )}
                {isSubmitted && clickedButtonId === method.id && (
                  <SuccessMessageWrapper>
                    <Check />
                    Updated successfully.
                  </SuccessMessageWrapper>
                )}
              </CollapsiblePane>
            ) : null}
          </div>
        );
      })}
    </>
  );
};
