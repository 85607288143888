import React, { ReactNode } from 'react';
import { Link } from 'react-router-dom';
import { Edit, FileParagraph, Trash } from '@instech/icons';
import { LinkButton } from './LinkButton';

interface ButtonProps {
  onClick: () => void;
}

export const RemoveButton = ({ onClick }: ButtonProps) => (
  <LinkButton startIcon={<Trash />} onClick={onClick}>
    Remove
  </LinkButton>
);

// With text
export const EditButton = ({ onClick }: ButtonProps) => (
  <LinkButton startIcon={<Edit />} onClick={onClick}>
    Edit
  </LinkButton>
);

// Icon only
export const EditIconButton = ({ onClick }: ButtonProps) => (
  <LinkButton startIcon={<Edit />} onClick={onClick} children={undefined} />
);

interface NavLinkButtonProps {
  children: ReactNode;
  startIcon?: ReactNode;
  endIcon?: ReactNode;
  width?: string;
  height?: string;
  underline?: boolean;
  to: string;
  color?: string;
  className?: string;
  title?: string;
}

export const NavLinkButton = ({
  children,
  startIcon: StartIcon,
  endIcon: EndIcon,
  width,
  height,
  underline,
  to,
  color,
  className,
  title,
}: NavLinkButtonProps) => (
  <Link to={to}>
    <LinkButton
      startIcon={StartIcon}
      endIcon={EndIcon}
      width={width}
      height={height}
      underline={underline}
      color={color}
      className={className}
      title={title}
    >
      {children}
    </LinkButton>
  </Link>
);

interface NavIconProps {
  to: string;
  color?: string;
  className?: string;
}
interface NavPreviewIconProps extends NavIconProps {
  title?: string;
}
export const NavEditIcon = ({ to, color, className }: NavIconProps) => (
  <NavLinkButton to={to} startIcon={Edit} color={color} className={className} children={undefined} />
);

export const NavPreviewIcon = ({ to, color, className, title }: NavPreviewIconProps) => (
  <NavLinkButton
    to={to}
    startIcon={FileParagraph}
    color={color}
    className={className}
    title={title}
    children={undefined}
  />
);
