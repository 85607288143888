import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';

interface StyledProps {
  width: string;
  padding: string;
  wrapText?: boolean;
}
const StyledText = styled.span<StyledProps>`
  display: block;
  max-width: ${(props) => props.width};
  padding: ${(props) => props.padding};
  overflow: hidden;
  text-overflow: ellipsis;
  ${(props) =>
    !props.wrapText &&
    css`
      white-space: nowrap;
    `}
`;

interface Props {
  text: string;
  width?: string;
  padding?: string;
  wrap?: boolean;
}
export const TruncatingText = ({ text, width = '100%', padding = '0px', wrap = false }: Props) => (
  <StyledText title={text} width={width} padding={padding} wrapText={wrap}>
    {text}
  </StyledText>
);

const truncateMiddle = (text: string, maxLen: number) => `${text.slice(0, maxLen / 2)}...${text.slice(-maxLen / 2)}`;

export const TruncatingTextMiddle = ({ text, maxLen }: { text: string; maxLen: number }) => {
  const [formattedText, setFormattedText] = useState(text);

  useEffect(() => {
    if (text.length > maxLen) {
      setFormattedText(truncateMiddle(text, maxLen));
    }
  }, [text, maxLen]);

  return <span title={text}>{formattedText}</span>;
};
