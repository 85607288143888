import React, { useEffect, useMemo } from 'react';
import styled from 'styled-components';
import { useParams } from 'react-router';
import { Formik, FieldArray, Form, FormikHelpers, FormikProps } from 'formik';
import { ButtonGroup } from '@instech/components';
import { objectsDeepEqual } from '../../../utils/object';
import { ModernPane } from '../../shared/Pane/ModernPane';
import { useAnnouncement } from '../../../services/announcementsServices';
import { Loader } from '../../shared/Loader';
import { UpdateApplication } from '../../../types';
import { useRedirect } from '../../../hooks/useRedirect';
import { NewAnnouncementProgress } from '../core/NewAnnouncementProgress';
import { MainColumn, PageFlex, Wrapper } from '../core/Components';
import { CancelButton, NextButton, ProgressButtons } from '../core/ProgressButtons';
import { RecipientGroup } from './RecipientGroup';
import { calcualteRecipients, getInitialValues, hasValidValues } from './utils';
import { useProgress } from '../core/ProgressContext';
import { Step } from '../NewAnnouncementPage';
import { getActualAnnouncementState, useAnnouncementState } from '../core/AnnouncementStateContext';
import { getReturnToCallBack } from '../utils';

export type FormValue = {
  providers: string[];
  applications: string[];
  [k: string]: string[]; // Roles for each application, one key per application
};

interface ConditionalFieldsProps {
  formik: FormikProps<FormValue>;
  applications: UpdateApplication[];
}
const ConditionalFields = ({ formik, applications }: ConditionalFieldsProps) => {
  const { values } = formik;

  const applicationRoles = applications.map((application) => ({
    title: application.displayName,
    name: application.name,
    options: application.roles,
  }));

  const applicationsToRender = useMemo(
    () => applicationRoles.filter((app) => values.applications.some((val: string) => app.title === val)),
    [applicationRoles, values.applications]
  );

  // A little bit of trickery to, whenever the Products change, see if the unchecked
  // product had a key storing its roles. If yes, purge said key to clear the roles
  useEffect(() => {
    const looseKeys = Object.keys(values).filter((key) => {
      if (key === 'applications' || key === 'providers') return false;
      return !values.applications.includes(key);
    });

    looseKeys.forEach((key) => formik.setFieldValue(key, undefined));
  }, [values.applications]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <FieldArray
      name="roles"
      render={() =>
        applicationsToRender.map((application) => (
          <RecipientGroup
            key={`${application.title}-roles`}
            title={application.title}
            fieldname={application.title}
            options={application.options}
          />
        ))
      }
    />
  );
};

interface Props {
  steps: Step[];
  stepNum: number;
  handleCancel: (val?: any) => void;
}
export const SelectRecipients = ({ steps, stepNum, handleCancel }: Props) => {
  const { announcementId } = useParams<{ announcementId: string }>();
  const { data: initialUpdate } = useAnnouncement(announcementId ?? '');
  const { next } = useProgress();
  const { updateState, state: currentUpdate, setDirty } = useAnnouncementState();
  const setShouldRedirect = useRedirect('/announcementsPage');

  if (!initialUpdate) return <Loader />;

  const actualUpdate = getActualAnnouncementState(initialUpdate, currentUpdate);
  const initialValues = getInitialValues(actualUpdate);

  // This probably needs to be a hook of some sort, if it's supposed to
  // get the number of recipients from the data selected in the form.
  const paneTitle = 'Select recipients';
  const subTitle = 'Final recipient count will be shown in the preview';

  const onSubmit = async (values: FormValue, helpers: FormikHelpers<FormValue>) => {
    const recipientStructure = calcualteRecipients(values, actualUpdate);
    const newValues = { ...actualUpdate, recipientStructure };
    updateState(newValues);

    // If there have been changes in this form, flag update as changed
    if (!objectsDeepEqual(values, initialValues)) setDirty(true);

    // Clean the form to avoid giving prompt when navigating to next page.
    helpers.resetForm({ values: getInitialValues(newValues) });
    next();
  };

  return (
    <Wrapper>
      <Formik initialValues={initialValues} onSubmit={onSubmit}>
        {(formikProps) => (
          <PageFlex>
            <MainColumn>
              <NewAnnouncementProgress steps={steps} currentStep={stepNum} />
              <Form>
                <ModernPane title={paneTitle} subTitle={subTitle} color="green">
                  <RecipientGroup
                    title="External / Internal"
                    fieldname="providers"
                    options={actualUpdate.recipientStructure.providers}
                  />
                  <RecipientGroup
                    title="Products"
                    fieldname="applications"
                    selectHideIfOne
                    options={actualUpdate.recipientStructure.applications}
                  />
                  <ConditionalFields formik={formikProps} applications={actualUpdate.recipientStructure.applications} />
                </ModernPane>
                <ButtonGroup alignRight marginTop="24px">
                  <NextButton disabled={!hasValidValues(formikProps.values)} onClick={formikProps.handleSubmit} />
                </ButtonGroup>
              </Form>
            </MainColumn>
            <ProgressButtons>
              <NextButton
                width="100%"
                disabled={!hasValidValues(formikProps.values)}
                onClick={formikProps.handleSubmit}
              />
              <CancelButton
                width="100%"
                onClick={() => handleCancel(getReturnToCallBack(setShouldRedirect, formikProps.resetForm))}
              />
            </ProgressButtons>
          </PageFlex>
        )}
      </Formik>
    </Wrapper>
  );
};
