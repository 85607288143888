import React, { useState } from 'react';
import * as Yup from 'yup';
import { Formik, Form, FormikValues } from 'formik';
import { ButtonGroup, SlimButton, TextField } from '@instech/components';
import { ModalLayout } from './ModalLayout';
import { Check } from '@instech/icons';
import { SuccessMessageWrapper, ErrorMessageWrapper, Column } from '../shared/Components';
import { createCompanyAsync } from '../../services/companiesService';
import { Company, SourceSystem } from '../../types';
import { useLocalStorage, currentTime } from '../../hooks/useLocalStorage';

interface Props {
  company?: Company;
  isCrmCompany?: boolean;
  handleCloseModal: () => void;
  setRefreshCompaniesCount: React.Dispatch<React.SetStateAction<number>>;
}

export const AddCompanyModal = ({ company, isCrmCompany, handleCloseModal, setRefreshCompaniesCount }: Props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>('');
  const { getItem, setItem } = useLocalStorage('companies');
  const localStorageCompanies = getItem() || [];

  const modalOptions = {
    size: 'medium',
    title: isCrmCompany ? 'Import from CRM' : 'Add New Company',
  };

  const initialValues = {
    name: company?.name ?? '',
    type: company?.type ?? '',
    companyNumber: company?.companyNumber ?? '',
    city: company?.city ?? '',
    country: company?.country ?? '',
    email: company?.email ?? '',
    externalId: company?.id ?? '',
  };
  const handleSubmit = async (values: FormikValues, resetForm: () => void) => {
    setIsError(false);
    setIsLoading(true);
    const submitValues: Company = {
      name: values.name,
      type: values.type === '' ? null : values.type,
      companyNumber: values.companyNumber,
      city: values.city === '' ? null : values.city,
      country: values.country === '' ? null : values.country,
      email: values.email === '' ? null : values.email,
      externalId: values.externalId === '' ? null : values.externalId,
    };
    try {
      const response = await createCompanyAsync(submitValues);
      const newCompany = {
        ...submitValues,
        id: response.id,
        sourceSystem: SourceSystem.Onboarding,
        isLocalStorageItem: true,
        timestamp: currentTime,
      };
      setItem([newCompany, ...localStorageCompanies]);
      setIsSubmitted(true);
      setIsLoading(false);
      !isCrmCompany && resetForm();
      setRefreshCompaniesCount((prev) => prev + 1);
    } catch (error) {
      let errorMessage = 'An unexpected error occurred';
      if (typeof error === 'object' && error !== null) {
        const axiosError = error as { response?: { data?: { Message?: string } } };
        errorMessage = axiosError.response?.data?.Message ?? errorMessage;
      }
      setErrorMessage(errorMessage);
      setIsError(true);
      setIsSubmitted(false);
      setIsLoading(false);
    }
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().max(100, 'Must be 100 characters or less').required('Required'),
    type: Yup.string().max(50, 'Must be 50 characters or less').required('Required'),
    companyNumber: Yup.string().required('Required'),
    externalId: Yup.string().uuid('Invalid UUID'),
    city: Yup.string(),
    country: Yup.string(),
    email: Yup.string().email('Invalid email address'),
  });

  const emptyField = company?.type === null;

  return (
    <ModalLayout closeModal={handleCloseModal} options={modalOptions}>
      <Formik
        initialValues={initialValues}
        onSubmit={(values, { resetForm }) => {
          handleSubmit(values, resetForm);
        }}
        validationSchema={validationSchema}
      >
        {() => {
          return (
            <Form>
              <Column>
                <TextField name="name" label="Name" required placeholder="Name" disabled={isCrmCompany} />
                <TextField
                  name="type"
                  label="Type"
                  required
                  placeholder="Type"
                  disabled={(isCrmCompany && !emptyField) || (isCrmCompany && isSubmitted)}
                />
                <TextField
                  name="companyNumber"
                  label="Company Number"
                  required
                  placeholder="Company Number"
                  disabled={isCrmCompany}
                />
                <TextField name="externalId" label="External Id" placeholder="External Id" disabled={isCrmCompany} />
                <TextField name="city" label="City" placeholder="City" disabled={isCrmCompany} />
                <TextField name="country" label="Country" placeholder="Country" disabled={isCrmCompany} />
                <TextField name="email" label="Email Address" placeholder="Email" disabled={isCrmCompany} />
              </Column>
              <Column>
                <ButtonGroup alignRight buttonGap="16px" marginTop="32px">
                  <SlimButton variant="secondary" onClick={handleCloseModal}>
                    CANCEL
                  </SlimButton>
                  <SlimButton type="submit" loading={isLoading}>
                    ADD COMPANY
                  </SlimButton>
                </ButtonGroup>
                {isSubmitted && (
                  <SuccessMessageWrapper>
                    <Check />
                    Successfully added a new company.
                  </SuccessMessageWrapper>
                )}
                {isError && <ErrorMessageWrapper>{errorMessage}</ErrorMessageWrapper>}
              </Column>
            </Form>
          );
        }}
      </Formik>
    </ModalLayout>
  );
};
