import React, { useState, PropsWithChildren, Dispatch, SetStateAction, useEffect } from 'react';
import styled, { css } from 'styled-components';
import { ButtonGroup, SlimButton } from '@instech/components';
import { TextFieldControlled, CheckboxControlled } from '@instech/components';
import { Search } from '@instech/icons';
import { FilterParams, HeaderItemProps, SelectedFilterFacetProps, FacetProps, RolesFacetsProps } from '../../types';
import { CharacterOrderIcon, CharacterReverseOrderIcon, CloseIcon } from '../shared/Components';

const StyledSearch = styled(Search)`
  color: ${(props) => props.theme.marineBlue50};
`;

const DropdownWrapper = styled.div<{ isVisible: boolean; right: boolean }>`
  width: 300px;
  border-radius: 3px;
  background-color: #fff;
  position: absolute;
  z-index: 3;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  display: ${(props) => (props.isVisible ? 'flex' : 'none')};
  flex-direction: column;
  padding: 1em;
  top: 40px;

  ${(props) =>
    props.right &&
    css`
      right: 0;
    `};
`;
const CheckboxGroupWrapper = styled.div`
  padding: 0 10px;
  p {
    padding-bottom: 4px;
    border-bottom: 2px solid ${(props) => props.theme.marineBlue};
  }
`;

const DropdownBackground = styled.div<{ isVisible: boolean }>`
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  position: fixed;
  display: ${(props) => (props.isVisible ? 'flex' : 'none')};
  z-index: 2;
`;

export const DropdownElement = styled.div<{
  first?: boolean;
  disabled?: boolean;
}>`
  color: ${(props) => (props.disabled ? props.theme.sanMarino25 : props.theme.marineBlue)};
  display: flex;
  align-items: center;
  ${(props) =>
    !props.disabled &&
    css`
      cursor: pointer;
    `};

  ${(props) =>
    !props.first &&
    css`
      margin-top: 1rem;
    `};
`;

const FacetWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const EmptyFacet = styled.div`
  padding: 2px 8px;
  margin: 0 8px;
  background-color: ${(props) => props.theme.ultraLightGray};
  border-radius: 4px;
  font-style: italic;
  color: ${(props) => props.theme.blueGray};
  justify-self: left;
`;

const FilterDropdownElement = styled(DropdownElement)`
  background-color: ${(props) => props.theme.whiteBlue};
  padding-right: 5px;
  padding-top: 8px;
  padding-bottom: 8px;
`;

const FilterList = styled.div`
  background-color: ${(props) => props.theme.whiteBlue};
  border-radius: 2px;
  max-height: 200px;
  overflow-y: auto;
  width: 100%;
  min-height: 100px;

  /* width */
  ::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }
  scrollbar-width: thin;

  /* Track */
  ::-webkit-scrollbar-track {
    background: ${(props) => props.theme.whiteBlue};
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: ${(props) => props.theme.whiteBlue};
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: ${(props) => props.theme.marineBlue};
  }

  input {
    margin: 5px 10px !important;
  }
`;

interface DropdownContainerProps {
  isVisible: boolean;
  clickOutside: (e: React.MouseEvent) => void;
  right: boolean;
}
export const DropdownContainer = ({
  clickOutside,
  isVisible,
  children,
  right,
}: PropsWithChildren<DropdownContainerProps>) => (
  <>
    <DropdownBackground isVisible={isVisible} onClick={clickOutside} />
    <DropdownWrapper isVisible={isVisible} right={right}>
      {children}
    </DropdownWrapper>
  </>
);

enum SortOrder {
  Ascending = 'asc',
  Descending = 'desc',
}
const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>, positive: () => void, negative: () => void) => {
  if (e.key === 'Enter') positive();
  else if (e.key === 'Escape') negative();
};

const addOrRemove = (array: SelectedFilterFacetProps[], value: SelectedFilterFacetProps) => {
  const isValueInArray = array.some((item) => item.id === value.id && item.property === value.property);
  if (isValueInArray) {
    return array.filter((item) => item.id !== value.id || item.property !== value.property);
  } else {
    return [...array, value];
  }
};

interface SearchFilterDropdownProps {
  isVisible: boolean;
  item: HeaderItemProps;
  close: () => void;
  filterParams: FilterParams;
  setFilterParams: Dispatch<SetStateAction<FilterParams>>;
  selectedFilterFacets: SelectedFilterFacetProps[];
  setSelectedFilterFacets: Dispatch<SetStateAction<SelectedFilterFacetProps[]>>;
  setSortOrder?: any;
}

export const SearchFilterDropdown = ({
  isVisible,
  item,
  close,
  filterParams,
  setFilterParams,
  selectedFilterFacets,
  setSelectedFilterFacets,
  setSortOrder,
}: SearchFilterDropdownProps) => {
  const [searchText, setSearchText] = useState<string>('');
  const [selectedFacets, setSelectedFacets] = useState<SelectedFilterFacetProps[]>(selectedFilterFacets);

  const { facets, title, property, sortProperty } = item;
  const initialFacets = title !== 'Roles' ? (facets as FacetProps[]) : null;
  const [currentFacets, setCurrentFacets] = useState<FacetProps[] | null>(initialFacets);

  const reset = () => {
    setSelectedFacets((prev) => {
      const newSelectedFacets = prev.filter((facet) => facet.property !== property);

      // Call apply after updating selectedFacets
      apply(newSelectedFacets);

      return newSelectedFacets;
    });
  };

  const cancel = () => {
    setSelectedFacets(selectedFilterFacets);
    close();
  };

  const changeSort = (order: 'asc' | 'desc') => {
    if (!sortProperty) return;
    const sortParam = sortProperty[0].toUpperCase() + sortProperty.slice(1);
    setFilterParams((prev) => ({
      ...prev,
      orderBy: `${sortParam} ${order}`,
    }));
    'asc' === order ? setSortOrder(SortOrder.Ascending) : setSortOrder(SortOrder.Descending);
    close();
  };

  const apply = (currentSelectedFacets: SelectedFilterFacetProps[]) => {
    if (item.type === 'Boolean') {
      const selectedFacet = currentSelectedFacets.find((facet) => facet.property === property);
      if (!!selectedFacet && (selectedFacet.value === true || selectedFacet.value === false)) {
        const propertyValue = selectedFacet.value;
        const newFilterParams = { ...filterParams, [property]: propertyValue };
        setFilterParams(newFilterParams);
        setSelectedFilterFacets((prev) => [...prev.filter((p) => p.property !== property), selectedFacet]);
      } else {
        const newFilterParams = { ...filterParams };
        delete newFilterParams[property];
        setFilterParams(newFilterParams);
        setSelectedFilterFacets((prev) => prev.filter((p) => p.property !== property));
      }
    } else {
      const newFilterPropertyValue = currentSelectedFacets
        .filter((facet) => facet.property === property)
        .map((facet) => facet.id);

      const newFilterParams = {
        ...filterParams,
        [property]: newFilterPropertyValue,
      };
      if (newFilterPropertyValue.length === 0) delete newFilterParams[property];
      setFilterParams(newFilterParams);
      setSelectedFilterFacets(currentSelectedFacets);
    }
    close();
  };

  const toggleFacet = (facet: FacetProps) => {
    const newSelectedFacets = addOrRemove(selectedFacets, {
      property,
      label: facet.label,
      id: facet.id,
    });
    item.type === 'Boolean'
      ? setSelectedFacets([{ property, label: facet.label, id: facet.id, value: facet.value }])
      : setSelectedFacets(newSelectedFacets);
  };

  const renderGroupedFacets = (facets: RolesFacetsProps) =>
    Object.keys(facets).map((key) => (
      <CheckboxGroupWrapper key={key}>
        <p>{key}</p>
        {facets[key as keyof typeof facets].map((facet: FacetProps) => (
          <FacetWrapper key={facet.id}>
            <CheckboxControlled
              labelIcon={null}
              name={facet.label}
              selected={selectedFacets.some((selected) => selected.id === facet.id && selected.property === property)}
              onChange={() => toggleFacet(facet)}
              rightLabel={facet.label}
              noTopLabel
              noErrors
            />
            {!facet && <EmptyFacet>No value</EmptyFacet>}
          </FacetWrapper>
        ))}
      </CheckboxGroupWrapper>
    ));

  const renderFacets = (facets: FacetProps[]) =>
    facets.map((facet, i) => (
      <FacetWrapper key={`${i}-${facet}`}>
        <CheckboxControlled
          labelIcon={null}
          name={facet.label}
          selected={selectedFacets.some((selected) => selected.label === facet.label && selected.property === property)}
          onChange={() => toggleFacet(facet)}
          rightLabel={facet.label}
          noTopLabel
          noErrors
        />
        {!facet && <EmptyFacet>No value</EmptyFacet>}
      </FacetWrapper>
    ));

  const handleInput = (e: any) => {
    const newText = e.target.value.toLowerCase();
    setSearchText(newText);
  };

  useEffect(() => {
    const newFacets =
      facets && title !== 'Roles'
        ? (facets as FacetProps[]).filter((item) => item.label.toLowerCase().includes(searchText))
        : null;
    setCurrentFacets(newFacets);
  }, [searchText]);

  const positionRight = item?.position === 'right';

  return (
    <DropdownContainer clickOutside={close} isVisible={isVisible} right={positionRight}>
      {sortProperty && (
        <>
          <DropdownElement
            tabIndex={0}
            onKeyDown={(e: React.KeyboardEvent<HTMLDivElement>) =>
              handleKeyDown(e, () => changeSort(SortOrder.Ascending), cancel)
            }
            onClick={() => changeSort(SortOrder.Ascending)}
            first
          >
            <CharacterOrderIcon />
            Sort A to Z
          </DropdownElement>
          <DropdownElement
            tabIndex={0}
            onKeyDown={(e) => handleKeyDown(e, () => changeSort(SortOrder.Descending), cancel)}
            onClick={() => changeSort(SortOrder.Descending)}
          >
            <CharacterReverseOrderIcon />
            Sort Z to A
          </DropdownElement>
        </>
      )}
      <DropdownElement tabIndex={0} onKeyDown={(e) => handleKeyDown(e, reset, cancel)} onClick={reset}>
        <CloseIcon />
        {`Clear filter for ${title}`}
      </DropdownElement>
      {item.type !== 'Boolean' && title !== 'Roles' && (
        <DropdownElement>
          <TextFieldControlled
            name="search"
            noLabel
            onChange={handleInput}
            placeholder="Search"
            startIcon={<StyledSearch />}
            useSpellcheck
            value={searchText || ''}
          />
        </DropdownElement>
      )}

      <FilterDropdownElement>
        {title !== 'Roles' && <FilterList>{currentFacets ? <>{renderFacets(currentFacets)}</> : 'No data'}</FilterList>}
        {title === 'Roles' && (
          <FilterList>{facets ? <>{renderGroupedFacets(facets as RolesFacetsProps)}</> : 'No data'}</FilterList>
        )}
      </FilterDropdownElement>

      <ButtonGroup alignRight buttonGap="16px" marginTop="16px">
        <SlimButton variant="secondary" onClick={cancel} width="100px">
          CANCEL
        </SlimButton>
        <SlimButton onClick={() => apply(selectedFacets)} width="100px">
          FILTER
        </SlimButton>
      </ButtonGroup>
    </DropdownContainer>
  );
};
