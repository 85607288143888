import React, { ReactNode } from 'react';
import styled, { css } from 'styled-components';

interface ContainerProps {
  color?: string;
  underline?: boolean;
  theme: {
    [key: string]: string;
  };
}

const Container = styled.div<ContainerProps>`
  color: ${(props) => props.theme[props.color ?? ''] || props.theme.marineBlue};
  ${(props) =>
    props.underline &&
    css`
      text-decoration: underline;
    `}
  display: flex;
  align-items: center;
  justify-content: space-around;
`;

interface StyledButtonProps {
  width?: string;
  height?: string;
  theme: {
    [key: string]: string;
  };
}

const StyledButton = styled.button<StyledButtonProps>`
  box-sizing: border-box;
  border: none;
  background-color: transparent;
  cursor: pointer;
  width: ${(props) => props && props.width};
  height: ${(props) => props && props.height};
  &:disabled {
    cursor: default;
    > ${Container} {
      color: ${(props) => props.theme.placeholderGray};
    }
  }
`;

interface TextContainerProps {
  StartIcon?: ReactNode;
  srTextOnly?: boolean;
}

const TextContainer = styled.span<TextContainerProps>`
  line-height: 20px;
  font-size: 14px;
  font-weight: bold;
  // If there is an icon and a text that should both show (e.g. the text
  // is not just for screen-readers), add margin between icon and button.
  ${(props) =>
    props.StartIcon &&
    !props.srTextOnly &&
    css`
      margin-left: 10px;
    `}
`;

interface LinkButtonProps {
  children: ReactNode;
  startIcon?: ReactNode;
  endIcon?: ReactNode;
  width?: string;
  height?: string;
  onClick?: () => void;
  underline?: boolean;
  color?: string;
  srTextOnly?: boolean;
  className?: string;
  disabled?: boolean;
  title?: string;
}

export const LinkButton = ({
  children,
  startIcon: StartIcon,
  endIcon: EndIcon,
  width,
  height,
  onClick,
  underline,
  color,
  srTextOnly, // The button text is only for screen-readers
  className,
  disabled,
  title,
}: LinkButtonProps) => (
  <StyledButton
    width={width}
    height={height}
    onClick={onClick}
    type="button"
    className={className}
    disabled={disabled}
    title={title}
  >
    <Container underline={underline} color={color}>
      {StartIcon}
      <TextContainer StartIcon={StartIcon} srTextOnly={srTextOnly}>
        {children}
      </TextContainer>
      {EndIcon}
    </Container>
  </StyledButton>
);
