import React, { useState } from 'react';
import { Guid } from '../../../../types';
import { ButtonGroup, SlimButton } from '@instech/components';
import { DeleteImageStatus, ImagePreview } from '../../../../services/imageServices/imageServices';
import { useModalContext } from '../../../modal/ModalContext';
import { useAnnouncement } from '../../../../services/announcementsServices';
import { useAnnouncementIdFromUrl } from '../../../../hooks/useAnnouncementIdFromUrl';
import { SelectedImages } from '../core/SelectedImages';
import { EditImageForm } from '../core/ImageForm/EditImageForm';
import { ContentGrid, Sidebar } from '../core/ContentGrid';
import { ImageGrid } from '../core/ImageGrid';
import { NoImagesUploaded } from '../core/NoImagesUploaded';
import { openInUseDeleteDialog } from '../core/InUseDeleteDialog/openInUseDeleteDialog';

const addOrRemove = (entries: Guid[], itemId: Guid, allowOne: boolean = false) => {
  const alreadySelected = entries.find((e) => e === itemId);
  if (alreadySelected) {
    return entries.filter((e) => e !== itemId);
  }
  return allowOne ? [itemId] : [...entries, itemId];
};

interface ImageInsertProps {
  imageIds: Guid[];
  selectedImages: Guid[];
  selectOneImage: boolean;
  insertImages: (images: Guid[]) => void;
  deleteImage: (image: Guid) => Promise<DeleteImageStatus>;
}
export const ImageInsert = ({
  imageIds,
  selectedImages,
  selectOneImage,
  insertImages,
  deleteImage,
}: ImageInsertProps) => {
  const [selectedIds, setSelectedIds] = useState<Guid[]>(selectedImages || []);
  const [editingImage, setEditingImage] = useState<ImagePreview | null>(null);
  const announcementId = useAnnouncementIdFromUrl();
  const { data: currentUpdate } = useAnnouncement(announcementId);
  const { open } = useModalContext();

  const isPublished = currentUpdate?.published;
  const articleImages = currentUpdate?.articles.flatMap((article) => article.images);

  const dirtyEditingImage = (editingImage && articleImages?.includes(editingImage.id)) ?? false;
  const disableSave = isPublished && !currentUpdate?.operations.publish;

  const onSelectImage = (image: ImagePreview) => {
    setSelectedIds((prev) => addOrRemove(prev, image.id, selectOneImage));
  };

  const onRemoveImage = ({ id }: ImagePreview) => {
    setSelectedIds((prev) => prev.filter((e) => e !== id));
  };

  const onDeleteImage = async (id: Guid) => {
    const result = await deleteImage(id);
    if (result === 'Inuse') {
      openInUseDeleteDialog({ open });
    }
    if (result === 'Success') {
      setSelectedIds((prev) => prev.filter((e) => e !== id));
      setEditingImage(null);
    }
  };

  if (imageIds.length < 1) {
    return <NoImagesUploaded />;
  }

  return (
    <ContentGrid>
      <ImageGrid
        imageIds={imageIds}
        selectedIds={selectedIds}
        onSelectImage={onSelectImage}
        disableSelect={!!editingImage}
      />
      <Sidebar title={editingImage ? 'Edit image' : 'Selected images'}>
        {!editingImage && (
          <>
            <SelectedImages
              imageIds={selectedIds}
              onEdit={(image) => setEditingImage(image)}
              onRemove={(image) => onRemoveImage(image)}
            />
            <ButtonGroup alignRight marginTop="30px">
              <SlimButton onClick={() => insertImages(selectedIds)}>INCLUDE</SlimButton>
            </ButtonGroup>
          </>
        )}
        {editingImage && (
          <EditImageForm
            image={editingImage}
            onSave={() => setEditingImage(null)}
            onClose={() => setEditingImage(null)}
            onDelete={onDeleteImage}
            isPublished={isPublished && dirtyEditingImage}
            disableSave={disableSave}
          />
        )}
      </Sidebar>
    </ContentGrid>
  );
};
