import { WarningFilled } from '@instech/icons';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

interface StyledLinkProps {
  isSmall?: boolean;
}

const Main = styled.div`
  background-color: ${(props) => props.theme.whiteBlue};
  background-size: cover;
  min-height: 100vh;
`;

const ContentWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  min-height: calc(100vh - 100px);
`;

const StyledWarning = styled(WarningFilled)`
  path {
    fill: ${(props) => props.theme.marineBlue};
  }
  height: 60px;
  width: 60px;
`;

const Content = styled.div<StyledLinkProps>`
  margin-top: 10px;
  font-size: ${(props) => (props.isSmall ? '18px' : '24px')};
  font-weight: bold;
  text-align: center;

  a {
    text-decoration: underline;
  }
`;

export const NoAccessMessage = () => (
  <Main>
    <ContentWrapper>
      <StyledWarning />
      <Content>Sorry, you do not have access to this page</Content>
      <Content isSmall>
        <Link to="/">Navigate to Home Page</Link>
      </Content>
    </ContentWrapper>
  </Main>
);
