import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { SlimButton, ButtonGroup, LinkButton } from '@instech/components';
import { SelectedUser } from '../UsersPage/userTypes';
import { UserSearch } from '../search/UserSearch';
import { Download } from '@instech/icons';

const StyledButtonGroup = styled(ButtonGroup)`
  position: relative;
  display: flex;
  margin-bottom: 8px;
`;

const MenuWrapper = styled.div`
  background: ${(props) => props.theme.white};
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  position: absolute;
  right: 0;
  top: 100%;
  z-index: 1;

  > * {
    padding: 16px;
    :hover {
      background: ${(props) => props.theme.ultraLightGray2};
    }
  }
`;

const TableSearchWrapper = styled.div`
  margin-right: 12px;
  div {
    margin: 0;
  }
`;

export interface ButtonGroupProps {
  selectedUsers: SelectedUser[];
  handleUserSearch: (search: string | null) => void;
  handleResetSearch: () => void;
  isAllUsersPage: boolean;
  setModalActivateUserVisible: (newPage: boolean) => void;
  setModalDeactivateUserVisible: (newPage: boolean) => void;
  setIsModalManageSignInMethodsVisible: (newPage: boolean) => void;
  setIsModalManageAccessVisible: (newPage: boolean) => void;
  setIsAddNewUserVisible: (newPage: boolean) => void;
  setIsModalAddUserToCompanyVisible: (newPage: boolean) => void;
  isCompanyPage: boolean;
  setIsModalRemoveUsersFromCompanyVisible: (newPage: boolean) => void;
  setModalDownloadCsvVisible: (newPage: boolean) => void;
}

export const ManageUserButtonGroup = ({
  isCompanyPage,
  selectedUsers,
  handleUserSearch,
  handleResetSearch,
  isAllUsersPage,
  setModalActivateUserVisible,
  setModalDeactivateUserVisible,
  setIsModalManageSignInMethodsVisible,
  setIsModalManageAccessVisible,
  setIsAddNewUserVisible,
  setIsModalAddUserToCompanyVisible,
  setIsModalRemoveUsersFromCompanyVisible,
  setModalDownloadCsvVisible,
}: ButtonGroupProps) => {
  const [buttonsMenuOpen, setButtonsMenuOpen] = useState(false);

  const menuRef = useRef<HTMLDivElement | null>(null);

  const handleDocumentClick = (event: any) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setButtonsMenuOpen(false);
    }
  };
  useEffect(() => {
    document.addEventListener('click', handleDocumentClick);
    return () => {
      document.removeEventListener('click', handleDocumentClick);
    };
  }, []);

  const noSelectedUsers = selectedUsers.length === 0;

  const activateDisabled = selectedUsers.every((user) => user.enabled);
  const deactivateDisabled = selectedUsers.every((user) => !user.enabled);

  return (
    <StyledButtonGroup alignRight>
      {!isAllUsersPage && (
        <TableSearchWrapper>
          <UserSearch onSearch={handleUserSearch} onReset={handleResetSearch} />
        </TableSearchWrapper>
      )}
      <SlimButton
        aria-label="Download csv"
        variant="secondary"
        startIcon={<Download />}
        onClick={() => setModalDownloadCsvVisible(true)}
        type="button"
      >
        CSV file
      </SlimButton>
      {isCompanyPage && (
        <SlimButton
          aria-label="Delete User"
          variant="secondary"
          onClick={() => setIsModalRemoveUsersFromCompanyVisible(true)}
          type="button"
          disabled={noSelectedUsers}
        >
          DELETE USER
        </SlimButton>
      )}
      <SlimButton aria-label="Add User" onClick={() => setIsAddNewUserVisible(true)} type="button">
        {isCompanyPage ? 'ADD USER' : 'ADD NEW USER'}
      </SlimButton>
      <SlimButton
        aria-label="Activate"
        type="button"
        onClick={(event) => {
          event.stopPropagation();
          setButtonsMenuOpen(!buttonsMenuOpen);
        }}
        disabled={noSelectedUsers}
      >
        MANAGE SELECTED USERS
      </SlimButton>
      {buttonsMenuOpen && (
        <MenuWrapper ref={menuRef}>
          <LinkButton onClick={() => setModalActivateUserVisible(true)} disabled={activateDisabled}>
            Activate
          </LinkButton>
          <LinkButton onClick={() => setModalDeactivateUserVisible(true)} disabled={deactivateDisabled}>
            Deactivate
          </LinkButton>
          <LinkButton onClick={() => setIsModalManageSignInMethodsVisible(true)}>Manage Sign In Methods</LinkButton>
          <LinkButton onClick={() => setIsModalManageAccessVisible(true)}>Manage Access</LinkButton>
          <LinkButton onClick={() => setIsModalAddUserToCompanyVisible(true)}>{`Add ${
            selectedUsers.length > 1 ? ' Users' : ' User'
          } To Company`}</LinkButton>
          {isCompanyPage && (
            <LinkButton onClick={() => setIsModalRemoveUsersFromCompanyVisible(true)}>
              {`Remove ${selectedUsers.length > 1 ? ' Users' : ' User'} From Company`}
            </LinkButton>
          )}
        </MenuWrapper>
      )}
    </StyledButtonGroup>
  );
};
