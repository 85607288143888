import React from 'react';
import { Guid } from '../../../../types';
import styled from 'styled-components';
import { ImagePreview, useImagePreviewById } from '../../../../services/imageServices/imageServices';
import { EditButton, RemoveButton } from '../../../shared/LinkButton/LinkButtons';
import { Image } from '../../../shared/Image/core/Image';

const Wrapper = styled.div`
  display: flex;
  margin-bottom: 20px;
`;

const ImageArea = styled.div`
  flex-shrink: 0;
`;

const Text = styled.div`
  flex-grow: 1;
  margin-left: 20px;
  word-break: break-word;
  color: ${(props) => props.theme.marineBlue75};
`;

const Name = styled.span`
  display: block;
  font-weight: 600;
`;

const Description = styled.span`
  display: block;
  margin-bottom: 10px;
`;

const NoSelectionDescription = styled(Description)`
  font-style: italic;
`;

const RowWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  button {
    padding-left: 0px;
  }
`;

interface ImageProps {
  id: Guid;
  onEdit: (image: ImagePreview) => void;
  onRemove: (image: ImagePreview) => void;
}
const SelectedImage = ({ id, onEdit, onRemove }: ImageProps) => {
  const { data: image } = useImagePreviewById(id, 'small');

  // TODO: Loader
  if (!image) return null;

  return (
    <Wrapper key={image.id}>
      <ImageArea>
        <Image url={image.url} alt={image.title} width="95px" height="95px" darkBorder />
      </ImageArea>
      <Text>
        <Name>{image.title}</Name>
        <Description>{image.description.trim().length ? image.description : <i>No description</i>}</Description>
        <RowWrapper>
          <EditButton onClick={() => onEdit(image)} />
          <RemoveButton onClick={() => onRemove(image)} />
        </RowWrapper>
      </Text>
    </Wrapper>
  );
};

interface Props {
  imageIds: Guid[];
  onEdit: (image: ImagePreview) => void;
  onRemove: (image: ImagePreview) => void;
}
export const SelectedImages = ({ imageIds, onEdit, onRemove }: Props) => {
  if (imageIds.length === 0) {
    return <NoSelectionDescription>No images have been selected.</NoSelectionDescription>;
  }
  return (
    <div>
      {imageIds.map((id) => (
        <SelectedImage key={id} id={id} onEdit={onEdit} onRemove={onRemove} />
      ))}
    </div>
  );
};
