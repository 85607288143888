import React from 'react';
import styled from 'styled-components';
import { ContextMenu } from '@instech/components';
import { Table, TableCell, TableHeader, TableRow } from '../../shared/Table';
import { ContextMenuCallbacks, Announcement, AnnouncementStatus } from '@/types';
import { portalNames, roles } from '../../../utils/textutils';
import { getFriendlyUTCTimestamp } from '../../../utils/date/friendlyDates';
import { RoleTooltip } from './tooltip';
import { useContextMenuOptions } from './utils';

// Last empty Header is for the button column
const HeaderRow = () => (
  <>
    <TableHeader>Product</TableHeader>
    <TableHeader>Title</TableHeader>
    <TableHeader>Published (UTC)</TableHeader>
    <TableHeader>Last edit (UTC)</TableHeader>
    <TableHeader>Recipients</TableHeader>
    <TableHeader>Read by</TableHeader>
    <TableHeader>Read by (%)</TableHeader>
    <TableHeader>Roles</TableHeader>
    <TableHeader>Author</TableHeader>
    <TableHeader />
  </>
);

interface RowProps {
  even: boolean;
  announcement: Announcement;
  status: AnnouncementStatus;
  callbacks: ContextMenuCallbacks;
}
const ContentRow = ({ even, announcement, status, callbacks }: RowProps) => {
  const screenReaderTitle = `Open menu for ${announcement.title}`;
  const menuId = `${announcement.id}-contextMenu`;
  const options = useContextMenuOptions(announcement, status, callbacks);

  return (
    <TableRow even={even}>
      <TableCell>{portalNames(announcement.recipientStructure)}</TableCell>
      <TableCell>{announcement.title}</TableCell>
      <TableCell>{getFriendlyUTCTimestamp(announcement.publishedDate)}</TableCell>
      <TableCell>{getFriendlyUTCTimestamp(announcement.lastUpdated)}</TableCell>
      <TableCell>{announcement.recipientStructure.selectedCount}</TableCell>
      <TableCell>{announcement.readCount}</TableCell>
      <TableCell>{announcement.percentageRead}%</TableCell>
      <TableCell>
        <>
          <p data-tip={roles(announcement.recipientStructure, false)} data-for={announcement.id}>
            {roles(announcement.recipientStructure, true)}
          </p>
          <RoleTooltip id={announcement.id} />
        </>
      </TableCell>
      <TableCell>{announcement.publishedBy.name}</TableCell>
      <TableCell>
        <ContextMenu id={menuId} options={options} screenReaderTitle={screenReaderTitle} />
      </TableCell>
    </TableRow>
  );
};

const StyledTable = styled(Table)`
  // Give some space to the context menu
  margin-bottom: 30px;
`;

interface Props {
  announcements: Announcement[];
  status: AnnouncementStatus;
  callbacks: ContextMenuCallbacks;
}
export const PublishedTable = ({ announcements, status, callbacks }: Props) => (
  <StyledTable gridTemplateColumns="150px auto 160px 160px 80px 85px 105px auto auto 80px">
    {announcements?.length > 0 && <HeaderRow />}
    {announcements?.map((item, index) => (
      <ContentRow key={item.id} announcement={item} even={index % 2 === 0} callbacks={callbacks} status={status} />
    ))}
  </StyledTable>
);
