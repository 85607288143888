/* eslint-disable react/no-array-index-key */
import React from 'react';
import styled from 'styled-components';
import { FieldArray, FormikProps } from 'formik';
import { Pane, FormRow, FormField, TextField, TextArea, Dropdown, FormGap } from '@instech/components';
import { Option } from '../../../../types';
import { useImagesLibraryModal } from '../../../tools/Images/core/ImagesLibraryModal/ImagesLibraryModal';
import { AddSectionButton, DeleteSectionButton } from './ArticleButtons';
import { newArticle, FormValues } from './utils';
import { RichTextArrayField } from './RichTextArrayField';
import { CardImageField } from './CardImageField';

const MaxWidth = styled.div`
  max-width: 840px;
  margin: auto;
`;

const ArticleWrapper = styled.div`
  & + & {
    // Inject a little space between articles
    margin-top: 8px;
  }
  margin-bottom: 5px;
`;

export interface NestedOptions {
  label: string;
  options: Option[];
}

interface Props {
  formik: FormikProps<FormValues>;
  categoryOptions: NestedOptions[];
  keywordOptions: NestedOptions[];
}
export const ContentsFormFields = ({ formik, categoryOptions, keywordOptions }: Props) => {
  const onImageChange = (imageIds: string[]) => {
    const imageId = imageIds.length === 1 ? imageIds[0] : undefined;
    formik.setFieldValue('cardImageId', imageId);
  };

  const currentImageId = formik.getFieldMeta('cardImageId').value as string;

  const { open: openCardImagesModal } = useImagesLibraryModal({
    title: 'Card image',
    selectOneImage: true,
    onImageChange,
    imageState: currentImageId ? [currentImageId] : [],
  });

  return (
    <>
      <Pane title="Card" color="green">
        <MaxWidth>
          <FormRow gutter="35px" marginBottom="20px">
            <FormField span={6}>
              <Dropdown name="categoryOption" label="Category" options={categoryOptions} clearable required />
            </FormField>
            <FormField span={6}>
              <Dropdown name="keywordOption" label="Keyword" options={keywordOptions} clearable required />
            </FormField>
          </FormRow>
          <FormRow gutter="35px">
            <FormField span={6}>
              <CardImageField name="cardImageId" onClick={openCardImagesModal} />
            </FormField>
            <FormField span={6}>
              <TextField name="title" label="Title" placeholder="Title" required maxLength={50} />
            </FormField>
          </FormRow>
          <FormRow gutter="35px">
            <FormGap span={6} />
            <FormField span={6}>
              <TextArea name="shortText" label="Short text" placeholder="Text" required maxLength={120} />
            </FormField>
          </FormRow>
        </MaxWidth>
      </Pane>
      <Pane title="Article" color="green" padding="32px 32px 20px 32px">
        <FieldArray
          name="articles"
          render={(arrayHelpers) => (
            <div>
              {formik.values.articles.map((value, index) => (
                <ArticleWrapper key={`articles-${index}`}>
                  {formik.values.articles.length > 1 && (
                    <DeleteSectionButton onClick={() => arrayHelpers.remove(index)} />
                  )}
                  <RichTextArrayField index={index} value={value} formik={formik} />
                </ArticleWrapper>
              ))}
              <AddSectionButton onClick={() => arrayHelpers.push(newArticle)} />
            </div>
          )}
        />
      </Pane>
    </>
  );
};
