import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Company, FilterParams } from '../../types';
import { PageContent } from '../../Page';
import { Table } from '../shared/Table';
import { Pagination } from '../shared/pagination/Pagination';
import { useAppContext } from '../../AppContext';
import { OnboardingTabs } from '../OnboardingTab/OnboardingTabs';
import { CompanySearch } from '../search/CompanySearch';
import { PAGE_SIZE } from '../shared/constants';
import { searchCompanies } from '../../services/companiesService';
import { Add } from '@instech/icons';
import useDebounce from '../../hooks/useDebounce';
import { ButtonGroup, SlimButton, Loader } from '@instech/components';
import { AddCompanyModal } from '../modal/AddCompanyModal';
import { useLocalStorage, currentTime } from '../../hooks/useLocalStorage';
import { CompaniesTable } from './CompaniesTable';
import { NoDataWrapper } from '../shared/Components';
import { useSearchWithLocalStorage } from '../../hooks/useSearchWithLocalStorage';
import { ItemsTableHeader } from '../shared/ItemsTableHeader';
import { getCompaniesHeaderItems } from './companiesHeaderItems';

const SearchWrapper = styled.div`
  margin: 32px auto;
  max-width: 600px;
`;
const ActionsWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  justify-content: space-between;
  margin-top: 32px;
`;
const PaginationWrapper = styled.div`
  padding: 32px 0;
`;

interface CompaniesPageProps {
  isCompaniesPage: boolean;
}

export const CompaniesPage = ({ isCompaniesPage }: CompaniesPageProps) => {
  const [selectedType, setSelectedType] = useState<string | undefined>('');
  const [searchText, setSearchText] = useState<string | null | undefined>(null);
  const [isAddNewCompanyVisible, setIsAddNewCompanyVisible] = useState(false);
  const debouncedSearchText = useDebounce(searchText, 500);
  const { companiesCurrentPage, setCompaniesCurrentPageNumber } = useAppContext();

  const [filterParams, setFilterParams] = useState<FilterParams>({});

  const [refreshCompaniesCount, setRefreshCompaniesCount] = useState(0);
  const { getItem, setItem, removeItem } = useLocalStorage('companies');
  const localStorageCompanies = getItem() ?? [];

  useEffect(() => {
    // take items from local storage and compare their timestamps with the current time
    // if the difference is greater than 20 minutes, remove the item from local storage
    const updatedCompanies = localStorageCompanies.filter(
      (company: Company) => currentTime - company.timestamp < 20 * 60 * 1000
    );
    setItem(updatedCompanies);
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setRefreshCompaniesCount((prev) => prev + 1);
      removeItem();
    }, 20 * 60 * 1000);
    return () => clearInterval(interval);
  }, []);

  const {
    data: companies,
    pagingMetadata,
    columnMetadata,
  } = useSearchWithLocalStorage<Company>(
    searchCompanies,
    {
      freeText: debouncedSearchText,
      includeCrmData: !!debouncedSearchText,
      pageNumber: companiesCurrentPage,
      pageSize: PAGE_SIZE,
      ...filterParams,
    },
    [debouncedSearchText, companiesCurrentPage, refreshCompaniesCount, filterParams],
    'companies',
    'id'
  );

  const totalCountCompanies = pagingMetadata.totalCount;
  const companiesHeaderItems = getCompaniesHeaderItems(columnMetadata);

  const onSearch = (text: string | undefined) => {
    setSearchText(text);
    setCompaniesCurrentPageNumber(1);
  };

  const onReset = () => {
    setSearchText(null);
    setCompaniesCurrentPageNumber(1);
  };

  const isNoCompanies = companies.length === 0 && searchText;
  const isLoading = !companies && !isNoCompanies;

  return (
    <PageContent>
      <SearchWrapper>
        <CompanySearch
          onReset={onReset}
          onSearch={onSearch}
          selectedType={selectedType}
          setSelectedType={setSelectedType}
        />
      </SearchWrapper>
      <ActionsWrapper>
        <OnboardingTabs isCompaniesPage={isCompaniesPage} totalCountCompanies={totalCountCompanies} />
        <ButtonGroup alignRight>
          <SlimButton
            aria-label="Add Company"
            onClick={() => setIsAddNewCompanyVisible(true)}
            type="button"
            startIcon={<Add />}
          >
            ADD NEW COMPANY
          </SlimButton>
        </ButtonGroup>
      </ActionsWrapper>
      {isAddNewCompanyVisible && (
        <AddCompanyModal
          setRefreshCompaniesCount={setRefreshCompaniesCount}
          handleCloseModal={() => setIsAddNewCompanyVisible(false)}
        />
      )}
      <Table gridTemplateColumns="1.5fr 1fr 1fr 1fr 1fr 1fr 120px">
        <ItemsTableHeader
          filterParams={filterParams}
          setFilterParams={setFilterParams}
          headerItems={companiesHeaderItems}
        />
        <CompaniesTable companies={companies} setRefreshCompaniesCount={setRefreshCompaniesCount} />
      </Table>
      {isLoading && <Loader />}
      {isNoCompanies && <NoDataWrapper>No companies found</NoDataWrapper>}
      {!debouncedSearchText && (
        <PaginationWrapper>
          <Pagination
            headerData={pagingMetadata}
            currentPage={companiesCurrentPage}
            setCurrentPage={setCompaniesCurrentPageNumber}
          />
        </PaginationWrapper>
      )}
    </PageContent>
  );
};
