import React, { FC } from 'react';
import styled from 'styled-components';

const Container = styled.div<{ selected: boolean }>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  border-radius: 5px;
  background-color: ${(props) => (props.selected ? props.theme.green50 : props.theme.ultraLightGray)};
  min-height: 28px;
  max-height: 1rem;
  margin-right: 0.7em;
  margin-bottom: 5px;
  padding: ${(props) => (props.selected ? '0 0 0 0.5rem' : '0 0.5rem')};
`;

const Label = styled.div<{ disabled: boolean }>`
  color: ${(props) => (!props.disabled ? props.theme.marineBlue : props.theme.mediumGray)};
  font-size: 14px;
  white-space: nowrap;
  font-weight: 500;
`;

const IconContainer = styled.div<{ disabled: boolean; iconHeight?: number }>`
  line-height: 0.5;
  padding: 0;

  svg {
    height: 15px;
  }
`;

interface FilterButtonProps {
  label: string | React.ReactElement;
  toggle: () => void;
  selected?: boolean;
  disabled?: boolean;
  icon?: React.ReactNode;
  tabIndex?: number;
}
export const FilterButton: FC<FilterButtonProps> = ({
  label,
  toggle,
  selected = false,
  disabled = false,
  icon,
  tabIndex,
}) => {
  return (
    <Container
      tabIndex={tabIndex}
      selected={selected}
      onClick={() => !disabled && toggle()}
      onKeyDown={(e) => e.key === 'Enter' && !disabled && toggle()}
    >
      <Label disabled={disabled}>{label}</Label>
      {icon && <IconContainer disabled={disabled}>{icon}</IconContainer>}
    </Container>
  );
};
