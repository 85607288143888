import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import './index.css';
import { App } from './App';
import reportWebVitals from './reportWebVitals';
import { ThemeProvider } from 'styled-components';
import { theme } from './utils/theme';
import { SWRConfig } from 'swr';
import { getJsonAsync } from './services/client';
import { ErrorBoundary, DefaultErrorMessage } from '@instech/components';
import { FeatureFlagProvider } from './providers/featureFlagProvider';
import { AuthContextProvider } from './authentication/AuthContext';
import { INTERVAL_VALUE } from './components/shared/constants';
import { AppContextProvider } from './AppContext';
import { ModalHandler } from './components/modal/Modal';

type FetcherFunction<T> = (...args: any[]) => Promise<T>;

interface SwrConfig<T> {
  revalidateOnFocus: boolean;
  errorRetryCount: number;
  fetcher: FetcherFunction<T>;
}

const swrConfig: SwrConfig<any> = {
  revalidateOnFocus: false,
  errorRetryCount: 3,
  fetcher: (...args: Parameters<typeof getJsonAsync>) => getJsonAsync(...args),
};

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    <ErrorBoundary component={DefaultErrorMessage}>
      <BrowserRouter basename={baseUrl ?? ''}>
        <AuthContextProvider intervalValue={INTERVAL_VALUE}>
          <ThemeProvider theme={theme}>
            <SWRConfig value={swrConfig}>
              <FeatureFlagProvider>
                <AppContextProvider>
                  <ModalHandler>
                    <App />
                  </ModalHandler>
                </AppContextProvider>
              </FeatureFlagProvider>
            </SWRConfig>
          </ThemeProvider>
        </AuthContextProvider>
      </BrowserRouter>
    </ErrorBoundary>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
