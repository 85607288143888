import React, { useState } from 'react';
import * as Yup from 'yup';
import { Formik, Form, FormikValues } from 'formik';
import { ButtonGroup, SlimButton, TextField } from '@instech/components';
import { ModalLayout } from './ModalLayout';
import { postNewUser, updateUser } from '../../services/userService';
import { UserDataProps, User, SourceSystem, Company, UserSearchOptionProps } from '../../types';
import { Check } from '@instech/icons';
import { SuccessMessageWrapper, ErrorMessageWrapper, Column, StyledLabel, SelectWrapper } from '../shared/Components';
import { useLocalStorage, currentTime } from '../../hooks/useLocalStorage';
import { searchUsers, addUserToCompany } from '../../services/userService';
import { debounce } from '../../utils/debounce';
import { AsyncSelectField } from '../shared/AsyncSelectField';
import { CrmLabel } from '../shared/Components';
import styled from 'styled-components';
import { PAGE_SIZE } from '../shared/constants';

const StyledLabellWrapper = styled.div`
  display: grid;
  grid-template-columns: minmax(200px, 1fr) minmax(200px, 1fr) 140px;
  grid-gap: 8px;
`;

interface UserModalProps {
  handleCloseModal: () => void;
  isNew?: boolean;
  user?: User;
  companyId?: string | null | undefined;
  companyName?: string | null | undefined;
  setRefreshUsersCount?: React.Dispatch<React.SetStateAction<number>>;
}

export const AddEditUserModal = ({
  handleCloseModal,
  isNew,
  user,
  companyId,
  companyName,
  setRefreshUsersCount,
}: UserModalProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>('');
  const isCrmUser = !!user && user.sourceSystem === SourceSystem.Crm;

  const modalOptions = {
    size: 'medium',
    title:
      isNew && !!companyId
        ? 'Add User to Company'
        : isCrmUser
        ? 'Import from CRM'
        : isNew && !companyId
        ? 'Create New User'
        : 'Edit User',
  };

  const initialValues = {
    name: user?.name ?? '',
    title: user?.title ?? '',
    phoneNumber: user?.phoneNumber ?? '',
    email: user?.email ?? '',
    subjectId: user?.subjectId || '',
    companies: user?.companies || [],
    roles: user?.roles || [],
  };

  const emptyOption = {
    value: '',
    label: '',
    name: '',
    email: '',
    phoneNumber: '',
    title: '',
    companies: [],
  };

  const { getItem, setItem } = useLocalStorage('users');
  const localStorageUsers = getItem() ?? [];

  const [selectedOption, setSelectedOption] = useState<UserSearchOptionProps>(emptyOption);

  const handleSubmit = async (values: FormikValues, resetForm: () => void) => {
    setIsError(false);
    setIsLoading(true);
    const submitValues: UserDataProps = {
      email: values.email,
      name: values.name,
      title: values.title === '' ? null : values.title,
      phoneNumber: values.phoneNumber === '' ? null : values.phoneNumber,
    };
    try {
      if (isNew && !!companyId) {
        await addUserToCompany(values.subjectId, companyId);
        const newUser = {
          ...values,
          sourceSystem: SourceSystem.Onboarding,
          enabled: true,
          companies: [{ id: companyId, name: companyName }, ...values.companies],
          isLocalStorageItem: true,
          timestamp: currentTime,
        };
        const updatedUsers = [newUser, ...localStorageUsers.filter((u: User) => u.subjectId !== values.subjectId)];
        setItem(updatedUsers);
      } else if (isNew) {
        const newUser = await postNewUser(submitValues);
        const updatedUsers = [
          {
            ...newUser,
            sourceSystem: SourceSystem.Onboarding,
            enabled: true,
            isLocalStorageItem: true,
            timestamp: currentTime,
            companies: [],
            roles: [],
          },
          ...localStorageUsers,
        ];
        setItem(updatedUsers);
      } else if (isCrmUser) {
        const postValues = { externalId: user.externalId, ...submitValues };
        const newUser = await postNewUser(postValues);
        const updatedUsers = [
          {
            ...newUser,
            sourceSystem: SourceSystem.Onboarding,
            enabled: true,
            roles: [],
            companies: [],
            isLocalStorageItem: true,
            timestamp: currentTime,
          },
          ...localStorageUsers,
        ];
        setItem(updatedUsers);
      } else {
        await updateUser(`${user?.subjectId}`, submitValues);

        const updatedUser = {
          ...user,
          ...submitValues,
          isLocalStorageItem: true,
          timestamp: currentTime,
        };

        const restUsers = localStorageUsers.filter((u: User) => u.subjectId !== updatedUser.subjectId);
        const updatedUsers = [updatedUser, ...restUsers];
        setItem(updatedUsers);
      }
      setIsSubmitted(true);
      setIsLoading(false);
      isNew && resetForm();
      setSelectedOption(emptyOption);
      setRefreshUsersCount && setRefreshUsersCount((prev) => prev + 1);
    } catch (error) {
      let errorMessage = 'An unexpected error occurred';
      if (typeof error === 'object' && error !== null) {
        const axiosError = error as { response?: { data?: { Message?: string } } };
        errorMessage = axiosError.response?.data?.Message ?? errorMessage;
      }
      setErrorMessage(errorMessage);
      setIsError(true);
      setIsSubmitted(false);
      setIsLoading(false);
    }
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .trim()
      .min(1, 'Must be at least 1 character')
      .max(50, 'Must be 50 characters or less')
      .required('Required'),
    email: Yup.string().trim().email('Invalid email address').required('Required'),
    title: Yup.string().max(50, 'Must be 50 characters or less'),
    phoneNumber: Yup.string(),
  });

  const styledSelectLabel = (item: User) => {
    return (
      <StyledLabellWrapper>
        <span>{item.name}</span>
        <span>{item.email}</span>
        <span>{item.sourceSystem === 2 && <CrmLabel>Import From CRM</CrmLabel>}</span>
      </StyledLabellWrapper>
    );
  };

  const loadOptions = (inputValue: string) => {
    return new Promise((resolve) => {
      const debouncedSearch = async () => {
        const params = {
          freeText: inputValue,
          pageSize: PAGE_SIZE,
          includeCrmData: false,
        };
        const users = await searchUsers(params);
        const filteredLocalStorageUsers = localStorageUsers.filter(
          (user: User) =>
            user.name.toLowerCase().includes(inputValue.toLowerCase()) ||
            user.email.toLowerCase().includes(inputValue.toLowerCase())
        );
        const filteredUsers = users.items.filter(
          (user: User) => !filteredLocalStorageUsers.some((localUser: User) => localUser.subjectId === user.subjectId)
        );
        const options = [...filteredLocalStorageUsers, ...filteredUsers]
          .filter((u: User) => !u.companies?.find((c: Company) => c.id === companyId))
          .map((item: User) => ({
            value: item.subjectId,
            label: styledSelectLabel(item),
            name: item.name,
            email: item.email,
            phoneNumber: item.phoneNumber ?? '',
            title: item.title ?? '',
            companies: item.companies,
          }));
        resolve(options);
      };
      debounce(debouncedSearch, 500);
    });
  };

  const handleChange = (option: UserSearchOptionProps, setFieldValue: (field: string, value: any) => void) => {
    setSelectedOption(option);
    setFieldValue('subjectId', option.value);
    setFieldValue('name', option.name);
    setFieldValue('email', option.email);
    setFieldValue('title', option.title);
    setFieldValue('phoneNumber', option.phoneNumber);
    setFieldValue('companies', option.companies);
  };

  return (
    <ModalLayout closeModal={handleCloseModal} options={modalOptions}>
      <Formik
        initialValues={initialValues}
        onSubmit={(values, { resetForm }) => {
          handleSubmit(values, resetForm);
        }}
        validationSchema={validationSchema}
      >
        {({ setFieldValue }) => {
          return (
            <Form>
              {isNew && companyId && (
                <SelectWrapper>
                  <StyledLabel>Select User *</StyledLabel>
                  <AsyncSelectField
                    placeholder="Search for a user"
                    loadOptions={(inputValue: string) => loadOptions(inputValue)}
                    onChange={(option: UserSearchOptionProps) => handleChange(option, setFieldValue)}
                    value={selectedOption}
                  />
                </SelectWrapper>
              )}
              <Column>
                <TextField name="name" label="Full Name" required disabled={!!companyId} />
                <TextField name="email" label="Email Address" required disabled={!!companyId} />
                <TextField name="title" label="Title" disabled={!!companyId} />
                <TextField name="phoneNumber" label="Phone Number" disabled={!!companyId} />
              </Column>
              <Column>
                <ButtonGroup alignRight buttonGap="16px" marginTop="32px">
                  <SlimButton variant="secondary" onClick={handleCloseModal}>
                    {isSubmitted ? 'CLOSE' : 'CANCEL'}
                  </SlimButton>
                  <SlimButton type="submit" loading={isLoading}>
                    {isNew || isCrmUser ? 'ADD USER' : 'SAVE'}
                  </SlimButton>
                </ButtonGroup>
                {isSubmitted && (
                  <SuccessMessageWrapper>
                    <Check />
                    {isNew ? 'Successfully added new user.' : 'Successfully updated user.'}
                  </SuccessMessageWrapper>
                )}
                {isError && <ErrorMessageWrapper>{errorMessage}</ErrorMessageWrapper>}
              </Column>
            </Form>
          );
        }}
      </Formik>
    </ModalLayout>
  );
};
