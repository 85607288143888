import React from 'react';
import styled from 'styled-components';
import { Load } from '@instech/icons';

/* TODO: Address the issue with the Loader svg colours in its-js-icons, and then update
  these components once that is done.
  Because right now, what is done here is a temporary workaround to override how the
  SVG icon 'Loader' in @instech/icons has hardcoded colours. Because of this, we have
  to do a workaround right now to let the icons be styled by their parent element.
*/

interface LoaderProps {
  size?: LoaderSize;
  color?: string;
}

type LoaderSize = 'large' | 'medium' | 'small';
function getWidth(size?: LoaderSize) {
  switch (size) {
    case 'small':
      return '24px';
    case 'medium':
      return '60px';
    case 'large':
    default:
      return '80px';
  }
}

const Wrapper = styled.div<{ color?: string }>`
  display: flex;
  justify-content: center;
  padding: 16px;
  color: ${(props) => props.color || props.theme.marineBlue};
  & svg circle {
    fill: currentColor;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 70px;
  height: 48px;
  padding: 10px 20px;
  box-sizing: border-box;
  align-items: center;
  color: ${(props) => props.theme.marineBlue};
  & svg circle {
    fill: currentColor;
  }
`;

const PageWrapper = styled.div`
  height: 60vh;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${(props) => props.theme.marineBlue};
  & svg circle {
    fill: currentColor;
  }
`;

export const Loader = ({ size, color }: LoaderProps) => {
  const width = getWidth(size);
  return (
    <Wrapper color={color}>
      <Load width={width} />
    </Wrapper>
  );
};

export const SmallLoader: React.FC = () => (
  <Wrapper color="inherit">
    <Load width="24px" height="16px" />
  </Wrapper>
);

export const ButtonLoader: React.FC = () => (
  <ButtonWrapper>
    <Load width="80px" />
  </ButtonWrapper>
);

export const PageLoader: React.FC = () => (
  <PageWrapper>
    <Load width="140px" height="32px" />
  </PageWrapper>
);
