import React, { useState } from 'react';
import { ButtonGroup, SlimButton } from '@instech/components';
import { Check } from '@instech/icons';
import { ModalLayout } from './ModalLayout';
import { SelectedUser } from '../UsersPage/userTypes';
import { SelectedUsersTable } from '../shared/SelectedUsersTable';
import { sortUsers } from '../../utils/sort';
import { searchCompanies } from '../../services/companiesService';
import { AsyncSelectField } from '../shared/AsyncSelectField';
import { addUserToCompanyForSelectedUsers } from '../../services/userService';
import { debounce } from '../../utils/debounce';
import {
  SuccessMessageWrapper,
  ErrorMessageWrapper,
  Column,
  StyledLabel,
  SelectWrapper,
  StyledButtonGroup,
} from '../shared/Components';
import { User } from '../../types';
import { useLocalStorage, currentTime } from '../../hooks/useLocalStorage';
import { PAGE_SIZE } from '../shared/constants';

interface Props {
  selectedUsers: SelectedUser[];
  handleCloseModal: () => void;
  setRefreshUsersCount?: React.Dispatch<React.SetStateAction<number>>;
}

interface SelectedCompanyProps {
  id: string;
  name: string;
}

export const AddUserToCompanyModal = ({ selectedUsers, handleCloseModal, setRefreshUsersCount }: Props) => {
  const [, setCompanyInputValue] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isError, setIsError] = useState(false);
  const [noChanges, setNoChanges] = useState(false);
  const [selectedCompanyValue, setSelectedCompanyValue] = useState<SelectedCompanyProps | null>(null);
  const { getItem, setItem } = useLocalStorage('users');
  const localStorageUsers = getItem() || [];

  // get compamies from local storage
  const { getItem: getLocalCompanies } = useLocalStorage('companies');
  const localStorageCompanies = getLocalCompanies() ?? [];

  const modalTitle = `Add ${selectedUsers.length > 1 ? ' Users' : ' User'} To A Company`;
  const modalOptions = {
    size: 'medium',
    title: modalTitle,
  };

  const sortedSelectedUsers = selectedUsers.slice().sort(sortUsers);
  const selectedUsersTitle = selectedUsers.length > 1 ? 'Selected Users' : 'Selected User';

  const handleInputChange = (value: string) => {
    setCompanyInputValue(value);
    setIsSubmitted(false);
  };
  const handleChange = (option: any) => {
    setSelectedCompanyValue({ id: option.value, name: option.label });
  };
  const loadOptions = (inputValue: string) => {
    return new Promise((resolve) => {
      const debouncedSearch = async () => {
        const params = {
          freeText: inputValue,
          pageSize: PAGE_SIZE,
          includeCrmData: false,
        };
        const companies = await searchCompanies(params);
        //add companies from local storage to async select field options
        const filteredLocalStorageCompanies = localStorageCompanies.filter(
          (company: any) => !companies.items.some((c: any) => c.id === company.id)
        );
        const allCompanies = [...companies.items, ...filteredLocalStorageCompanies];
        const options = allCompanies.map((item: any) => ({
          value: item.id,
          label: `${item.name} (company number: ${item.companyNumber})`,
        }));
        resolve(options);
      };
      debounce(debouncedSearch, 500);
    });
  };

  const handleSubmit = () => {
    setIsError(false);
    setIsLoading(true);
    setNoChanges(false);
    if (selectedCompanyValue) {
      const usersToChange = selectedUsers.filter(
        (user) => !user.companies.some((c: SelectedCompanyProps) => c.id === selectedCompanyValue.id)
      );
      if (usersToChange.length === 0) {
        //when all selected users are already added to company
        console.error('nothing to change');
        setNoChanges(true);
        setIsLoading(false);
        return;
      }
      addUserToCompanyForSelectedUsers(usersToChange, selectedCompanyValue.id)
        .then(() => {
          const updatedLocalStorageUsers = getItem() || [];
          const usersWithUpdatedCompanies = usersToChange.map((user) => {
            const localStorageUser = updatedLocalStorageUsers.find((u: User) => u.subjectId === user.subjectId);
            return {
              ...user,
              companies: localStorageUser
                ? [...localStorageUser.companies, selectedCompanyValue]
                : [selectedCompanyValue],
              isLocalStorageItem: true,
              timestamp: currentTime,
            };
          });
          const filteredLocalstorageUsers = localStorageUsers.filter(
            (localStorageUser: User) =>
              !usersToChange.some((userToChange: SelectedUser) => userToChange.subjectId === localStorageUser.subjectId)
          );
          const updatedUsers = [...usersWithUpdatedCompanies, ...filteredLocalstorageUsers];
          setItem(updatedUsers);
          setRefreshUsersCount && setRefreshUsersCount((prev) => prev + 1);
          setIsSubmitted(true);
          setIsLoading(false);
          setTimeout(() => {
            handleCloseModal();
          }, 2000);
        })
        .catch((err) => {
          console.error(err);
          setIsError(true);
          setIsSubmitted(false);
          setIsLoading(false);
        });
    }
  };

  return (
    <ModalLayout closeModal={handleCloseModal} options={modalOptions}>
      <SelectedUsersTable title={selectedUsersTitle} selectedUsers={sortedSelectedUsers} startCollapsed={false} />
      <SelectWrapper>
        <StyledLabel>Select a Company *</StyledLabel>
        <AsyncSelectField
          placeholder="Search for a company"
          loadOptions={(inputValue: string) => loadOptions(inputValue)}
          onInputChange={handleInputChange}
          onChange={handleChange}
        />
      </SelectWrapper>
      <Column>
        <StyledButtonGroup alignRight>
          <SlimButton variant="secondary" onClick={handleCloseModal}>
            {isSubmitted ? 'CLOSE' : 'CANCEL'}
          </SlimButton>
          <SlimButton
            onClick={handleSubmit}
            loading={isLoading}
            disabled={!selectedCompanyValue || isSubmitted}
            width="220px"
          >
            {modalTitle}
          </SlimButton>
        </StyledButtonGroup>
        {isSubmitted && (
          <SuccessMessageWrapper>
            <Check />
            User(s) added successfully.
          </SuccessMessageWrapper>
        )}
        {isError && <ErrorMessageWrapper>Something went wrong.</ErrorMessageWrapper>}
        {noChanges && <ErrorMessageWrapper>All users are already added to the company.</ErrorMessageWrapper>}
      </Column>
    </ModalLayout>
  );
};
