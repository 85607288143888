import React, { useState } from 'react';
import styled from 'styled-components';
import { SlimButton, ButtonGroup } from '@instech/components';
import { WarningFilled, Check } from '@instech/icons';
import { ModalLayout } from './ModalLayout';
import { SelectedUser } from '../UsersPage/userTypes';
import { SelectedUsersTable } from '../shared/SelectedUsersTable';
import { sortUsers } from '../../utils/sort';
import { deleteUserFromCompany } from '../../services/userService';
import { Company } from '../../types';
import { useLocalStorage, currentTime } from '../../hooks/useLocalStorage';

const Column = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  button {
    text-transform: uppercase;
  }
`;
const StyledWarningFilled = styled(WarningFilled)`
  width: 52px;
  height: 52px;
  color: ${(props) => (props.error ? props.theme.red : props.theme.marineBlue)};
`;
const StyledCheck = styled(Check)`
  width: 52px;
  height: 52px;
  color: ${(props) => props.theme.green};
`;
const Title = styled.div`
  text-align: center;
  color: ${(props) => props.theme.marineBlue};
  p {
    font-weight: bold;
  }
`;

interface Props {
  companyId: string;
  selectedUsers: SelectedUser[];
  setSelectedUsers: React.Dispatch<React.SetStateAction<SelectedUser[]>>;
  handleCloseModal: () => void;
  setRefreshUsersCount?: React.Dispatch<React.SetStateAction<number>>;
}
const getRemovedUsers = (selectedUsers: SelectedUser[], companyId: string) => {
  const selectedUsersIds = [];
  const endpoints = [];
  const removedUsers = [];
  for (const user of selectedUsers) {
    selectedUsersIds.push(user.subjectId);
    const filteredCompanies = user.companies.filter((c: Company) => c.id?.toLowerCase() !== companyId?.toLowerCase());
    removedUsers.push({
      ...user,
      companies: filteredCompanies,
      timestamp: currentTime,
      isLocalStorageItem: true,
    });
    endpoints.push(`/authorization/${user.subjectId}/companies/${companyId}`);
  }
  return { selectedUsersIds, endpoints, removedUsers };
};
export const RemoveUsersFromCompanyModal = ({
  companyId,
  selectedUsers,
  setSelectedUsers,
  handleCloseModal,
  setRefreshUsersCount,
}: Props) => {
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isError, setIsError] = useState(false);
  const modalOptions = {
    size: 'small',
    title: 'Remove User From Company',
  };
  const sortedSelectedUsers = selectedUsers.slice().sort(sortUsers);
  const selectedUsersTitle =
    selectedUsers.length > 1 ? `Multiple users will be removed (${selectedUsers.length})` : `This user will be removed`;
  const { getItem, setItem } = useLocalStorage('users');
  const localStorageUsers = getItem() ?? [];
  const { getItem: removedUsersIds, setItem: setRemovedUsersIds } = useLocalStorage('removedUsers');
  const localStorageRemovedUsers = removedUsersIds() || [];

  const handleRemoveUsers = async () => {
    const { selectedUsersIds, endpoints, removedUsers } = getRemovedUsers(selectedUsers, companyId);
    try {
      for (const endpoint of endpoints) {
        await deleteUserFromCompany(endpoint);
      }
      setRemovedUsersIds([...localStorageRemovedUsers, selectedUsersIds]);
      const filteredUsers = localStorageUsers.filter(
        (u: any) => !removedUsers.some((removedUser) => removedUser.subjectId === u.subjectId)
      );
      setItem([...removedUsers, ...filteredUsers]);
      setRefreshUsersCount && setRefreshUsersCount((prev) => prev + 1);
      setIsSubmitted(true);
      setTimeout(() => {
        handleCloseModal();
      }, 2000);
    } catch (error) {
      setIsError(true);
      setIsSubmitted(false);
    }
    setSelectedUsers([]);
  };

  return (
    <ModalLayout closeModal={handleCloseModal} options={modalOptions}>
      {isError && (
        <Title>
          <StyledWarningFilled error />
          <div>Something went wrong.</div>
        </Title>
      )}
      {isSubmitted && (
        <Title>
          <StyledCheck />
          <div>Removed succesfully.</div>
        </Title>
      )}
      {!isError && !isSubmitted && (
        <Title>
          <StyledWarningFilled />
          <>
            <p>{selectedUsersTitle}</p>
            <SelectedUsersTable color="blue" selectedUsers={sortedSelectedUsers} />
          </>
          <p>
            Are you sure you want to remove
            {selectedUsers.length > 1 ? ' users' : ' user'}?
          </p>
        </Title>
      )}
      <Column>
        {isSubmitted || isError ? (
          <SlimButton variant="secondary" onClick={handleCloseModal}>
            CLOSE
          </SlimButton>
        ) : (
          <ButtonGroup alignRight buttonGap="16px" marginTop="">
            <SlimButton variant="secondary" onClick={handleCloseModal}>
              CANCEL
            </SlimButton>
            <SlimButton onClick={handleRemoveUsers}>Remove</SlimButton>
          </ButtonGroup>
        )}
      </Column>
    </ModalLayout>
  );
};
