import React, { useState } from 'react';
import styled from 'styled-components';
import { Company } from '../../types';
import { CellContent, CellTitle } from './Components';
import { IconButton } from '@instech/components';
import { EditCompanyModal } from '../modal/EditCompanyModal';
import { EditIcon } from '../shared/Components';

const Pane = styled.div`
  background-color: ${(props) => props.theme.white};
  box-shadow: 0 1px 6px 0 rgba(173, 173, 173, 0.5);
  padding: 16px;
  margin-bottom: 47px;
`;
const GridWrapper = styled.div`
  display: flex;
`;
const Grid = styled.div`
  flex: 3;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 8px;
  row-gap: 24px;
`;
const EditButton = styled(IconButton)`
  margin: 8px;
  & svg {
    color: ${(props) => props.theme.marineBlue};
    width: 16px;
  }
`;
const Cell = styled.div`
  & svg {
    height: 16px;
    color: ${(props) => props.theme.blue};
  }
`;

interface InfoProps {
  company: Company;
  setIsEditCompanyVisible: (prev: boolean) => void;
}

const Info = ({ company, setIsEditCompanyVisible }: InfoProps) => (
  <GridWrapper>
    <Grid>
      <Cell>
        <CellTitle>Name</CellTitle>
        <CellContent>{company.name}</CellContent>
      </Cell>
      <Cell>
        <CellTitle>Type</CellTitle>
        <CellContent>{company.type}</CellContent>
      </Cell>
      <Cell>
        <CellTitle>CRM Number</CellTitle>
        <CellContent>{company.companyNumber}</CellContent>
      </Cell>
      <Cell>
        <CellTitle>City</CellTitle>
        <CellContent>{company.city}</CellContent>
      </Cell>
      <Cell>
        <CellTitle>Country</CellTitle>
        <CellContent>{company.country}</CellContent>
      </Cell>
      <Cell>
        <CellTitle>Email</CellTitle>
        <CellContent>{company.email}</CellContent>
      </Cell>
    </Grid>
    <EditButton icon={<EditIcon />} height="16px" width="16px" onClick={() => setIsEditCompanyVisible(true)} />
  </GridWrapper>
);

interface CompanyInfoProps {
  company: Company;
  mutate: () => void;
}

export const CompanyInfo = ({ company, mutate }: CompanyInfoProps) => {
  const [isEditCompanyVisible, setIsEditCompanyVisible] = useState<boolean>(false);

  return (
    <Pane>
      {isEditCompanyVisible && (
        <EditCompanyModal company={company} handleCloseModal={() => setIsEditCompanyVisible(false)} mutate={mutate} />
      )}
      <Info company={company} setIsEditCompanyVisible={setIsEditCompanyVisible} />
    </Pane>
  );
};
