import React from 'react';
import styled from 'styled-components';
import { ArrowBack, ArrowForward } from '@instech/icons';
import { SlimButton } from '@instech/components';

export const ProgressButtons = styled.div`
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  margin-left: 24px;
  & > button {
    margin-bottom: 8px;
  }
`;

interface ButtonProps {
  width?: string;
  disabled?: boolean;
  onClick: () => any;
  inverted?: boolean;
}

export const NextButton = (props: ButtonProps) => (
  <SlimButton endIcon={<ArrowForward />} {...props}>
    NEXT
  </SlimButton>
);

export const PreviousButton = (props: ButtonProps) => (
  <SlimButton inverted startIcon={<ArrowBack />} {...props}>
    PREVIOUS
  </SlimButton>
);

export const PublishButton = (props: ButtonProps) => <SlimButton {...props}>PUBLISH NOW</SlimButton>;

export const SchedulePublishButton = (props: ButtonProps) => (
  <SlimButton inverted {...props}>
    SCHEDULE PUBLISH
  </SlimButton>
);

export const PushChangesButton = (props: ButtonProps) => <SlimButton {...props}>PUSH CHANGES</SlimButton>;

export const CancelButton = (props: ButtonProps) => (
  <SlimButton inverted {...props}>
    CANCEL
  </SlimButton>
);

export const SaveDraftButton = (props: ButtonProps) => (
  <SlimButton inverted {...props}>
    SAVE DRAFT
  </SlimButton>
);

export const UploadImageButton = (props: ButtonProps) => (
  <SlimButton inverted {...props}>
    UPLOAD THUMBNAIL
  </SlimButton>
);
