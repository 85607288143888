import React from 'react';
import { UserSearchOptionProps } from '../../types';
import AsyncSelect from 'react-select/async';

interface AsyncSelectFieldProps {
  loadOptions: (value: string) => void;
  onInputChange?: any;
  onChange: any;
  placeholder?: string;
  value?: string | UserSearchOptionProps;
}

export const AsyncSelectField = ({
  loadOptions,
  onInputChange,
  onChange,
  placeholder,
  value,
}: AsyncSelectFieldProps) => (
  <AsyncSelect
    value={value}
    loadOptions={loadOptions}
    onInputChange={onInputChange}
    onChange={onChange}
    placeholder={placeholder}
  />
);
