import React, { useState, createContext, useContext, ReactNode, useMemo } from 'react';
import { COMPANIES_PAGE_KEY, USERS_PAGE_KEY } from './components/shared/constants';

interface AppContextProps {
  companiesCurrentPage: number;
  setCompaniesCurrentPageNumber: (newPage: number) => void;
  usersCurrentPage: number;
  setUsersCurrentPageNumber: (newPage: number) => void;
}

const AppContext = createContext<AppContextProps>({
  companiesCurrentPage: 1,
  setCompaniesCurrentPageNumber: (newPage: number) => {},
  usersCurrentPage: 1,
  setUsersCurrentPageNumber: (newPage: number) => {},
});

interface AuthContextProviderProps {
  children: ReactNode;
}

export const AppContextProvider = ({ children }: AuthContextProviderProps) => {
  const savedCompaniesCurrentPage = localStorage.getItem(COMPANIES_PAGE_KEY)
    ? localStorage.getItem(COMPANIES_PAGE_KEY)
    : 1;
  const [companiesCurrentPage, setCompaniesCurrentPage] = useState<number>(Number(savedCompaniesCurrentPage));
  const setCompaniesCurrentPageNumber = (number: number) => {
    localStorage.setItem(COMPANIES_PAGE_KEY, `${number}`);
    setCompaniesCurrentPage(number);
  };

  const savedUsersCurrentPage = localStorage.getItem(USERS_PAGE_KEY) ? localStorage.getItem(USERS_PAGE_KEY) : 1;
  const [usersCurrentPage, setUsersCurrentPage] = useState<number>(Number(savedUsersCurrentPage));
  const setUsersCurrentPageNumber = (number: number) => {
    localStorage.setItem(USERS_PAGE_KEY, `${number}`);
    setUsersCurrentPage(number);
  };

  const value = useMemo(() => {
    return {
      companiesCurrentPage,
      setCompaniesCurrentPageNumber,
      usersCurrentPage,
      setUsersCurrentPageNumber,
    };
  }, [companiesCurrentPage, setCompaniesCurrentPageNumber, usersCurrentPage, setUsersCurrentPageNumber]);

  return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
};

export const useAppContext = () => useContext<AppContextProps>(AppContext);
