import { useEffect, useState, DependencyList, Dispatch, SetStateAction } from 'react';
import { PagingMetadataProps, ColumnMetadataProps } from '../types';
import { PAGE_SIZE } from '../components/shared/constants';
import { useLocalStorage } from './useLocalStorage';

interface SearchParams {
  selectedCompanyIds?: string[] | null;
  freeText?: string | null;
  includeCrmData?: boolean;
  pageNumber?: number;
  pageSize: number;
  orderBy?: string;
}
interface SearchResult<T> {
  items: T[];
  pagingMetadata: PagingMetadataProps;
  columnMetadata: ColumnMetadataProps[];
}
interface SearchHookResult<TData> {
  data: TData[];
  setData: Dispatch<SetStateAction<TData[]>>;
  pagingMetadata: PagingMetadataProps;
  columnMetadata: ColumnMetadataProps[];
  isFetching: boolean;
}

export const useSearchWithLocalStorage = <TData>(
  searchFunction: (params: SearchParams) => Promise<SearchResult<TData>>,
  searchParams: SearchParams,
  dependencies: DependencyList,
  localStorageItemName: string,
  idProperty: string
): SearchHookResult<TData> => {
  const [data, setData] = useState<TData[]>([]);
  const [pagingMetadata, setPagingMetadata] = useState<PagingMetadataProps>({
    currentPage: 1,
    hasNext: false,
    hasPrevious: false,
    pageSize: PAGE_SIZE,
    totalCount: 0,
    totalPages: 0,
  });
  const [columnMetadata, setColumnMetadata] = useState<ColumnMetadataProps[]>([]);

  const [isFetching, setIsFetching] = useState(false);
  const { getItem } = useLocalStorage(localStorageItemName);

  const selectedCompanyId = searchParams.selectedCompanyIds && searchParams.selectedCompanyIds[0].toUpperCase();
  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsFetching(true);
        const searchData = await searchFunction(searchParams);
        const localStorageData = getItem() || [];
        const filteredLocalStorageData = selectedCompanyId
          ? localStorageData.filter((data: any) =>
              data.companies.some((c: any) => c.id.toUpperCase() === selectedCompanyId)
            )
          : localStorageData;

        const filteredSearchData = searchData.items.filter((data: any) => {
          if (data.externalId)
            return !localStorageData.some((localData: any) => localData.externalId === data.externalId);
          return !localStorageData.some(
            (localData: TData) => (localData as any)[idProperty] === (data as any)[idProperty]
          );
        });
        setData([...filteredLocalStorageData, ...filteredSearchData]);
        setPagingMetadata(searchData.pagingMetadata);
        setColumnMetadata(searchData.columnMetadata);
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setIsFetching(false);
      }
    };
    fetchData();
  }, dependencies);
  return { data, setData, pagingMetadata, columnMetadata, isFetching };
};
