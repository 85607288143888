import React, { useState } from 'react';
import { CheckboxControlled } from '@instech/components';
import { Company, SourceSystem, User } from '../../types';
import { UserFromSearchRole } from './userTypes';
import { TableCell, TableRow } from '../shared/Table';
import { AddEditUserModal } from '../modal/AddEditUserModal';
import { Tooltip, TooltipText, CrmLabel, EditIcon } from '../shared/Components';
import styled from 'styled-components';

const StyledTableCell = styled(TableCell)`
  justify-content: right;
  padding-right: 16px;
`;

interface UserRowProps {
  user: User;
  even: boolean;
  selected: boolean;
  handleCheck: (user: User) => void;
  allAuthenticationMethods: Map<string, string>;
  setRefreshUsersCount?: React.Dispatch<React.SetStateAction<number>>;
}

export const UserRow = ({
  user,
  even,
  selected,
  handleCheck,
  allAuthenticationMethods,
  setRefreshUsersCount,
}: UserRowProps) => {
  const [isEditUserVisible, setIsEditUserVisible] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const openEditUser = () => setIsEditUserVisible(true);
  const isCrmUser = user.sourceSystem === SourceSystem.Crm;

  const handleRowClick = () => {
    if (isCrmUser) return;
    handleCheck(user);
  };

  const getUserMethodNames = (allMethods: any, userMethods: any) => {
    if (!userMethods) return '';
    const userMethodsNames = new Set();
    for (const userMethod of userMethods) {
      const displayName = allMethods.get(userMethod.id);
      userMethodsNames.add(displayName);
    }
    return [...userMethodsNames].join(', ');
  };

  const userAuthMethodsNames =
    allAuthenticationMethods && getUserMethodNames(allAuthenticationMethods, user.identities);

  const companyNames = user?.companies?.map((c: Company) => c.name);
  const userCompanies =
    companyNames?.length > 5 ? `${companyNames.slice(0, 5).join(', ')}...` : companyNames?.join(', ');
  const userCompaniesShort =
    userCompanies?.split(',').slice(0, 2).join(', ') +
    (userCompanies?.split(',').length > 2 ? ` +${user.companies?.length - 2}` : '');
  const isLocalStorageItem = user?.isLocalStorageItem;

  const userRoles = user?.roles
    ?.map((r: UserFromSearchRole) => r.roleDisplayName + ' (' + r.applicationName + ')')
    .join(', ');

  const userRolesShort =
    userRoles?.split(',').slice(0, 2).join(', ') +
    (userRoles?.split(',').length > 2 ? ` +${user.roles?.length - 2}` : '');

  return (
    <>
      {isEditUserVisible && (
        <AddEditUserModal
          setRefreshUsersCount={setRefreshUsersCount}
          handleCloseModal={() => setIsEditUserVisible(false)}
          user={user}
        />
      )}
      <TableRow
        even={even}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        isLocalStorageItem={isLocalStorageItem}
        isCrmItem={isCrmUser && !isLocalStorageItem}
      >
        <TableCell>
          {!isCrmUser && (
            <CheckboxControlled
              name={user.subjectId}
              noErrors
              noTopLabel
              noPadding
              value={user.subjectId}
              selected={selected}
              onChange={handleRowClick}
            />
          )}
        </TableCell>
        <TableCell onClick={handleRowClick}>{user.name}</TableCell>
        <TableCell onClick={handleRowClick}>{user.title}</TableCell>
        <TableCell onClick={handleRowClick}>{user.email}</TableCell>
        <TableCell onClick={handleRowClick}>{user.phoneNumber}</TableCell>
        <TableCell onClick={handleRowClick}>
          {user.companies?.length > 2 ? (
            <Tooltip>
              <TooltipText>{userCompanies}</TooltipText>
              {userCompaniesShort}
            </Tooltip>
          ) : (
            userCompanies
          )}
        </TableCell>
        <TableCell onClick={handleRowClick}>{userAuthMethodsNames}</TableCell>
        <TableCell onClick={handleRowClick}>
          {user.roles?.length > 2 ? (
            <Tooltip>
              <TooltipText>{userRoles}</TooltipText>
              {userRolesShort}
            </Tooltip>
          ) : (
            userRoles
          )}
        </TableCell>
        <TableCell onClick={handleRowClick}>
          {isCrmUser && !isLocalStorageItem ? (
            <CrmLabel onClick={openEditUser}>Import From CRM</CrmLabel>
          ) : user.enabled ? (
            'Active'
          ) : (
            'Passive'
          )}
        </TableCell>
        <StyledTableCell>
          {(isHovered || selected) && !isCrmUser && <EditIcon onClick={openEditUser} />}
        </StyledTableCell>
      </TableRow>
    </>
  );
};
