import React from 'react';
import { LabelValuePair } from '../../types';
import { SearchWrapper } from './core/Components';
import { Search } from './core/Search';

interface SearchProps {
  onSearch: (searchTerm?: string) => void;
  onReset: () => void;
}
interface FilteredSearchProps extends SearchProps {
  filterOptions?: LabelValuePair[];
  selectedOption?: string;
  onFilter: (option?: string) => void;
}

export const SearchBar = ({ onSearch, onReset }: SearchProps) => (
  <SearchWrapper>
    <Search onReset={onReset} onSearch={onSearch} />
  </SearchWrapper>
);

export const FilteredSearchBar = ({ onSearch, onReset }: FilteredSearchProps) => (
  <SearchWrapper>
    <Search onReset={onReset} onSearch={onSearch} />
  </SearchWrapper>
);
