/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/forbid-prop-types */
import React, { useEffect, useRef, useState, Suspense } from 'react';
import { createPortal } from 'react-dom';
import PropTypes from 'prop-types';
import { Loader } from '../shared/Loader';
import { ModalProvider } from './ModalContext';
import { ModalLayout } from './ModalLayout';
import { ErrorBoundary, DefaultErrorMessage } from '@instech/components';

let modalRoot = document.getElementById('modal');
if (!modalRoot) {
  modalRoot = document.createElement('div');
  modalRoot.setAttribute('id', 'modal');
  modalRoot.setAttribute('data-testid', 'modal');
  document.body.appendChild(modalRoot);
}

const Modal = ({ children, options, closeModal }) => {
  const elRef = useRef(null);
  if (!elRef.current) {
    elRef.current = document.createElement('div');
  }

  useEffect(() => {
    modalRoot.appendChild(elRef.current);
    document.body.style.overflow = 'hidden';

    return () => {
      modalRoot.removeChild(elRef.current);
      document.body.style.overflow = null;
    };
  }, []);

  return createPortal(
    <ModalLayout options={options} closeModal={closeModal}>
      {children}
    </ModalLayout>,
    elRef.current
  );
};
Modal.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  closeModal: PropTypes.func.isRequired,
  options: PropTypes.shape({
    title: PropTypes.string,
    footer: PropTypes.bool,
  }),
};

const ModalContent = ({ component: Component, args }) => <Component {...args} />;
ModalContent.propTypes = {
  component: PropTypes.func.isRequired,
  args: PropTypes.object,
};

export const ModalHandler = ({ children }) => {
  const [modal, setModal] = useState(null);
  const close = () => setModal(null);
  return (
    <ModalProvider open={setModal} close={close}>
      {children}
      {modal && (
        <Modal options={modal.options} closeModal={close}>
          <ErrorBoundary component={<DefaultErrorMessage reset={close} />}>
            <Suspense fallback={<Loader />}>
              <ModalContent component={modal.component} args={modal.args} />
            </Suspense>
          </ErrorBoundary>
        </Modal>
      )}
    </ModalProvider>
  );
};
ModalHandler.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};

export default Modal;
