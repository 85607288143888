import useSWR from 'swr';
import { getJsonAsync, putJsonAsync } from './client';
import { SelectedUser } from '../components/UsersPage/userTypes';

export const useApplicationsRoles = () => {
  const url = 'taxonomy/applications';
  const { data } = useSWR(url, () => getJsonAsync(url));
  return data;
};

const getUserApplications = (subjectId: string) => getJsonAsync(`authorization/${subjectId}`);

const getUsersApplications = async (selectedUsers: SelectedUser[]) => {
  const userPromises = selectedUsers.map(async (user) => {
    return await getUserApplications(user.subjectId);
  });
  return Promise.all(userPromises);
};

export const fetchUsersApplications = async (selectedUsers: SelectedUser[]) => {
  const usersApplications = await getUsersApplications(selectedUsers);
  return usersApplications;
};

export interface RoleOperation {
  roleId: string;
  getType(): string;
}
export class RoleAddition implements RoleOperation {
  roleId: string = '';
  getType = (): string => 'add';
  constructor(roleId: string) {
    this.roleId = roleId;
  }
}
export class RoleRemoval implements RoleOperation {
  roleId: string = '';
  getType = (): string => 'remove';
  constructor(roleId: string) {
    this.roleId = roleId;
  }
}

export const manageUserRoles = (subjectId: string, operations: RoleOperation[]) => {
  const request = {
    subjectId: subjectId,
    operations: operations.map((x) => ({
      $type: x.getType(),
      roleId: x.roleId,
    })),
  };
  return putJsonAsync(`/v2.0/authorization/${subjectId}/roles`, request);
};
