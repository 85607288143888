import React from 'react';
import { NhcLogoSymbol } from '@instech/icons';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { Navigation } from './Navigation';
import { PortalSelector, UserMenu } from 'insify-remote-component-loader';
import { useNavigate } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { useAuthContext } from '../../../authentication/AuthContext';

const Row = styled.div<{ scrolled: boolean }>`
  height: ${(props) => (props.scrolled ? props.theme.header.scrolledHeight : '70px')};
  position: ${(props) => props.scrolled && 'sticky'};
  top: 0;
  z-index: 40;
  padding: 0px 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: ${(props) => props.theme.marineBlue};
  color: ${(props) => props.theme.white};
`;

const LogoText = styled.span`
  font-size: 1.2em;
  font-weight: bold;
  font-family: Century Gothic, CenturyGothic, AppleGothic, sans-serif;
`;

const LogoWrapper = styled(Link)<{ scrolled: boolean }>`
  display: flex;
  align-items: center;
  column-gap: 30px;
  text-decoration: none;
  color: ${(props) => props.theme.white};
  & svg {
    height: ${(props) => (props.scrolled ? '32px' : '48px')};
  }
  @media screen and (max-width: 1000px) {
    flex-direction: column;
    font-size: 0.8em;
    padding-right: 16px;
    & svg {
      height: 32px;
    }
  }
`;
const MenuSection = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 16px;
`;

const Logo = ({ scrolled = false }) => (
  <LogoWrapper to="/" scrolled={scrolled}>
    <NhcLogoSymbol alt="NHC logo" />
    <LogoText>Admin</LogoText>
  </LogoWrapper>
);

interface HeaderProps {
  scrolled: boolean;
}

export const Header = ({ scrolled = false }: HeaderProps) => {
  const { userRole, account } = useAuthContext();

  const navigate = useNavigate();
  const history = createBrowserHistory();
  history.push = (to, state) => navigate(to, state);
  history.replace = (to, state) => navigate(to, { ...state, replace: true });

  const portal = { name: 'Administration', displayName: 'Administration' };

  const links: React.ComponentProps<typeof UserMenu>['links'] = [
    {
      href: '/announcements',
      title: 'Announcements',
    },
  ];

  return (
    <Row scrolled={scrolled}>
      <Logo scrolled={scrolled} />
      <Navigation />
      <MenuSection>
        <UserMenu account={account} history={history} role={userRole} portal={portal} links={links} />
        <PortalSelector account={account} portal={portal} />
      </MenuSection>
    </Row>
  );
};
