import React, { useState, useEffect, useMemo } from 'react';
import styled from 'styled-components';
import { Checkbox } from '../../shared/Form/fields/Checkbox';
import { FormlessCheckbox } from '../../shared/FormlessFields/Checkbox';
import { useField } from 'formik';

// Function deprecated for now, will be put into use again later?
// const optionCountToString = (option: { name: string; count: number; }) =>
//   (`${option.name} - ${option.count}`);

const Container = styled.div<{ visible?: boolean }>`
  background-color: ${(props) => props.theme.marineBlue10};
  padding: 20px;
  display: ${(props) => (props.visible ? 'block' : 'none')};
  &:not(:last-of-type) {
    margin-bottom: 20px;
  }
`;

const Title = styled.div`
  margin-bottom: 8px;
  font-weight: bold;
  color: ${(props) => props.theme.marineBlue};
`;

const Flex = styled.div`
  display: flex;
`;

const Options = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  width: 100%;
  & > * {
    width: auto;
    margin-right: 12px;
  }
`;

const AllOption = styled.div`
  display: flex;
  align-items: center;
  flex-shrink: 0;
  width: 130px;
`;

const VerticalDivider = styled.div`
  flex-shrink: 0;
  width: 1px;
  margin: 0px 16px;
  background: ${(props) => props.theme.marineBlue};
`;

const checkUnavailableOptions = (options: RecipientOption[]) =>
  options.some((option) => option.editableByCurrentUser === false);

export interface RecipientOption {
  displayName: string;
  count: number;
  editableByCurrentUser?: boolean;
}

interface Props {
  title: string;
  fieldname: string;
  selectHideIfOne?: boolean;
  options: RecipientOption[];
}
export const RecipientGroup = ({ title, fieldname, selectHideIfOne, options }: Props) => {
  const [allSelected, setAllSelected] = useState<boolean>(false);
  const [visible] = useState<boolean>(!(options?.length <= 1 && selectHideIfOne));
  const [field, , helpers] = useField(fieldname);

  const reducedOptions = useMemo(() => options.map((option) => option.displayName), [options]);
  const unavailableOptions = checkUnavailableOptions(options);

  // On toggle, if not allSelected, add all options to Value.
  // If allSelected, remove all options from Value.
  // The allSelected switch is then handled by useEffect based on new Value.
  const handleToggleAll = () => {
    if (unavailableOptions) return;
    if (!allSelected) helpers.setValue(reducedOptions);
    if (allSelected) helpers.setValue([]);
  };

  useEffect(() => {
    if (!visible) {
      helpers.setValue(reducedOptions);
      return;
    }
    // If the user manually checks all options, or unchecks one when
    // all were checked, automatically toggle the All option.
    const allChecked = field.value && field.value.length === reducedOptions.length;
    if (allChecked && !allSelected) setAllSelected(true);
    if (!allChecked && allSelected) setAllSelected(false);
  }, [field.value, options]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Container visible={visible} data-testid={title}>
      <Title>{title}</Title>
      <Flex>
        <AllOption>
          <FormlessCheckbox
            rightLabel="All"
            selected={allSelected}
            disabled={unavailableOptions}
            onClick={handleToggleAll}
          />
        </AllOption>
        <VerticalDivider />
        <Options>
          {options.map((option) => (
            <Checkbox
              key={option.displayName}
              noErrors
              name={fieldname}
              value={option.displayName}
              rightLabel={`${option.displayName} (${option.count})`}
              disabled={!option.editableByCurrentUser}
            />
          ))}
        </Options>
      </Flex>
    </Container>
  );
};
