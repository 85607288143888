import styled, { css } from 'styled-components';

export const SearchWrapper = styled.div`
  display: flex;
  min-height: 44px;
  margin: 16px 0;
`;

export const SearchInput = styled.input`
  border: none;
  border-radius: 4px;
  font-size: 18px;
  flex: 1;
  padding: 9px 16px 11px 50px;
  outline-offset: -2px;

  &::-ms-clear {
    display: none;
  }
`;

export const ClearButton = styled.button<{ roundLeft?: boolean; hidden?: boolean }>`
  position: absolute;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 100%;
  padding: 0;
  padding-left: 2px;
  border: none;
  background: none;
  cursor: pointer;

  & svg {
    flex-shrink: 0;
    width: 16px;
    opacity: 0.6;
  }

  ${(props) =>
    props.hidden &&
    css`
      cursor: default;

      svg {
        visibility: hidden;
      }
    `}
`;
