import useSWR from 'swr';
import { getJsonAsync, postJsonAsync, deleteJsonAsync } from './client';

const authenticationMethodsUrl = '/authentication-methods';

interface PayloadProps {
  authenticationMethodId: string;
  subjectId: string;
}

export const useAuthenticationMethods = () => {
  const { data } = useSWR(authenticationMethodsUrl, () => getJsonAsync(authenticationMethodsUrl));
  return data;
};

export const postUsersAuthenticationMethods = (path: string, payload: PayloadProps[]) =>
  postJsonAsync(`${authenticationMethodsUrl}${path}`, payload);

export const deleteUsersAuthenticationMethods = (payload: PayloadProps[]) =>
  deleteJsonAsync(authenticationMethodsUrl, payload);
