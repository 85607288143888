/* eslint-disable react/jsx-props-no-spreading,no-nested-ternary */
import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import { Field, useField } from 'formik';
import { FieldLayout, FieldLabel, EmptyLabel, ErrorMessage } from '../core/Components';

interface PaddingProps {
  noPadding?: boolean;
}

export const Padding = styled.span<PaddingProps>`
  padding: ${(props) => (props.noPadding ? '0px' : '10px')};
`;

interface CheckboxFieldProps {
  error?: boolean | string;
  disabled?: boolean;
}

export const CheckboxField = styled.div<CheckboxFieldProps>`
  display: flex;
  flex-direction: row;

  & label {
    display: flex;
    align-items: center;
    line-height: 21px;
    cursor: ${(props) => (props.disabled ? 'default' : 'pointer')};
  }
  & input[type='checkbox']:disabled {
    cursor: default;
  }
  & input[type='checkbox'] {
    position: relative;
    display: flex;
    align-items: center;
    width: 20px;
    height: 20px;
    line-height: 21px;
    box-sizing: content-box;
    appearance: none;
    cursor: ${(props) => (props.disabled ? 'default' : 'pointer')};

    &::before {
      content: '';
      display: inline-block;
      left: 0px;
      width: 20px;
      height: 20px;
      background: ${(props) => (props.disabled ? props.theme.lightGray : props.theme.white)};
      border: 1px solid
        ${(props) =>
          props.error ? props.theme.red : props.disabled ? props.theme.mediumGray : props.theme.border.gray};
      box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.05);
      box-sizing: border-box;
    }
    &:checked::before {
      background: ${(props) => (props.disabled ? props.theme.lightGray : props.theme.marineBlue)};
      border-color: ${(props) => (props.disabled ? props.theme.mediumGray : props.theme.marineBlue)};
    }
    &::after {
      content: '';
      display: block;
      position: absolute;
      width: 0px;
      height: 0px;
      bottom: 5px;
      left: 7px;
      border: 1px solid transparent;
      border-bottom-color: ${(props) => (props.disabled ? props.theme.lightGray : props.theme.white)};
      border-right-color: ${(props) => (props.disabled ? props.theme.lightGray : props.theme.white)};
      transform: rotate(45deg);
    }
    &:checked::after {
      width: 5px;
      height: 13px;
      border-bottom-color: ${(props) => props.theme.white};
      border-right-color: ${(props) => props.theme.white};
    }
  }
`;

interface Props {
  name: string;
  value?: string;
  label?: string;
  emptyLabel?: boolean;
  rightLabel?: string;
  noErrors?: boolean | string;
  className?: string;
  disabled?: boolean;
  /** Unique identifier to couple label and input field. */
  fieldId?: string;
  noPadding?: boolean;
}
export const Checkbox: FunctionComponent<Props> = ({
  fieldId,
  name,
  value,
  label,
  emptyLabel,
  rightLabel,
  noErrors,
  className,
  disabled,
  noPadding,
  ...fieldProps
}) => {
  const [field, meta] = useField(name);
  return (
    <FieldLayout className={className}>
      {label && !emptyLabel && (
        <FieldLabel as="label" htmlFor={fieldId}>
          {label}
        </FieldLabel>
      )}
      {!label && emptyLabel && <EmptyLabel />}
      <CheckboxField error={meta.touched && meta.error} disabled={disabled} {...fieldProps}>
        <label htmlFor={fieldId}>
          <Padding noPadding={noPadding}>
            <Field
              id={fieldId}
              name={name}
              type="checkbox"
              value={value ?? field.checked}
              disabled={disabled}
              {...fieldProps}
            />
          </Padding>
          {rightLabel}
        </label>
      </CheckboxField>
      {!noErrors && <ErrorMessage>{meta.error && meta.touched ? meta.error : ''}</ErrorMessage>}
    </FieldLayout>
  );
};
