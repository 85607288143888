import { useImagesLibraryModal } from '../../../tools/Images/core/ImagesLibraryModal/ImagesLibraryModal';
import { useImagePreviewById } from '../../../../services/imageServices/imageServices';
import { Article } from '../../../../types';
import { ImageCard, RichText } from '@instech/components';
import { FormikProps } from 'formik';
import React from 'react';
import { FormValues, getFieldArrayError } from './utils';

const ImageLoader = ({ id }: { id: string }) => {
  const { data: image } = useImagePreviewById(id, 'small');
  return <ImageCard image={image} />;
};

export const RichTextArrayField = ({
  index,
  value,
  formik,
}: {
  index: number;
  value: Article;
  formik: FormikProps<FormValues>;
}) => {
  const currentImages = formik.getFieldMeta(`articles.${index}.images`).value as string[];

  const { open: openArticleImagesModal } = useImagesLibraryModal({
    title: 'Article images',
    selectOneImage: false,
    onImageChange: (images) => formik.setFieldValue(`articles.${index}.images`, images),
    imageState: currentImages ?? [],
  });

  return (
    <RichText
      fieldId={`articles.${index}`}
      textState={value.text}
      imageState={value.images}
      onRichTextChange={(val) => formik.setFieldValue(`articles.${index}.text`, val)}
      imageModal={openArticleImagesModal}
      ImageLoader={ImageLoader}
      label="Text"
      error={getFieldArrayError(formik, `articles.${index}.text`)}
      required
    />
  );
};
