import React from 'react';
import styled from 'styled-components';
import { SlimButton } from '@instech/components';
import { StyledButtonGroup } from '../shared/Components';

const Wrapper = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  background-color: rgba(0, 0, 0, 0.5);
  color: ${(props) => props.theme.marineBlue};
  z-index: 1;
`;
const Dialog = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  border-radius: 2px;
  left: 35%;
  right: 35%;
  top: 35%;
  bottom: 35%;
  height: fit-content;
  margin: auto;
  background: white;
  padding: 0;
`;
const Text = styled.h2`
  text-align: center;
  font-weight: normal;
  margin: 1em;
`;
const Button = styled.button`
  align-self: flex-end;
  margin: 5px;
  padding-left: 6px;
  padding-right: 6px;
  background: none;
  color: #de350b;
  font-size: 24px;
  line-height: 24px;
  outline: none;
  border: none;
  border-radius: 2px;
  align-items: center;
  transition: all 0.2s ease;
  :hover {
    background-color: #ffbdad;
    transform: scale(1.3);
  }
`;
interface ConfirmModalDialogProps {
  text: string;
  cancelButtonText?: string | null;
  confirmButtonText?: string | null;
  onCancel: () => void;
  onConfirm: () => void | null;
}
export const ConfirmModalDialog = ({
  text,
  cancelButtonText = null,
  confirmButtonText = null,
  onCancel,
  onConfirm,
}: ConfirmModalDialogProps) => {
  return (
    <Wrapper>
      <Dialog>
        <Button onClick={onCancel}>x</Button>
        <Text>{text}</Text>
        <StyledButtonGroup alignRight>
          <SlimButton variant="secondary" onClick={onCancel}>
            {cancelButtonText}
          </SlimButton>
          <SlimButton variant="primary" onClick={onConfirm}>
            {confirmButtonText}
          </SlimButton>
        </StyledButtonGroup>
      </Dialog>
    </Wrapper>
  );
};
