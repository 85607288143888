import React, { useState } from 'react';
import { Company } from '../../types';
import { CompanyRow } from './CompanyRow';

interface CompaniesTableProps {
  companies: Company[];
  setRefreshCompaniesCount: React.Dispatch<React.SetStateAction<number>>;
}
export const CompaniesTable = ({ companies, setRefreshCompaniesCount }: CompaniesTableProps) => {
  const [addCompanyId, setAddCompanyId] = useState<string | null>(null);
  return (
    <>
      {companies.map((company, i) => (
        <CompanyRow
          key={company.id}
          company={company}
          even={i % 2 === 0}
          addCompanyId={addCompanyId}
          setAddCompanyId={setAddCompanyId}
          setRefreshCompaniesCount={setRefreshCompaniesCount}
        />
      ))}
    </>
  );
};
