import { AnnouncementStatus, Announcement, ContextMenuCallbacks } from '@/types';
import React from 'react';
import styled from 'styled-components';
import { ImageView, UpdatePreview } from '@instech/components';
import { getFriendlyUTCTimestamp, getFriendlyDate } from '../../../../utils/date/friendlyDates';
import { portalNames } from '../../../../utils/textutils';
import { useImagePreviewByIdAndAnnouncementId } from '../../../../services/imageServices/imageServices';
import { CardMeta } from './CardMeta';

const CardGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr)); // minmax prevents grid blowout
  grid-gap: 20px;
`;

const Card = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${(props) => props.theme.white};
  border: 1px solid ${(props) => props.theme.marineBlue20};
`;

const Content = styled.div`
  flex-grow: 1;
  word-break: break-word;
  padding: 20px;
`;

const previewTags = (announcement: Announcement) => [announcement.categoryOption, announcement.keywordOption];

// Return contextual timestamp or draft
const dateOrDraft = (date?: string) => (date ? getFriendlyUTCTimestamp(date) : 'Draft');

const AnnouncementCard = ({
  announcement,
  status,
  callbacks,
}: { announcement: Announcement } & Omit<Props, 'announcements'>) => {
  // eslint-disable-next-line react/no-unstable-nested-components
  const ImageLoader = ({ id }: { id: string }) => {
    const { data: image, error } = useImagePreviewByIdAndAnnouncementId(announcement.id, id, 'small');
    return <ImageView image={image} error={error} noBorder />;
  };
  return (
    <Card>
      <Content>
        <UpdatePreview
          title={announcement.title}
          shortText={announcement.shortText}
          tags={previewTags(announcement)}
          topRight={announcement.isDuplicate ? 'Duplicate' : dateOrDraft(announcement.notVisibleBefore ?? '')}
          imageId={announcement.cardImageId}
          ImageLoader={ImageLoader}
        />
      </Content>
      <CardMeta
        announcement={announcement}
        lastEdit={getFriendlyDate(announcement.lastUpdated)}
        product={portalNames(announcement.recipientStructure)}
        recipients={announcement.recipientStructure?.selectedCount}
        status={status}
        title={announcement.title}
        callbacks={callbacks}
      />
    </Card>
  );
};

interface Props {
  announcements: Announcement[];
  status: AnnouncementStatus;
  callbacks: ContextMenuCallbacks;
}
export const AnnouncementCards = ({ announcements, status, callbacks }: Props) => (
  <CardGrid>
    {announcements.map((announcement) => (
      <AnnouncementCard key={announcement.id} announcement={announcement} status={status} callbacks={callbacks} />
    ))}
  </CardGrid>
);
