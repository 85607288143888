import React from 'react';
// import { useCompanyTypes } from '../../services/companiesService';
import { FilteredSearchBar } from '../search/SearchBar';

interface Props {
  selectedType?: string;
  setSelectedType: (newType?: string) => void;
  onSearch: (searchTerm?: string) => void;
  onReset: () => void;
}
export const CompanySearch = ({
  selectedType,
  setSelectedType,
  onSearch,
  onReset,
}: Props) => {
  // const { data } = useCompanyTypes();
  const data = [
    {
      typeCode: 'AM',
      description: 'Asset Manager',
    },
    {
      typeCode: 'AD',
      description: 'Average Adjuster',
    },
    {
      typeCode: 'AA',
      description: 'Average Agent',
    },
    {
      typeCode: 'BA',
      description: 'Bank',
    },
    {
      typeCode: 'BR',
      description: 'Broker',
    },
    {
      typeCode: 'CA',
      description: 'Captive',
    },
    {
      typeCode: 'CH',
      description: 'Charterer',
    },
    {
      typeCode: 'YY',
      description: 'Company to be corrected',
    },
    {
      typeCode: 'CO',
      description: 'Correspondent P&I',
    },
    {
      typeCode: 'DD',
      description: 'Delete Company',
    },
    {
      typeCode: 'SA',
      description: 'Flag State Authority’',
    },
    {
      typeCode: 'IN',
      description: 'Insurer',
    },
    {
      typeCode: 'LA',
      description: 'Lawyer',
    },
    {
      typeCode: 'MA',
      description: 'Managers of vessels',
    },
    {
      typeCode: 'MI',
      description: 'Miscellaneous',
    },
    {
      typeCode: 'OO',
      description: 'Owner',
    },
    {
      typeCode: 'AS',
      description: 'P.E.T.I.',
    },
    {
      typeCode: 'PA',
      description: 'Port Authority',
    },
    {
      typeCode: 'RO',
      description: 'Registered Owner',
    },
    {
      typeCode: 'RI',
      description: 'Reinsurer',
    },
    {
      typeCode: 'ST',
      description: 'Salvage/Tug',
    },
    {
      typeCode: 'SY',
      description: 'Shipyard',
    },
    {
      typeCode: 'SU',
      description: 'Supplier',
    },
    {
      typeCode: 'TC',
      description: 'Technical Consultant',
    },
    {
      typeCode: 'XX',
      description: 'Temporary Created Company',
    },
  ];
  const options =
    data &&
    data.map(o => ({
      value: o.typeCode,
      label: o.description,
    }));
  return (
    <FilteredSearchBar
      filterOptions={options}
      selectedOption={selectedType}
      onFilter={setSelectedType}
      onSearch={onSearch}
      onReset={onReset}
    />
  );
};
