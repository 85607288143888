/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useAuthContext } from '../AuthContext';
import { Page } from '../../Page';
import { PageLoader } from '@instech/components';

const AuthenticationHandler = ({ children }: any) => {
  const { user, hasUserAccess } = useAuthContext();
  if (!user) {
    return <PageLoader />;
  }

  if (!hasUserAccess) {
    return <Navigate to="/no-access" />;
  }
  return children;
};

export const ProtectedRoute = () => (
  <AuthenticationHandler>
    <Page>
      <Outlet />
    </Page>
  </AuthenticationHandler>
);

export default ProtectedRoute;
