import React from 'react';
import { ApplicationRole } from '@/types';
import styled from 'styled-components';
import { PaneButton } from './PaneButton';
import { SlimButton } from '@instech/components';
import { routes } from '../../NewAnnouncementPage/utils';
import { Add } from '@instech/icons';
import { Link } from 'react-router-dom';

const StyledLink = styled(Link)`
  color: ${(props) => props.theme.marineBlue};
  text-decoration: none;
`;

const Pane = styled.div`
  background-color: ${(props) => props.theme.white};
  margin-bottom: 40px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.06);
`;

const PaneInner = styled.div`
  padding: 32px 24px 40px;
  display: flex;
  justify-content: space-between;
`;

const PageTitle = styled.h1`
  font-weight: normal;
  font-size: 40px;
  line-height: 40px;
  margin: 0px;
  color: ${(props) => props.theme.marineBlue};
`;

const ButtonRow = styled.div`
  display: flex;
`;

interface Props {
  applications: ApplicationRole[];
  setActiveTab: (application: ApplicationRole) => any;
  activeTab: ApplicationRole;
}

export const CategoryPane = ({ applications, activeTab, setActiveTab }: Props) => (
  <Pane>
    <PaneInner>
      <PageTitle>Announcements</PageTitle>
      <StyledLink to={routes.newAnnouncement}>
        <SlimButton startIcon={<Add />}>NEW ANNOUNCEMENT</SlimButton>
      </StyledLink>
    </PaneInner>
    <ButtonRow>
      <PaneButton
        key={`pane-button All Announcements`}
        label={'All'}
        active={activeTab.displayName === 'All Announcements'}
        onClick={() => setActiveTab({ displayName: 'All Announcements', name: 'All' })}
      />
      {applications.map((application) => (
        <PaneButton
          key={`pane-button ${application.displayName}`}
          label={application.displayName}
          active={activeTab.displayName === application.displayName}
          onClick={() => setActiveTab(application)}
        />
      ))}
    </ButtonRow>
  </Pane>
);
