import React from 'react';
import { NavLink, useMatch } from 'react-router-dom';
import styled from 'styled-components';

const Layout = styled.div`
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: flex-end;
`;

const NavEntry = styled(NavLink)`
  margin-top: 10px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  font-size: 0.8em;
  letter-spacing: 0.5px;
  padding: 10px 32px;
  text-align: center;
  text-decoration: none;
  color: white;
  &.active {
    background-color: ${(props) => props.theme.whiteBlue};
    color: ${(props) => props.theme.marineBlue};
    font-weight: 600;
  }
  @media screen and (max-width: 1000px) {
    font-size: 0.6em;
  }
`;

export const Navigation: React.FC = () => {
  const homePageMatch = useMatch('/');
  const companiesMatch = useMatch('/companies/*');
  const usersMatch = useMatch('/users/*');
  return (
    <Layout>
      <NavEntry to="/" className={homePageMatch || companiesMatch || usersMatch ? 'active' : ''}>
        ONBOARDING
      </NavEntry>{' '}
      <NavEntry to="/announcementsPage">ANNOUNCEMENTS</NavEntry>
    </Layout>
  );
};
