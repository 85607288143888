import React, { useState } from 'react';
import styled from 'styled-components';
import { SlimButton, ButtonGroup } from '@instech/components';
import { ModalLayout } from './ModalLayout';
import { CSVLink } from 'react-csv';
import { useSearchWithLocalStorage } from '../../hooks/useSearchWithLocalStorage';
import { User } from '../../types';
import { searchUsers } from '../../services/userService';
import { SelectedUsersTable } from '../shared/SelectedUsersTable';
import { Pagination } from '../shared/pagination/Pagination';
import { PAGE_SIZE } from '../shared/constants';

const Column = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  button {
    text-transform: uppercase;
  }
`;

const Title = styled.div`
  text-align: center;
  color: ${(props) => props.theme.marineBlue};
  p {
    font-weight: bold;
  }
`;

interface SearchProps {
  [key: string]: string[] | string | boolean | null;
}
interface DownloadCsvModalProps {
  handleCloseModal: () => void;
  searchParams: SearchProps;
}

export const DownloadCsvModal = ({ handleCloseModal, searchParams }: DownloadCsvModalProps) => {
  const modalOptions = {
    size: 'medium',
    title: 'Export to CSV file',
  };

  const { data } = useSearchWithLocalStorage<User>(
    searchUsers,
    {
      pageSize: 1000,
      pageNumber: 1,
      ...searchParams,
    },
    [searchParams],
    'users',
    'subjectId'
  );

  const usersTitle = `${data?.length} users selected`;

  const currentDate = new Date();

  type DateTimeFormatOptions = {
    year?: 'numeric' | '2-digit';
    month?: 'numeric' | '2-digit' | 'narrow' | 'short' | 'long';
    day?: 'numeric' | '2-digit';
    hour?: 'numeric' | '2-digit';
    minute?: 'numeric' | '2-digit';
    second?: 'numeric' | '2-digit';
    weekday?: 'narrow' | 'short' | 'long';
    era?: 'narrow' | 'short' | 'long';
    timeZoneName?: 'short' | 'long';
    formatMatcher?: 'basic' | 'best fit';
    hour12?: boolean;
    timeZone?: string;
  };

  const dateOptions: DateTimeFormatOptions = {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
  };

  const timeOptions: DateTimeFormatOptions = {
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
    hour12: false,
  };
  const userFriendlyDate = currentDate.toLocaleDateString('en-US', dateOptions);
  const userFriendlyTime = currentDate.toLocaleTimeString('en-US', timeOptions);
  const userFriendlyCurrentDate = `${userFriendlyDate} ${userFriendlyTime}`;

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = PAGE_SIZE;
  const startIndex = (currentPage - 1) * itemsPerPage;
  const selectedUsers = data.slice(startIndex, startIndex + itemsPerPage);
  const pagingMetadata = {
    currentPage,
    hasPrevious: currentPage > 1,
    hasNext: currentPage < Math.ceil(data.length / itemsPerPage),
    pageSize: itemsPerPage,
    totalCount: data.length,
    totalPages: Math.ceil(data.length / itemsPerPage),
  };

  const csvData = data.map((user) => [user.email]);

  const [isCsvDownloaded, setIsCsvDownloaded] = useState(false);

  const handleCsvClick = () => {
    setIsCsvDownloaded(true);
  };

  return (
    <ModalLayout closeModal={handleCloseModal} options={modalOptions}>
      <Title>
        <SelectedUsersTable color="blue" title={usersTitle} selectedUsers={selectedUsers} />
        {data?.length > 20 && (
          <Pagination headerData={pagingMetadata} currentPage={currentPage} setCurrentPage={setCurrentPage} />
        )}
      </Title>
      <Column>
        <ButtonGroup alignRight buttonGap="16px" marginTop="">
          <SlimButton variant="secondary" onClick={handleCloseModal}>
            CANCEL
          </SlimButton>
          <SlimButton onClick={handleCsvClick} disabled={isCsvDownloaded}>
            {isCsvDownloaded ? (
              'CSV Downloaded'
            ) : (
              <CSVLink data={csvData} filename={`onboarded_users_${userFriendlyCurrentDate}.csv`}>
                Download CSV file
              </CSVLink>
            )}
          </SlimButton>
        </ButtonGroup>
      </Column>
    </ModalLayout>
  );
};
