import { routes } from '../components/NewAnnouncementPage/utils';
import { matchPath, useLocation } from 'react-router-dom';

function getAnnouncementIdFromPathname(pathname: string) {
  const matchesForStep = pathname.match(/^\/announcementsPage\/new-announcement\/(.*)\/.*/);
  const currentStep = matchesForStep ? matchesForStep[1] : null;
  const match = currentStep ? matchPath(routes[currentStep as keyof typeof routes], pathname) : null;
  if (match) {
    return match.params.announcementId;
  }
  return null;
}

function useAnnouncementIdFromUrl() {
  /// <summary>
  /// Hook that wraps 'useLocation' from 'react-router-dom' and returns the announcementId URL param.
  /// Useful when the URL param isn't available from useParams directly, such as in a modal.
  /// </summary>
  const location = useLocation();

  const result = location && location.pathname && getAnnouncementIdFromPathname(location.pathname);

  if (!result) {
    throw new Error('announcementId cannot be null');
  }

  return result;
}

export { useAnnouncementIdFromUrl, getAnnouncementIdFromPathname };
