import styled from 'styled-components';

export const PageFlex = styled.div`
  display: flex;
`;

export const MainColumn = styled.div`
  width: 100%;
`;

export const Wrapper = styled.div`
  padding: 64px 10%;
`;
