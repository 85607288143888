import React from 'react';
import styled from 'styled-components';
import { Guid } from '../../../../types';
import { ImagePreview } from '../../../../services/imageServices/imageServices';
import { ImageView } from '../../../shared/Image/ImageView';

const GridOuter = styled.div<{ noSidebar?: boolean }>`
  min-height: 588px;
  padding-right: ${(props) => (props.noSidebar ? '0px' : '32px')};
  overflow-y: auto;
  overflow-x: hidden;
  box-sizing: border-box;
`;

const GridLayout = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(140px, 1fr));
  grid-column-gap: 24px;
  grid-row-gap: 24px;
`;

interface ImageGridProps {
  imageIds: Guid[];
  selectedIds?: Guid[];
  onSelectImage: (image: ImagePreview) => void;
  disableSelect: boolean;
  noSidebar?: boolean;
}
export const ImageGrid = ({ imageIds, selectedIds = [], onSelectImage, disableSelect, noSidebar }: ImageGridProps) => {
  const isSelected = (id: Guid) => !!selectedIds.find((selectedId) => selectedId === id);

  return (
    <GridOuter noSidebar={noSidebar}>
      <GridLayout>
        {imageIds.map((id) => (
          <ImageView
            key={id}
            imageId={id}
            height="145px"
            isSelected={isSelected(id)}
            onClick={(image: ImagePreview) => (!disableSelect ? onSelectImage(image) : null)}
            disableClick={disableSelect}
            crop="small"
          />
        ))}
      </GridLayout>
    </GridOuter>
  );
};
