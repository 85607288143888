import React from 'react';
import styled from 'styled-components';
import * as Yup from 'yup';
import { Guid } from '../../../../../types';
import { Form } from 'formik';
import { FormikWithPrompt } from '@instech/components';
import { updateImageMetadataAsync, ImagePreview } from '../../../../../services/imageServices/imageServices';
import { SaveCloseButtons } from '../../../../shared/FormButton/FormButtons';
import { TextArea, TextField } from '../../../../shared/Form/FormFields';
import { trimString } from '../../../../../utils/text';
import {
  openConfirmationModal,
  PushChangesConfirmationModalData,
} from '../../../../NewAnnouncementPage/core/ConfirmationModal';
import { useModalContext } from '../../../../modal/ModalContext';
import { EditedImageMeta } from './EditedImageMeta';

const validationSchema = Yup.object().shape({
  title: Yup.string().trim().max(60, 'Cannot be longer than 60 characters').required('Required'),
  description: Yup.string().max(256, 'Cannot be longer than 256 characters'),
});

const FieldArea = styled.div`
  margin-top: 20px;
  margin-bottom: 20px;
`;

interface EditImageFormProps {
  image: ImagePreview;
  onSave: (result: ImagePreview) => void;
  onClose: () => void;
  onDelete?: (imageId: Guid) => void;
  isPublished?: boolean;
  disableSave?: boolean;
}
export const EditImageForm = ({ image, onSave, onClose, onDelete, isPublished, disableSave }: EditImageFormProps) => {
  const { open, close } = useModalContext();

  const saveHandler = async (values: ImagePreview) => {
    const submitValues = {
      ...values,
      title: trimString(values.title),
    };

    const { announcementId, id: imgId } = image;
    const result = await updateImageMetadataAsync(announcementId, imgId, submitValues);
    if (result) {
      onSave(result);
    }
  };

  const publishHandler = async (values: ImagePreview) => {
    open(
      openConfirmationModal(
        { title: 'Push Changes' },
        {
          confirmCallback: () => {
            saveHandler(values).catch((error) => {
              console.error(error);
            });
          },
          returnToCallBack: () => close(),
          ...PushChangesConfirmationModalData,
        }
      )
    );
  };

  return (
    <>
      <EditedImageMeta image={image} onDelete={onDelete} />
      <FormikWithPrompt
        initialValues={image}
        enableReinitialize
        validationSchema={validationSchema}
        onSubmit={isPublished ? publishHandler : saveHandler}
      >
        {({ dirty, isSubmitting, handleSubmit }) => (
          <Form>
            <FieldArea>
              <TextField name="title" label="Image title" placeholder="Enter a title" useSpellcheck={false} />
              <TextArea name="description" label="Description" placeholder="Enter a description" />
            </FieldArea>
            <SaveCloseButtons
              closeLabel="Cancel"
              onSave={handleSubmit}
              onClose={onClose}
              isSubmitting={isSubmitting}
              disableSave={!dirty || disableSave}
              saveLabel={isPublished ? 'Push changes' : 'Save'}
              alignRight
              reverse
            />
          </Form>
        )}
      </FormikWithPrompt>
    </>
  );
};
