import React from 'react';
import styled from 'styled-components';
import { theme } from '../../../../utils/theme';
import { ContextMenuCallbacks, Announcement, AnnouncementStatus } from '@/types';
import { ContextMenu } from '@instech/components';
import { useContextMenuOptions } from '../utils';

const getCardColor = (status?: AnnouncementStatus) => {
  switch (status) {
    case 'draft':
      return theme.status.yellow;
    case 'scheduled':
      return theme.status.red;
    default:
      return theme.marineBlue;
  }
};

const Detail = styled.div`
  display: flex;
`;

const DetailLabel = styled.div`
  font-size: 12px;
`;

const DetailValue = styled.div`
  font-size: 12px;
  font-weight: bold;
  margin-left: 4px;
`;

interface DetailProps {
  label: string;
  value?: string | number;
}
const MetaDetail = ({ label, value }: DetailProps) => (
  <Detail>
    <DetailLabel>{label}</DetailLabel>
    {value && <DetailValue>{value}</DetailValue>}
  </Detail>
);

const Container = styled.div<{ status: string }>`
  display: flex;
  color: ${(props) => props.theme.marineBlue};
  border-top: 2px solid ${(props) => props.status};
`;

const MetaWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 12px 12px 12px 20px;
  & > * ~ * {
    // Arbitrary space between children
    margin-left: 16px;
  }
`;

const ContextMenuWrapper = styled.div`
  padding-right: 15px;
`;

interface Props {
  announcement: Announcement;
  lastEdit: string;
  product: string;
  recipients: number;
  title: string;
  status: AnnouncementStatus;
  callbacks: ContextMenuCallbacks;
}
export const CardMeta = ({ announcement, lastEdit, product, recipients, title, status, callbacks }: Props) => {
  const menuId = `${announcement.id}-contextMenu`;
  const options = useContextMenuOptions(announcement, status, callbacks);
  return (
    <Container status={getCardColor(status)}>
      <MetaWrapper>
        <MetaDetail label="Last edit" value={lastEdit} />
        <MetaDetail label={product} />
        <MetaDetail label="Recipients" value={recipients} />
      </MetaWrapper>
      <ContextMenuWrapper>
        <ContextMenu id={menuId} options={options} screenReaderTitle={title} />
      </ContextMenuWrapper>
    </Container>
  );
};
