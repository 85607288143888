import React from 'react';
import styled from 'styled-components';
import { useParams, Link } from 'react-router-dom';
import { useCompanyById } from '../../services/companiesService';
import { ChevronLeft } from '@instech/icons';
import { Loader } from '../shared/Loader';
import { UsersPage } from '../UsersPage/UsersPage';
import { CompanyInfo } from '../CompanyInfo/CompanyInfo';

const PageContent = styled.div`
  margin: 0 10%;
  padding: 64px 12px;
`;
const PageTitle = styled.div`
  font-size: 18px;
  font-weight: bold;
  color: ${(props) => props.theme.marineBlue50};
  display: flex;
  align-content: center;
`;
const CompanyTitle = styled.div`
  overflow-wrap: break-word;
  font-size: 28px;
  font-weight: bold;
  color: ${(props) => props.theme.marineBlue};
  margin-top: 6px;
  margin-bottom: 30px;
`;
const Columns = styled.div`
  display: flex;
`;
const InfoColumn = styled.div`
  width: 100%;
`;
const StyledLink = styled(Link)`
  text-decoration: none;
  :visited {
    color: ${(props) => props.theme.marineBlue};
  }
`;
export const CompanyPage = () => {
  const { companyId } = useParams();
  const { data: company, mutate } = useCompanyById(companyId || '');
  if (!company) return <Loader />;
  return (
    <PageContent>
      <StyledLink to="/">
        <PageTitle>
          <ChevronLeft /> to Companies List
        </PageTitle>
      </StyledLink>
      <CompanyTitle>{company.name}</CompanyTitle>
      <Columns>
        <InfoColumn>
          <CompanyInfo company={company} mutate={mutate} />
          <UsersPage companyId={companyId} companyName={company.name} />
        </InfoColumn>
      </Columns>
    </PageContent>
  );
};
