import { Company, Guid } from '../types';
import useSWR from 'swr';
import client, { postJsonAsync } from './client';

export const searchCompanies = async (params: any) => {
  const usersData = await postJsonAsync('search/companies', params);
  return usersData;
};

export const useCompanyById = (companyId: string | undefined) => {
  const requestUrl = `taxonomy/companies/${companyId}`;
  return useSWR<Company>(requestUrl);
};

export const enrollCompanyAsync = async (companyId: Guid): Promise<Company> => {
  const requestUrl = `/externalcompany/${companyId}`;
  const result = await client.post<Company>(requestUrl);
  return result.data;
};

export const getAllEnrolledCompaniesAsync = async (): Promise<Company> => {
  const requestUrl = '/taxonomy/companies';
  const result = await client.get(requestUrl);
  return result.data;
};
export const createCompanyAsync = async (companyModel: Company): Promise<Company> => {
  const requestUrl = '/taxonomy/companies';
  const result = await client.post(requestUrl, companyModel);
  return result.data;
};

export const updateCompanyAsync = async (companyModel: Company): Promise<Company> => {
  const requestUrl = `/taxonomy/companies/${companyModel.id}`;
  const result = await client.put(requestUrl, companyModel);
  return result.data;
};

export const useCompanyTypes = () => {
  const requestUrl = '/types';
  return useSWR<string[]>(requestUrl);
};
