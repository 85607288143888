import React from 'react';
import styled from 'styled-components';
import { SlimButton } from '@instech/components';
import { ModalLayout } from './ModalLayout';
import { SelectedUser, AuthenticationMethodsProps } from '../UsersPage/userTypes';
import { SelectedUsersTable } from '../shared/SelectedUsersTable';
import { SignInMethods } from './SignInMethods';

const CloseButtonWrapper = styled.div`
  display: flex;
  justify-content: right;
`;

interface Props {
  selectedUsers: SelectedUser[];
  setRefreshUsersCount?: React.Dispatch<React.SetStateAction<number>>;
  handleCloseModal: () => void;
  allAuthenticationMethods: AuthenticationMethodsProps[];
  selectedUsersTitle?: string;
}

export const ManageSignInMethodsModal = ({
  selectedUsers,
  selectedUsersTitle,
  handleCloseModal,
  allAuthenticationMethods,
  setRefreshUsersCount,
}: Props) => {
  const modalOptions = {
    size: 'medium',
    title: 'Manage Sign In Methods',
  };

  return (
    <ModalLayout closeModal={handleCloseModal} options={modalOptions}>
      <div>
        <SelectedUsersTable title={selectedUsersTitle} selectedUsers={selectedUsers} color="green" />
        <SignInMethods
          selectedUsers={selectedUsers}
          allAuthenticationMethods={allAuthenticationMethods}
          setRefreshUsersCount={setRefreshUsersCount}
          handleCloseModal={handleCloseModal}
        />
      </div>
      <CloseButtonWrapper>
        <SlimButton variant="primary" padding="8px 16px" onClick={handleCloseModal}>
          Close
        </SlimButton>
      </CloseButtonWrapper>
    </ModalLayout>
  );
};
