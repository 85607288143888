import React from 'react';
import PropTypes from 'prop-types';
import { ButtonGroup } from '@instech/components';
import { AddButton, SaveButton, CloseButton } from './FormButton';

export const AddSaveButtons = ({
  addLabel,
  saveLabel,
  disableSave,
  isSubmitting,
  onAdd,
  onSave,
  inverted = false,
  alignRight,
  spaceBetween,
  reverse,
}) => (
  <ButtonGroup alignRight={alignRight} spaceBetween={spaceBetween} reverse={reverse}>
    <AddButton label={addLabel} onClick={onAdd} inverted={inverted} />
    <SaveButton label={saveLabel} disabled={disableSave} isSubmitting={isSubmitting} onClick={onSave} />
  </ButtonGroup>
);
AddSaveButtons.propTypes = {
  addLabel: PropTypes.string,
  saveLabel: PropTypes.string,
  disableSave: PropTypes.bool,
  isSubmitting: PropTypes.bool,
  onAdd: PropTypes.func,
  onSave: PropTypes.func,
  inverted: PropTypes.bool,
  alignRight: PropTypes.bool,
  spaceBetween: PropTypes.bool,
  reverse: PropTypes.bool,
};

export const SaveCloseButtons = ({
  saveLabel,
  closeLabel,
  disableSave,
  disableClose,
  isSubmitting,
  onSave,
  onClose,
  alignRight,
  reverse,
  hideCloseButton,
}) => (
  <ButtonGroup alignRight={alignRight} reverse={reverse}>
    <SaveButton label={saveLabel} disabled={disableSave} isSubmitting={isSubmitting} onClick={onSave} />
    {!hideCloseButton && <CloseButton label={closeLabel} disabled={disableClose} onClick={onClose} />}
  </ButtonGroup>
);
SaveCloseButtons.propTypes = {
  saveLabel: PropTypes.string,
  closeLabel: PropTypes.string,
  disableSave: PropTypes.bool,
  disableClose: PropTypes.bool,
  isSubmitting: PropTypes.bool,
  onSave: PropTypes.func,
  onClose: PropTypes.func,
  alignRight: PropTypes.bool,
  reverse: PropTypes.bool,
  hideCloseButton: PropTypes.bool,
};
