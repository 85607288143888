import { UserDataProps, UserInfo } from '../types';
import useSWR from 'swr';
import { User } from '../types';
import { getJsonAsyncWithHeaders, postJsonAsync, putJsonAsync, deleteJsonAsync } from './client';
import { SelectedUser } from '../components/UsersPage/userTypes';

export const searchUsers = async (params: any) => {
  const usersData = await postJsonAsync('search/users', params);
  return usersData;
};

export const getUser = async (): Promise<UserInfo | null> => {
  try {
    const response = await fetch('management/user?slide=false', {
      headers: { 'x-csrf': '1' },
    });
    if (response.status === 401 || response.status === 403) {
      // redirect to login page
      window.location.href = 'management/login';
    } else {
      const responseData: any = await response.json();
      if (Array.isArray(responseData)) {
        const userInfo: UserInfo = responseData.reduce((acc, { type, value }) => ({ ...acc, [type]: value }), {
          sub: '',
        });
        if (userInfo) return userInfo;
      }
    }
  } catch (error) {
    console.error('Error fetching user:', error);
  }
  return null;
};

export const getUserRoles = async (): Promise<any> => {
  try {
    const response = await fetch('management-extras/user', {
      headers: { 'x-csrf': '1' },
    });
    const userRoles: any = await response.json();
    if (userRoles) return userRoles;
  } catch (error) {
    console.error('Error fetching user roles:', error);
  }
  return null;
};

export const useCompanyUsers = (companyId: string) => {
  const requestUrl = `/companies/${companyId}/users`;
  return useSWR<User[]>(requestUrl);
};

export const useUsers = (params?: any) => {
  const { companyId, freeText } = params;

  let url: string;

  if (companyId) {
    url = `/companies/${companyId}/users`;
  } else if (freeText) {
    url = '/users/search';
  } else {
    url = '/users';
  }

  const { data: response, mutate } = useSWR(
    ['users', params], // If the params object changes, it should trigger a re-fetch of the data
    () => getJsonAsyncWithHeaders(url, params),
    {
      shouldRetryOnError: false,
      revalidateOnFocus: false,
      refreshWhenOffline: false,
    }
  );
  const users = response?.data;

  let headerData = null;
  try {
    const headerValue = response?.headers['x-pagination'];
    if (headerValue) {
      headerData = JSON.parse(headerValue);
    }
  } catch (error) {
    console.error('Error parsing header data:', error);
  }

  return { users, headerData, mutate };
};

export const postNewUser = async (payload: UserDataProps) => await postJsonAsync('/users', payload);

export const updateUser = async (userId: string, payload: UserDataProps) =>
  await putJsonAsync(`/users/${userId}`, payload);

export const addUserToCompany = async (subjectId: string, companyId: string) => {
  return await postJsonAsync(`/authorization/${subjectId}/companies/${companyId}`, {});
};

export const addUserToCompanyForSelectedUsers = async (selectedUsers: SelectedUser[], companyId: string) => {
  const addUserPromises = selectedUsers.map(async (user) => {
    const { subjectId } = user;
    await addUserToCompany(subjectId, companyId);
  });
  await Promise.all(addUserPromises);
};

export const deleteUserFromCompany = (endpoint: string) => deleteJsonAsync(endpoint, {});
