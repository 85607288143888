export enum SourceSystem {
  Onboarding = 1,
  Crm = 2,
}

export type Guid = string;

export interface Application {
  id: number;
  name: string;
}

export interface ApplicationRole {
  name: string;
  displayName: string;
}

export interface ApplicationRoles {
  id: number;
  name: string;
  applicationRoles: UserInRole[];
  canEdit: boolean;
}

export interface Company {
  id?: Guid;
  externalId?: Guid | null;
  name: string;
  type: string;
  companyNumber: string;
  city: string | null;
  country: string;
  email: string | null;
  sourceSystem?: number;
  users?: User[];
  isLocalStorageItem?: boolean;
  [key: string]: any;
}

// eslint-disable-next-line no-shadow
export enum EnabledValues {
  Active = 'Active',
  Passive = 'Passive',
}

export interface ExternalUser {
  [index: string]: number | string | boolean | Guid | null | Company[];
  id: number;
  enabled: boolean;
  displayName: string;
  title: string | null;
  email: string;
  phoneNumber: string | null;
  company: string | null;
  companyId: Guid | null;
  companyNumber: number;
  companyNumericId: number;
  additionalCompanies: Company[];
}

export interface ExternalCompany {
  id: string;
  name: string;
  companyNumber: number;
  type: string;
  addressLine1: string;
  addressLine2: string;
  addressLine3: string;
  email: string;
  website: string;
  registeredAt?: string;
  lastChangedAt?: string;
  country?: string;
  city?: string;
}

export interface ExternalCompanyType {
  typeCode: string;
  description: string;
}

export interface ExternalCompanyContact {
  id: Guid;
  contactNumber: number;
  companyNumber: number;
  name: string;
  title: string | null;
  phoneMobile: string;
  phoneDirect?: string;
  email: string;
  active: boolean;
}

export interface LabelValuePair {
  label: string;
  value: string;
}

export interface Portal {
  id: number;
  name: string;
  description: string;
  canEdit: boolean;
  selected: boolean;
}

export type AnnouncementStatus = 'draft' | 'scheduled' | 'published';

export interface User {
  id: number;
  enabled: boolean;
  name: string;
  title: string;
  email: string;
  phoneNumber: string | null;
  company: string;
  companyId: string;
  subjectId: string;
  externalId?: string;
  [key: string]: any;
}

export interface UserInRole {
  id: number;
  name: string;
  selected: boolean;
}

export interface UserRole {
  displayName: string;
  name: string;
  isAdministrative: boolean;
}

export interface Article {
  text: string;
  images: string[];
}

export interface Provider {
  count: number;
  providerId: number;
  selected: boolean;
  displayName: string;
  editableByCurrentUser?: boolean;
}

export interface UpdateUserInRole {
  providerId: number;
  subjectId: string;
  selected: boolean;
  displayName: string;
  company: string;
}

export interface UpdateApplicationRole {
  count: number;
  providerId: number;
  selected: boolean;
  displayName: string;
  name: string;
  users: UpdateUserInRole[];
  editableByCurrentUser?: boolean;
}

export interface UpdateUser {
  displayName: string;
  title: string | null;
  email: string;
  phoneNumber: string | null;
}

export interface UpdateApplication {
  count: number;
  providerId: number;
  selected: boolean;
  displayName: string;
  name: string;
  roles: UpdateApplicationRole[];
  editableByCurrentUser?: boolean;
}

export interface RecipientStructure {
  count: number;
  selectedCount: number;
  providers: Provider[];
  applications: UpdateApplication[];
}

export interface UserInfo {
  sub: string;
  [key: string]: string | undefined;
}

export interface UserInfoItem {
  type: string;
  value: string;
}

export type UserInfoArray = UserInfoItem[];

export interface Operations {
  edit: boolean;
  delete: boolean;
  publish: boolean;
  duplicate: boolean;
  withdraw: boolean;
}

export interface Announcement {
  id: string;
  published: boolean;
  isDuplicate: boolean;
  notVisibleBefore?: string | null;
  lastUpdated: string;
  publishedDate: string;
  publishedBy: any;
  categoryOption: Option;
  cardImageId?: string;
  keywordOption: Option;
  title: string;
  shortText: string;
  articles: Article[];
  recipientStructure: RecipientStructure;
  readCount: number;
  percentageRead: number;
  operations: Operations;
}

export interface Dictionary<T = any> {
  [key: string]: T;
}

export interface Category {
  name: string;
  displayText: string;
  options: Option[];
  isGeneral: boolean;
}

export interface Keyword {
  name: string;
  displayText: string;
  options: Option[];
  isGeneral: boolean;
}

export interface Option {
  label: string;
  value: string;
}

export interface ContextMenuCallbacks {
  onDraftDelete?: () => void;
  onWithdrawn?: () => void;
  onDublicate?: () => void;
}

export interface SortOrderProps {
  [key: string]: 'asc' | 'desc';
}

export interface FilterParams {
  [key: string]: string[] | string | boolean;
}

export interface FacetProps {
  id: string;
  label: string;
  value?: boolean;
}

export interface RolesFacetsProps {
  [key: string]: { id: string; label: string }[];
}

export interface HeaderItemProps {
  facets: FacetProps[] | RolesFacetsProps | null;
  title: string;
  property: string;
  filterable: boolean;
  type?: 'Boolean';
  position?: 'right';
  options?: { label: string; value: boolean }[];
  sortProperty?: string;
}

export interface PagingMetadataProps {
  currentPage: number;
  hasNext: boolean;
  hasPrevious: boolean;
  pageSize: number;
  totalCount: number;
  totalPages: number;
}

export interface ColumnMetadataProps {
  propertyName: string;
  facets: { value: string; count: number }[];
}

export interface UserDataProps {
  email: string;
  name: string;
  title: string;
  phoneNumber: string;
}

export interface SelectedFilterFacetProps {
  property: string;
  label: string;
  id: string;
  value?: boolean;
}

export interface UserSearchOptionProps {
  value: string;
  label: string;
  name: string;
  email: string;
  phoneNumber: string;
  title: string;
  companies: Company[];
}

