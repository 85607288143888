import { ColumnMetadataProps, HeaderItemProps, FacetProps, RolesFacetsProps } from '../../types';
import { AuthenticationMethodsProps, ApplicationProps } from './userTypes';

const getColumnFromMetadata = (propertyName: string, metadata: ColumnMetadataProps[]) =>
  metadata
    .filter((column) => column.propertyName === propertyName)
    .map((column) => column.facets)
    .flat()
    .map((item) => item.value);

const getFacetsFromMetadata = (propertyName: string, metadata: ColumnMetadataProps[]): FacetProps[] => {
  const column = getColumnFromMetadata(propertyName, metadata);
  const facets = column.filter((facet) => facet !== '').map((facet) => ({ id: facet, label: facet }));
  return facets;
};
const getFacetsFromRequest = (
  idProperty: string,
  allData: FacetProps[],
  metadata: ColumnMetadataProps[]
): FacetProps[] => {
  const ids = getColumnFromMetadata(idProperty, metadata);
  const facets =
    ids &&
    ids.map((id) => {
      const label = allData?.find((d) => d.id === id)?.label || '';
      return { id, label };
    });
  return facets;
};

const getCompaniesFacets = (propertyName: string, metadata: ColumnMetadataProps[]): FacetProps[] => {
  const compoundData = getColumnFromMetadata(propertyName, metadata);
  const facets = compoundData.map((data) => {
    const splitted = data.split('__');
    return { id: splitted[1], label: splitted[0] };
  });
  return facets;
};

export const getUsersHeaderItems = (
  metadata: ColumnMetadataProps[],
  allAuthenticationMethods: AuthenticationMethodsProps[],
  allApplicationsRoles: ApplicationProps[]
): HeaderItemProps[] => {
  var allMethodsData = allAuthenticationMethods?.map((method) => ({ id: method.id, label: method.displayName }));
  const getRolesFacets = (
    idProperty: string,
    allData: ApplicationProps[],
    metadata: ColumnMetadataProps[]
  ): RolesFacetsProps => {
    const ids = getColumnFromMetadata(idProperty, metadata);
    const facets: RolesFacetsProps = {};
    allData?.forEach((app) => {
      const matchingRoles = app.roles && app.roles.filter((role) => ids.includes(role.id.toUpperCase()));
      if (matchingRoles && matchingRoles.length > 0) {
        facets[app.displayName] = matchingRoles.map((role) => ({ id: role.id.toLowerCase(), label: role.displayName }));
      }
    });
    return facets;
  };

  const headerItems: HeaderItemProps[] = [
    {
      facets: getFacetsFromMetadata('Name', metadata),
      title: 'User Name',
      property: 'selectedNames',
      filterable: false,
      sortProperty: 'Name',
    },
    {
      facets: getFacetsFromMetadata('Title', metadata),
      title: 'Title',
      property: 'selectedTitles',
      filterable: true,
    },
    {
      facets: null,
      title: 'Email',
      property: 'selectedEmails',
      filterable: false,
    },
    {
      facets: null,
      title: 'Phone',
      property: 'selectedPhones',
      filterable: false,
    },
    {
      facets: getCompaniesFacets('Companies/IdNameCompound', metadata),
      title: 'Companies',
      property: 'selectedCompanyIds',
      filterable: true,
    },
    {
      facets: getFacetsFromRequest('Identities/Id', allMethodsData, metadata),
      title: 'Sign In Methods',
      property: 'selectedSignInMethods',
      filterable: true,
    },
    {
      facets: getRolesFacets('Roles/RoleId', allApplicationsRoles, metadata),
      title: 'Roles',
      property: 'selectedRoleIds',
      filterable: true,
    },
    {
      facets: [
        { label: 'Active', id: 'Active', value: true },
        { label: 'Passive', id: 'Passive', value: false },
      ],
      title: 'Status',
      property: 'isEnabled',
      filterable: true,
      sortProperty: 'Enabled',
      type: 'Boolean',
      position: 'right',
    },
  ];
  return headerItems;
};
