import React from 'react';
import styled from 'styled-components';
import { useImagePreviewById } from '../../../../services/imageServices/imageServices';
import { ImageView } from '@instech/components';

const CardImagePlaceholder = styled.div`
  height: 80px;
  width: 80px;
  background: ${(props) => props.theme.lightGray};
`;

const CardImagePreview = ({ imageId }: { imageId: string }) => {
  const { data: image, error } = useImagePreviewById(imageId, 'small');
  return <ImageView image={image} error={error} width="80px" height="80px" noBorder />;
};

export const CardImage = ({ imageId }: { imageId?: string }) => {
  if (!imageId) return <CardImagePlaceholder />;
  return <CardImagePreview imageId={imageId} />;
};
