import useSWR from 'swr';
import { useState } from 'react';
import { ApplicationRole, Category, Keyword, Announcement } from '@/types';
import client, { postJsonAsync, putJsonAsync } from './client';

const getPortalSuffix = (portal: string) => {
  let portalSuffix = '';
  if (portal != null && portal !== 'All Announcements') {
    portalSuffix = `?portal=${portal}`;
  }
  return portalSuffix;
};

export const useUpdateApplicationNames = () => {
  const url = 'taxonomy/applications';
  return useSWR<ApplicationRole[]>(url);
};

export const useAnnouncement = (announcementId: string) => {
  const [updateError, setUpdateError] = useState<string>('');
  const url = `announcements/${announcementId}`;
  const { data, mutate } = useSWR<Announcement>(url);

  if (updateError) {
    throw new Error(updateError);
  }

  const saveAnnouncement = async (newItem: Announcement) => {
    try {
      const result = await putJsonAsync<Announcement>(url, newItem);
      void mutate(result);
      return result;
    } catch (e) {
      if (typeof e === 'string') {
        setUpdateError(e);
      }
      if (e instanceof Error) {
        setUpdateError(e.message);
      }
    }
    return undefined;
  };

  return { data, saveAnnouncement };
};

export const createEmptyDraft = async () => {
  const result = await postJsonAsync<Announcement>('announcements', null);
  return result;
};

export const useDrafts = (portal: string) => {
  const portalSuffix = getPortalSuffix(portal);
  const requestUrl = `announcements/drafts${portalSuffix}`;
  return useSWR<Announcement[]>(requestUrl);
};

export const useScheduled = (portal: string) => {
  const portalSuffix = getPortalSuffix(portal);
  const requestUrl = `announcements/scheduled${portalSuffix}`;
  return useSWR<Announcement[]>(requestUrl);
};

export const usePublished = (portal: string) => {
  const portalSuffix = getPortalSuffix(portal);
  const requestUrl = `announcements${portalSuffix}`;
  return useSWR<Announcement[]>(requestUrl);
};

export const publishAnnouncement = async (announcementId: string) => {
  const url = `/announcements/publish/${announcementId}`;
  await client.put(url);
};

export const withdrawAnnouncement = async (announcementId: string) => {
  const url = `/announcements/withdraw/${announcementId}`;
  await client.put(url);
};

export const deleteDraft = async (announcementId: string) => {
  const url = `announcements/${announcementId}`;
  await client.delete(url);
};

export const duplicateAnnouncement = async (announcementId: string) => {
  const url = `/announcements/duplicate/${announcementId}`;
  const result = await putJsonAsync<Announcement>(url, null);
  return result;
};

export const scheduledPublish = async (
  announcement: Announcement,
  saveAnnouncement: (toSave: Announcement) => Promise<Announcement | undefined>
) => {
  if (!announcement.notVisibleBefore) {
    throw new Error('Cannot scheduele an announcement without an update time');
  }
  const scheduledUpdate = await saveAnnouncement(announcement);
  await publishAnnouncement(announcement.id);
  return scheduledUpdate;
};

export const useCategories = () => {
  const url = '/announcements/categories';
  return useSWR<Category[]>(url);
};

export const useKeywords = () => {
  const url = '/announcements/keywords';
  return useSWR<Keyword[]>(url);
};
