import { useHasScrolled } from '../../hooks/useHasScrolled';
import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import { Header } from './Header';

const PageWrapper = styled.div<{ scrolled: boolean }>`
  background-color: ${(props) => props.theme.whiteBlue};
  // Prevents flickering when scrolling the header
  margin-top: ${(props) => (props.scrolled ? props.theme.header.scrolledHeight : null)};
  min-height: 100vh;
`;

export const PageContent = styled.div`
  margin: 0 auto;
  padding: 64px 12px;
  height: 100%;
  max-width: 1200px;
`;

export const PageContentWide = styled.div`
  display: flex;
  margin: 0 auto;
  padding: 24px;
  height: 100%;
  max-width: 1440px;
`;

export const MainColumn = styled.div`
  width: 100%;
`;

export const SideColumn = styled.div`
  flex-shrink: 0;
  width: 152px;
  margin-left: 24px;
`;

export const Page: FunctionComponent = ({ children }) => {
  const scrolledHeight = 50;
  const hasScrolled = useHasScrolled(scrolledHeight);

  return (
    <PageWrapper scrolled={hasScrolled}>
      <Header scrolled={hasScrolled} />
      {children}
    </PageWrapper>
  );
};
