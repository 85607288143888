import { Announcement } from '@/types';
import moment from 'moment';
import { useEffect } from 'react';

type HookSignature = (
  refreshPublished: () => Promise<Announcement[] | undefined>,
  refreshScheduled: () => Promise<Announcement[] | undefined>,
  scheduled: Announcement[] | undefined
) => void;

export const useRefreshAfterScheduledIsPublished: HookSignature = (refreshPublished, refreshScheduled, scheduled) =>
  useEffect(() => {
    if (!scheduled || scheduled.length === 0) {
      return () => null;
    }
    const firstDate = moment.min(scheduled.map((update) => moment(update.notVisibleBefore)));
    const timeBeforePublished = firstDate.diff(moment(), 'milliseconds') + 1000;
    const updateScheduledTimeout = setTimeout(() => {
      Promise.all([refreshScheduled(), refreshPublished()]).catch((error) => {
        console.error(error);
      });
    }, timeBeforePublished);

    return () => clearTimeout(updateScheduledTimeout);
  }, [refreshPublished, refreshScheduled, scheduled]);
