import React from 'react';
import { useParams } from 'react-router';
import * as Yup from 'yup';
import { Formik, Form, FormikHelpers } from 'formik';
import { ButtonGroup } from '@instech/components';
import { useCategories, useKeywords, useAnnouncement } from '../../../services/announcementsServices';
import { objectsDeepEqual } from '../../../utils/object';
import { stringRequiredMaxLength, stringRequired } from '../../../utils/validationSchemas';
import { Loader } from '../../shared/Loader';
import { Category, Keyword, Option } from '../../../types';
import { useRedirect } from '../../../hooks/useRedirect';
import { NewAnnouncementProgress } from '../core/NewAnnouncementProgress';
import { MainColumn, PageFlex, Wrapper } from '../core/Components';
import { CancelButton, NextButton, PreviousButton, ProgressButtons } from '../core/ProgressButtons';
import { ContentsFormFields } from './core/ContentsFormFields';
import { useProgress } from '../core/ProgressContext';
import { Step } from '../NewAnnouncementPage';
import { FormValues, getInitialValues } from './core/utils';
import { getActualAnnouncementState, useAnnouncementState } from '../core/AnnouncementStateContext';
import { getReturnToCallBack } from '../utils';

const getOptions = (list: Category[] | Keyword[]) =>
  list.map((item) => ({ label: item.displayText, options: item.options }));

const validationSchema = (categories: Option[], keywords: Option[]) =>
  Yup.object().shape({
    /* categoryOption: Yup.mixed().test('Valid option selected', 'Required', (option) =>
      categories.some((category) => category.value === option?.value)
    ),
    keywordOption: Yup.mixed().test('Valid option selected', 'Required', (option) =>
      keywords.some((keyword) => keyword.value === option?.value)
    ), */
    title: stringRequiredMaxLength(50, 'Required'),
    shortText: stringRequiredMaxLength(120, 'Required'),
    cardImageId: Yup.string(),
    articles: Yup.array().of(
      Yup.object().shape({
        text: stringRequired('Required'),
        images: Yup.array().of(Yup.string()),
      })
    ),
  });

interface Props {
  steps: Step[];
  stepNum: number;
  handleCancel: (val?: any) => void;
}
export const AnnouncementContents = ({ steps, stepNum, handleCancel }: Props) => {
  const { announcementId } = useParams<{ announcementId: string }>();
  const { data: initialAnnouncement } = useAnnouncement(announcementId ?? '');
  const { next, previous } = useProgress();
  const { data: categories } = useCategories();
  const { data: keywords } = useKeywords();
  const { updateState, state: currentAnnouncement, setDirty } = useAnnouncementState();
  const setShouldRedirect = useRedirect('/announcementsPage');

  if (!initialAnnouncement) return <Loader />;

  const actualAnnouncement = getActualAnnouncementState(initialAnnouncement, currentAnnouncement);
  const initialValues = getInitialValues(actualAnnouncement);

  // Spread form data onto full announcement data model, and use func passed with
  // the form data to send the user to a different part of the wizard
  const onSubmit = async (formVals: FormValues, helpers: FormikHelpers<FormValues>) => {
    const { jumpFunc, ...values } = formVals;
    const newValues = { ...actualAnnouncement, ...values };
    updateState(newValues);

    // If there have been changes in this form, flag update as changed
    if (!objectsDeepEqual(values, initialValues)) setDirty(true);

    // Clean the form to avoid giving prompt when navigating to next page.
    helpers.resetForm({ values: getInitialValues(newValues) });
    if (jumpFunc) {
      jumpFunc();
    }
  };

  if (!categories || !keywords) return <Loader />;

  const categoryOptions = getOptions(categories);
  const keywordOptions = getOptions(keywords);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema(
        categoryOptions.flatMap((x) => x.options),
        keywordOptions.flatMap((x) => x.options)
      )}
      onSubmit={onSubmit}
    >
      {(formikProps) => {
        // Insert a function call into the form data just before submitting, that
        // tells the onSubmit handler where to direct the user on successful submit
        const handleSubmitAndJump = (jumpFunc: any) => () => {
          formikProps.setFieldValue('jumpFunc', jumpFunc);
          formikProps.handleSubmit();
        };
        return (
          <Wrapper>
            <Form>
              <PageFlex>
                <MainColumn>
                  <NewAnnouncementProgress steps={steps} currentStep={stepNum} />
                  <ContentsFormFields
                    formik={formikProps}
                    categoryOptions={categoryOptions}
                    keywordOptions={keywordOptions}
                  />
                  <ButtonGroup alignRight marginTop="24px">
                    <PreviousButton width="130px" onClick={handleSubmitAndJump(previous)} />
                    <NextButton width="130px" onClick={handleSubmitAndJump(next)} />
                  </ButtonGroup>
                </MainColumn>
                <ProgressButtons>
                  <NextButton width="100%" onClick={handleSubmitAndJump(next)} />
                  <PreviousButton width="100%" onClick={handleSubmitAndJump(previous)} />
                  <CancelButton
                    width="100%"
                    onClick={() => handleCancel(getReturnToCallBack(setShouldRedirect, formikProps.resetForm))}
                  />
                </ProgressButtons>
              </PageFlex>
            </Form>
          </Wrapper>
        );
      }}
    </Formik>
  );
};
