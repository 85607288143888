import React, { useState } from 'react';
import styled from 'styled-components';
import { ButtonGroup, SlimButton } from '@instech/components';
import { WarningFilled, Check } from '@instech/icons';
import { ModalLayout } from './ModalLayout';
import { SelectedUser } from '../UsersPage/userTypes';
import { SelectedUsersTable } from '../shared/SelectedUsersTable';
import { sortUsers } from '../../utils/sort';
import { toggleUserEnabled } from '../../services/toggleUserEnabled';
import { useLocalStorage, currentTime } from '../../hooks/useLocalStorage';
import { Loader } from '../shared/Loader';
import { SuccessMessageWrapper, ErrorMessageWrapper, StyledButtonGroup } from '../shared/Components';

const StyledWarningFilled = styled(WarningFilled)`
  width: 52px;
  height: 52px;
  color: ${(props) => (props.error ? props.theme.red : props.theme.marineBlue)};
`;
const Title = styled.div`
  text-align: center;
  color: ${(props) => props.theme.marineBlue};
  p {
    font-weight: bold;
  }
`;

interface Props {
  isActive: boolean;
  selectedUsers: SelectedUser[];
  setSelectedUsers: React.Dispatch<React.SetStateAction<SelectedUser[]>>;
  handleCloseModal: () => void;
  setRefreshUsersCount: React.Dispatch<React.SetStateAction<number>>;
}

export const ChangeUserStatusModal = ({
  isActive,
  selectedUsers,
  setSelectedUsers,
  handleCloseModal,
  setRefreshUsersCount,
}: Props) => {
  const modalOptions = {
    size: 'medium',
    title: isActive ? 'Activate User' : 'Deactivate User',
  };

  const statusButtonName = isActive ? 'activate' : 'deactivate';
  const statusStateName = statusButtonName + 'd';
  const sortedSelectedUsers = selectedUsers.slice().sort(sortUsers);
  const selectedUsersTitle =
    selectedUsers.length > 1
      ? `Multiple users will be ${statusStateName} (${selectedUsers.length})`
      : `This user will be ${statusStateName}`;

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isError, setIsError] = useState(false);

  const { getItem, setItem } = useLocalStorage('users');
  const localStorageUsers = getItem() ?? [];

  const changeUsersStatus = (isActive: boolean = false) => {
    setIsSubmitting(true);
    const updatedUsers = selectedUsers.filter((u) => u.enabled !== isActive);
    const changedStatusUsers = updatedUsers.map((u) => ({
      ...u,
      enabled: isActive,
      isLocalStorageItem: true,
      timestamp: currentTime,
    }));
    const otherUpdatedUsers = localStorageUsers.filter(
      (localStorageUser: SelectedUser) =>
        !changedStatusUsers.some((changedStatusUser) => changedStatusUser.subjectId === localStorageUser.subjectId)
    );

    const updatedSubjectIds = updatedUsers.map((u) => u.subjectId);
    const usersData = { enable: isActive, subjectIds: updatedSubjectIds };
    toggleUserEnabled(usersData)
      .then(() => {
        setItem([...changedStatusUsers, ...otherUpdatedUsers]);
        setRefreshUsersCount((prev) => prev + 1);
        setIsSubmitted(true);
        setIsSubmitting(false);
        setSelectedUsers([...changedStatusUsers]);
        setTimeout(() => {
          handleCloseModal();
        }, 2000);
      })
      .catch((error) => {
        console.error(error);
        setIsSubmitted(false);
        setIsSubmitting(false);
        setIsError(true);
      });
  };

  return (
    <ModalLayout closeModal={handleCloseModal} options={modalOptions}>
      <Title>
        <StyledWarningFilled />
        <>
          <p>{selectedUsersTitle}</p>
          <SelectedUsersTable color="blue" selectedUsers={sortedSelectedUsers} />
        </>
        <p>
          Are you sure you want to {statusButtonName}
          {selectedUsers.length > 1 ? ' users' : ' user'}?
        </p>
      </Title>
      <StyledButtonGroup alignRight>
        {isSubmitted || isError ? (
          <SlimButton variant="secondary" onClick={handleCloseModal}>
            CLOSE
          </SlimButton>
        ) : (
          <ButtonGroup alignRight buttonGap="16px" marginTop="32px">
            <SlimButton variant="secondary" onClick={handleCloseModal}>
              CANCEL
            </SlimButton>
            <SlimButton onClick={() => changeUsersStatus(isActive)}>
              {isSubmitting ? <Loader size="small" /> : statusButtonName.toUpperCase()}
            </SlimButton>
          </ButtonGroup>
        )}
      </StyledButtonGroup>
      {isSubmitted && (
        <SuccessMessageWrapper>
          <Check />
          Changes saved successfully.
        </SuccessMessageWrapper>
      )}
      {isError && <ErrorMessageWrapper>Something went wrong.</ErrorMessageWrapper>}
    </ModalLayout>
  );
};
