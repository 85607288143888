import React from 'react';
import styled from 'styled-components';
import { baseTheme } from '@instech/components';
import { NavLink } from 'react-router-dom';

const TabsWrapper = styled.div`
  display: flex;
  height: 100%;
  align-items: flex-end;
`;

const NavEntry = styled(NavLink)<{ highlight?: string }>`
  text-decoration: none;
  background: ${(props) => props.theme.flatWhite};
  color: ${(props) => props.theme.marineBlue50};
  font-weight: normal;
  border: none;
  border-top: 5px solid ${(props) => props.highlight};
  white-space: nowrap;
  padding: 8px 24px;
  text-transform: uppercase;
  border-radius: 4px 4px 0 0;
  width: 150px;
  height: 40px;
  cursor: pointer;
  box-sizing: content-box;
  position: relative;
  outline: none;
  margin-right: 6px;
  transition: height 0.1s linear;
  text-decoration: none;

  &.active {
    background: ${(props) => props.theme.white};
    color: ${(props) => props.theme.marineBlue};
    font-weight: 600;
    margin-top: 0;
    border-bottom: none;
    border-right: 2px solid ${(props) => props.theme.whiteBlue};
    height: 50px;
    color: ${(props) => props.theme.marineBlue};
  }
`;

interface OnboardingTabsProps {
  isCompaniesPage: boolean;
  totalCountCompanies?: number;
  totalCountUsers?: number;
}

export const OnboardingTabs = ({ isCompaniesPage, totalCountCompanies, totalCountUsers }: OnboardingTabsProps) => {
  return (
    <TabsWrapper>
      <NavEntry highlight={baseTheme.marineBlue} to="/companies" className={isCompaniesPage ? 'active' : ''}>
        COMPANIES {totalCountCompanies && totalCountCompanies > 0 && `(${totalCountCompanies})`}
      </NavEntry>
      <NavEntry highlight={baseTheme.green} to="/users">
        USERS {totalCountUsers && totalCountUsers > 0 && `(${totalCountUsers})`}
      </NavEntry>
    </TabsWrapper>
  );
};
