import React, { useState } from 'react';
import styled from 'styled-components';
import { TableHeader } from '../shared/Table';
import { CheckboxControlled } from '@instech/components';
import { Remove } from '@instech/icons';
import { FilterButton } from '../shared/FilterButton';
import { SearchFilterDropdown } from '../shared/SearchFilterDropdown';
import { HeaderItemProps, SelectedFilterFacetProps } from '../../types';
import { CharacterOrderIcon, CharacterReverseOrderIcon, EnlargeIcon } from '../shared/Components';

const FiltersRow = styled.div<{ isUsersList?: boolean }>`
  grid-column: span 7;
  grid-column: ${(props) => (props.isUsersList ? 'span 10' : 'span 7')};
  display: flex;
  flex-direction: row;
  padding: 0.5rem;
  justify-content: end;
`;
const ColumnTitle = styled.div`
  display: flex;
  align-items: center;
  :hover {
    cursor: pointer;
  }
`;

interface ItemsTableHeaderProps {
  filterParams: any;
  setFilterParams: any;
  headerItems: HeaderItemProps[];
  isUsersList?: boolean;
  isAllUsersSelected?: boolean;
  isSomeUsersSelected?: boolean;
  handleCheckAll?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export const ItemsTableHeader = ({
  filterParams,
  setFilterParams,
  headerItems,
  isAllUsersSelected,
  isSomeUsersSelected,
  handleCheckAll,
  isUsersList,
}: ItemsTableHeaderProps) => {
  const [visibleFilterName, setVisibleFilterName] = useState<string | null>(null);
  const [selectedFilterFacets, setSelectedFilterFacets] = useState<SelectedFilterFacetProps[]>([]);

  const showFilter = (name: string) => {
    setVisibleFilterName(name);
  };
  const closeFilter = () => {
    setVisibleFilterName(null);
  };
  const clearAllFilters = () => {
    setSelectedFilterFacets([]);
    setFilterParams({});
  };

  const clearFilter = (facet: SelectedFilterFacetProps) => {
    const newFilterParams = { ...filterParams };

    if (Array.isArray(filterParams[facet.property])) {
      newFilterParams[facet.property] = filterParams[facet.property].filter(
        (item: string) => item !== facet.label && item !== facet.id
      );
      if (newFilterParams[facet.property].length === 0) {
        delete newFilterParams[facet.property];
      }
      setFilterParams(newFilterParams);
    } else {
      delete newFilterParams[facet.property];
      setFilterParams(newFilterParams);
    }
    setSelectedFilterFacets(
      selectedFilterFacets.filter(
        (selectedFacet) => selectedFacet.id !== facet.id || selectedFacet.property !== facet.property
      )
    );
  };

  const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('desc');

  const changeSort = (fieldName: string) => {
    setSortOrder((prevSortOrder) => (prevSortOrder === 'asc' ? 'desc' : 'asc'));
    setFilterParams((prev: any) => ({
      ...prev,
      orderBy: `${fieldName} ${sortOrder}`,
    }));
  };

  const handleTitleClick = (item: HeaderItemProps) => {
    if (item.filterable) {
      showFilter(item.title);
    } else if (item.sortProperty && !item.filterable) {
      changeSort(item.sortProperty!);
    } else return;
  };

  return (
    <>
      {selectedFilterFacets.length > 0 && (
        <FiltersRow isUsersList={isUsersList}>
          {selectedFilterFacets.map((facet: SelectedFilterFacetProps) => (
            <FilterButton
              key={facet.id}
              label={facet.label}
              selected
              icon={<Remove />}
              toggle={() => clearFilter(facet)}
            >
              {facet.label}
            </FilterButton>
          ))}

          <FilterButton label="Clear filters" toggle={clearAllFilters} />
        </FiltersRow>
      )}
      {isUsersList && (
        <TableHeader>
          <CheckboxControlled
            name="selectAll"
            noErrors
            noTopLabel
            noPadding
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleCheckAll && handleCheckAll(e)}
            selected={isAllUsersSelected}
            indeterminate={isSomeUsersSelected}
          />
        </TableHeader>
      )}

      {headerItems.map((item) => (
        <TableHeader key={item.title}>
          <ColumnTitle onClick={() => handleTitleClick(item)}>
            {item.title}
            {item.filterable && <EnlargeIcon data-testid="sort-by-name" />}
          </ColumnTitle>
          {item.title === visibleFilterName && item.filterable && (
            <SearchFilterDropdown
              isVisible={item.title === visibleFilterName}
              item={item}
              close={closeFilter}
              filterParams={filterParams}
              setFilterParams={setFilterParams}
              selectedFilterFacets={selectedFilterFacets}
              setSelectedFilterFacets={setSelectedFilterFacets}
              setSortOrder={setSortOrder}
            />
          )}
          {!item.filterable && !!item.sortProperty && sortOrder === 'asc' && (
            <CharacterOrderIcon data-testid="sort-by-name" onClick={() => changeSort(item.sortProperty!)} />
          )}
          {!item.filterable && !!item.sortProperty && sortOrder === 'desc' && (
            <CharacterReverseOrderIcon data-testid="sort-by-name" onClick={() => changeSort(item.sortProperty!)} />
          )}
        </TableHeader>
      ))}
      <TableHeader></TableHeader>
    </>
  );
};
