import React from 'react';
import styled from 'styled-components';
import { ImageView, UpdatePreview } from '@instech/components';
import { Announcement } from '../../../types';
import { useImagePreviewById } from '../../../services/imageServices/imageServices';
import { getFriendlyUTCTimestamp } from '../../../utils/date/friendlyDates';
import { TitleAndEdit } from './core/Components';

const Container = styled.div`
  max-width: 500px;
  padding: 20px;
  box-sizing: border-box;
  background-color: ${(props) => props.theme.white};
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.06);
  word-break: break-word;
`;

const ImageLoader = ({ id }: { id: string }) => {
  const { data: image, error } = useImagePreviewById(id, 'small');
  return <ImageView image={image} error={error} noBorder />;
};

interface Props {
  announcement: Announcement;
  onEdit: () => void;
}
export const CardPreview = ({ announcement, onEdit }: Props) => {
  // pass only valid string input to component
  const tags = [announcement.categoryOption, announcement.keywordOption];
  const timestamp = announcement.lastUpdated && getFriendlyUTCTimestamp(announcement.lastUpdated);
  return (
    <div>
      <TitleAndEdit title="Card Preview" onEdit={onEdit} />
      <Container>
        <UpdatePreview
          title={announcement.title}
          imageId={announcement.cardImageId}
          ImageLoader={ImageLoader}
          shortText={announcement.shortText}
          tags={tags}
          topRight={timestamp}
        />
      </Container>
    </div>
  );
};
