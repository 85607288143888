import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { searchUsers } from '../../services/userService';
import { User, SourceSystem, FilterParams } from '../../types';
import { Table } from '../shared/Table';
import { UserSearch } from '../search/UserSearch';
import { ChangeUserStatusModal } from '../modal/ChangeUserStatusModal';
import { ItemsTableHeader } from '../shared/ItemsTableHeader';
import { UserRow } from './UserRow';
import { PageContent } from '../../Page';
import { OnboardingTabs } from '../OnboardingTab/OnboardingTabs';
import { useAppContext } from '../../AppContext';
import { PAGE_SIZE } from '../shared/constants';
import { Pagination } from '../shared/pagination/Pagination';
import { ManageUserButtonGroup } from '../shared/ManageUserButtonGroup';
import { ManageSignInMethodsModal } from '../modal/ManageSignInMethodsModal';
import { AddEditUserModal } from '../modal/AddEditUserModal';
import { useAuthenticationMethods } from '../../services/authenticationMethods';
import { AuthenticationMethodsProps, SelectedUser, ApplicationProps } from '../UsersPage/userTypes';
import { ManageAccessModal } from '../modal/ManageAccessModal';
import { AddUserToCompanyModal } from '../modal/AddUserToCompanyModal';
import { DefaultErrorMessage, ErrorBoundary, Loader } from '@instech/components';
import useDebounce from '../../hooks/useDebounce';
import { useLocalStorage, currentTime } from '../../hooks/useLocalStorage';
import { useSearchWithLocalStorage } from '../../hooks/useSearchWithLocalStorage';
import { NoDataWrapper } from '../shared/Components';
import { RemoveUsersFromCompanyModal } from '../modal/RemoveUsersFromCompanyModal';
import { getUsersHeaderItems } from './usersHeaderItems';
import { useApplicationsRoles } from '../../services/manageAccessService';
import { DownloadCsvModal } from '../modal/DownloadCsvModal';

const PageSearchWrapper = styled.div`
  margin: 32px auto;
  max-width: 600px;
`;

const Title = styled.div`
  color: ${(props) => props.theme.marineBlue};
  font-size: 18px;
  font-weight: bold;
`;
const ActionsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  margin-top: 32px;
`;
const PaginationWrapper = styled.div`
  padding: 32px 0;
`;

interface UsersProps {
  companyId?: string;
  companyName?: string;
  isCompaniesPage?: boolean;
}

export const UsersPage = ({ companyId, companyName, isCompaniesPage = false }: UsersProps) => {
  const [searchText, setSearchText] = useState<string | null>(null);
  const debouncedSearchText = useDebounce(searchText, 500);
  const { usersCurrentPage, setUsersCurrentPageNumber } = useAppContext();
  const [isModalActivateUserVisible, setModalActivateUserVisible] = useState(false);
  const [isModalDownloadCsvVisible, setModalDownloadCsvVisible] = useState(false);
  const [isModalDeactivateUserVisible, setModalDeactivateUserVisible] = useState(false);
  const [isModalManageSignInMethodsVisible, setIsModalManageSignInMethodsVisible] = useState(false);
  const [isModalManageAccessVisible, setIsModalManageAccessVisible] = useState(false);
  const [isAddNewUserVisible, setIsAddNewUserVisible] = useState(false);
  const [isModalAddUserToCompanyVisible, setIsModalAddUserToCompanyVisible] = useState(false);
  const [isModalRemoveUsersFromCompanyVisible, setIsModalRemoveUsersFromCompanyVisible] = useState(false);

  const allAuthenticationMethods = useAuthenticationMethods() as AuthenticationMethodsProps[];
  const allAuthenticationMethodsMap = new Map(
    allAuthenticationMethods?.map((method) => {
      return [method.id, method.displayName];
    })
  );

  const allApplicationsRoles = useApplicationsRoles() as ApplicationProps[];

  const { getItem, setItem, removeItem } = useLocalStorage('users');
  const localStorageUsers = getItem() ?? [];
  const [refreshUsersCount, setRefreshUsersCount] = useState(0);
  const [filterParams, setFilterParams] = useState<FilterParams>({});

  const searchParams = {
    selectedCompanyIds: !!companyId ? [companyId] : null,
    freeText: debouncedSearchText,
    includeCrmData: !!debouncedSearchText && !companyId,
    ...filterParams,
  };

  const {
    data: users,
    pagingMetadata,
    columnMetadata,
  } = useSearchWithLocalStorage<User>(
    searchUsers,
    {
      pageNumber: usersCurrentPage,
      pageSize: PAGE_SIZE,
      ...searchParams,
    },
    [companyId, debouncedSearchText, usersCurrentPage, refreshUsersCount, filterParams],
    'users',
    'subjectId'
  );

  const { getItem: removedUsersIds, removeItem: clearRemovedUsersIds } = useLocalStorage('removedUsers');
  const localStorageRemovedUsers = removedUsersIds() ?? [];

  const filteredUsers = !!companyId ? users.filter((u) => !localStorageRemovedUsers.includes(u.subjectId)) : users;

  const totalCountUsers = pagingMetadata.totalCount;
  const usersHeaderItems = getUsersHeaderItems(columnMetadata, allAuthenticationMethods, allApplicationsRoles);

  const isNoUsers = (users?.length === 0 && (searchText || !!companyId)) || filteredUsers.length === 0;
  const isLoading = !users && !isNoUsers;

  useEffect(() => {
    // take items from local storage and compare their timestamps with the current time
    // if the difference is greater than 20 minutes, remove the item from local storage
    const updatedUsers = localStorageUsers.filter(
      (user: SelectedUser) => currentTime - user.timestamp < 20 * 60 * 1000
    );
    setItem(updatedUsers);
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      // Refresh users data
      setRefreshUsersCount((prev) => prev + 1);
      // Clear updatedUsers array and local storage
      removeItem();
      clearRemovedUsersIds();
    }, 20 * 60 * 1000); // 20 minutes
    // Clean up interval on component unmount
    return () => clearInterval(interval);
  }, []);

  const isAllUsersPage = !companyId;

  const [selectedUsers, setSelectedUsers] = useState<SelectedUser[]>([]);
  const isSelected = (subjectId: string) => selectedUsers.some((u) => u.subjectId === subjectId);
  const selectedUsersTitle =
    selectedUsers?.length === 1 ? `1 selected user` : `${selectedUsers?.length} selected users`;
  useEffect(() => {
    setSelectedUsers([]);
    setUsersCurrentPageNumber(1);
  }, [companyId]);

  const handleClickCheckbox = (user: User): void => {
    const isInSelected = selectedUsers.some((selectedUser) => selectedUser.subjectId === user.subjectId);
    if (isInSelected) {
      setSelectedUsers((prev: SelectedUser[]) => prev.filter((u) => u.subjectId !== user.subjectId));
    } else {
      setSelectedUsers((prev: SelectedUser[]) => {
        return [user, ...prev];
      });
    }
  };

  const sourceSystemUsers = users?.filter((u: User) => u.sourceSystem === SourceSystem.Onboarding);
  const selectedUserIds = new Set(selectedUsers.map((u) => u.subjectId));
  const isAllUsersSelected = sourceSystemUsers.every((s: User) => selectedUserIds.has(s.subjectId));

  const isSomeUsersSelected = selectedUserIds.size > 0;

  const handleCheckAll = (event: React.ChangeEvent<HTMLInputElement>): void => {
    if (event.target.checked) {
      setSelectedUsers(sourceSystemUsers);
    } else {
      setSelectedUsers([]);
    }
  };

  const handleCloseModal = (setter: (value: boolean) => void) => {
    setter(false);
  };

  const handleUserSearch = (text: string | null) => {
    setSearchText(text);
    setUsersCurrentPageNumber(1);
  };

  const handleResetSearch = () => {
    setSearchText(null);
  };

  return (
    <PageContent>
      <ErrorBoundary component={DefaultErrorMessage}>
        {isAllUsersPage && (
          <PageSearchWrapper>
            <UserSearch onSearch={handleUserSearch} onReset={handleResetSearch} />
          </PageSearchWrapper>
        )}
        <ActionsWrapper>
          {isAllUsersPage ? (
            <OnboardingTabs isCompaniesPage={isCompaniesPage} totalCountUsers={totalCountUsers} />
          ) : (
            <Title>Users</Title>
          )}

          <ManageUserButtonGroup
            isCompanyPage={!!companyId}
            selectedUsers={selectedUsers}
            handleUserSearch={handleUserSearch}
            handleResetSearch={handleResetSearch}
            isAllUsersPage={isAllUsersPage}
            setModalActivateUserVisible={setModalActivateUserVisible}
            setModalDeactivateUserVisible={setModalDeactivateUserVisible}
            setIsModalManageSignInMethodsVisible={setIsModalManageSignInMethodsVisible}
            setIsModalManageAccessVisible={setIsModalManageAccessVisible}
            setIsAddNewUserVisible={setIsAddNewUserVisible}
            setIsModalAddUserToCompanyVisible={setIsModalAddUserToCompanyVisible}
            setIsModalRemoveUsersFromCompanyVisible={setIsModalRemoveUsersFromCompanyVisible}
            setModalDownloadCsvVisible={setModalDownloadCsvVisible}
          />
        </ActionsWrapper>

        {isAddNewUserVisible && (
          <AddEditUserModal
            setRefreshUsersCount={setRefreshUsersCount}
            handleCloseModal={() => handleCloseModal(setIsAddNewUserVisible)}
            companyId={companyId}
            companyName={companyName}
            isNew
          />
        )}

        {isModalAddUserToCompanyVisible && (
          <AddUserToCompanyModal
            selectedUsers={selectedUsers}
            setRefreshUsersCount={setRefreshUsersCount}
            handleCloseModal={() => handleCloseModal(setIsModalAddUserToCompanyVisible)}
          />
        )}

        {isModalActivateUserVisible && (
          <ChangeUserStatusModal
            isActive={true}
            setRefreshUsersCount={setRefreshUsersCount}
            setSelectedUsers={setSelectedUsers}
            selectedUsers={selectedUsers}
            handleCloseModal={() => handleCloseModal(setModalActivateUserVisible)}
          />
        )}
        {isModalDeactivateUserVisible && (
          <ChangeUserStatusModal
            isActive={false}
            setRefreshUsersCount={setRefreshUsersCount}
            setSelectedUsers={setSelectedUsers}
            selectedUsers={selectedUsers}
            handleCloseModal={() => handleCloseModal(setModalDeactivateUserVisible)}
          />
        )}

        {companyId && isModalRemoveUsersFromCompanyVisible && (
          <RemoveUsersFromCompanyModal
            companyId={companyId}
            selectedUsers={selectedUsers}
            setSelectedUsers={setSelectedUsers}
            setRefreshUsersCount={setRefreshUsersCount}
            handleCloseModal={() => handleCloseModal(setIsModalRemoveUsersFromCompanyVisible)}
          />
        )}

        {isModalManageSignInMethodsVisible && (
          <ManageSignInMethodsModal
            selectedUsers={selectedUsers}
            setRefreshUsersCount={setRefreshUsersCount}
            selectedUsersTitle={selectedUsersTitle}
            handleCloseModal={() => handleCloseModal(setIsModalManageSignInMethodsVisible)}
            allAuthenticationMethods={allAuthenticationMethods}
          />
        )}
        {isModalManageAccessVisible && (
          <ManageAccessModal
            selectedUsersTitle={selectedUsersTitle}
            selectedUsers={selectedUsers}
            handleCloseModal={() => handleCloseModal(setIsModalManageAccessVisible)}
            setRefreshUsersCount={setRefreshUsersCount}
          />
        )}
        {isModalDownloadCsvVisible && (
          <DownloadCsvModal
            handleCloseModal={() => handleCloseModal(setModalDownloadCsvVisible)}
            searchParams={searchParams}
          />
        )}

        <Table gridTemplateColumns="40px 2fr 1fr 2fr 1fr 2fr 2fr 2fr 1fr 120px">
          <ItemsTableHeader
            isAllUsersSelected={isAllUsersSelected}
            isSomeUsersSelected={isSomeUsersSelected}
            handleCheckAll={handleCheckAll}
            filterParams={filterParams}
            setFilterParams={setFilterParams}
            headerItems={usersHeaderItems}
            isUsersList
          />

          {filteredUsers.map((user: User, i: number) => {
            return (
              <UserRow
                key={user.subjectId}
                user={user}
                even={i % 2 === 0}
                selected={isSelected(user.subjectId)}
                handleCheck={handleClickCheckbox}
                allAuthenticationMethods={allAuthenticationMethodsMap}
                setRefreshUsersCount={setRefreshUsersCount}
              />
            );
          })}
        </Table>
        {isLoading && <Loader />}
        {isNoUsers && <NoDataWrapper>No users found</NoDataWrapper>}
        {!debouncedSearchText && (
          <PaginationWrapper>
            <Pagination
              headerData={pagingMetadata}
              currentPage={usersCurrentPage}
              setCurrentPage={setUsersCurrentPageNumber}
            />
          </PaginationWrapper>
        )}
      </ErrorBoundary>
    </PageContent>
  );
};
