import React, { FunctionComponent } from 'react';
import { useModalContext } from '../../../../modal/ModalContext';
import { ButtonGroup, BoxButton } from '@instech/components';
import styled from 'styled-components';

const Text = styled.p`
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  margin-top: 0px;
`;

export const InUseDialog: FunctionComponent = () => {
  const { close } = useModalContext();
  const contentString1 = 'This image is currently in use and cannot be deleted.';
  const contentString2 = 'If you want to delete this image, please remove the image from the article or the card.';

  return (
    <>
      <Text>{contentString1}</Text>
      <Text>{contentString2}</Text>
      <ButtonGroup alignRight>
        <BoxButton onClick={close}>Close</BoxButton>
      </ButtonGroup>
    </>
  );
};
