import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import {
  DefaultErrorMessage,
  ErrorBoundary,
  ButtonGroup,
  SlimButton,
  CollapsiblePane,
  CheckboxControlled,
} from '@instech/components';
import { ModalLayout } from './ModalLayout';
import { SelectedUser } from '../UsersPage/userTypes';
import {
  manageUserRoles,
  useApplicationsRoles,
  fetchUsersApplications,
  RoleOperation,
  RoleAddition,
  RoleRemoval,
} from '../../services/manageAccessService';
import { Check } from '@instech/icons';
import { Loader } from '../shared/Loader';
import { SelectedUsersTable } from '../shared/SelectedUsersTable';
import { ConfirmModalDialog } from '../modal/ConfirmModalDialog';
import { objectsDeepEqual } from '../../utils/object';
import { sortUsers } from '../../utils/sort';
import { SuccessMessageWrapper, ErrorMessageWrapper, StyledButtonGroup } from '../shared/Components';
import { groupBy } from '../../utils/groupBy';
import { currentTime, useLocalStorage } from '../../hooks/useLocalStorage';

interface ManageAccessModalProps {
  handleCloseModal: () => void;
  selectedUsers: SelectedUser[];
  selectedUsersTitle?: string;
  setRefreshUsersCount?: React.Dispatch<React.SetStateAction<number>>;
}
interface PortalRoleProps {
  id: string;
  displayName: string;
  name: string;
  isAdministrative: boolean;
}
interface ApplicationRolesProps {
  roles: PortalRoleProps[];
  allUsersHaveRole: (roleId: string) => boolean;
  someUsersHaveRole: (roleId: string) => boolean;
  portalId: string;
  handleCheck: (event: React.ChangeEvent<HTMLInputElement>, roleId: string) => void;
}
interface PortalProps {
  id: string;
  displayName: string;
  name: string;
  roles: PortalRoleProps[];
}
interface UsersRolesProps {
  subjectId: string;
  roleId: string;
}
interface UserRoleProps {
  applicationId: string | undefined;
  applicationName: string | undefined;
  roleDisplayName: string | undefined;
  roleName: string | undefined;
}
interface UserRoleChanges {
  added: UserRoleProps[];
  removed: UserRoleProps[];
}

const ApplicationRoles = ({
  roles,
  allUsersHaveRole,
  someUsersHaveRole,
  portalId,
  handleCheck,
}: ApplicationRolesProps) => {
  return (
    <>
      {roles.map((role: PortalRoleProps) => (
        <CheckboxControlled
          key={role.name}
          name={role.name + portalId}
          noErrors
          rightLabel={role.displayName}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleCheck(e, role.id)}
          value={role.name}
          selected={allUsersHaveRole(role.id)}
          indeterminate={someUsersHaveRole(role.id)}
        />
      ))}
    </>
  );
};

export const ManageAccessModal = ({
  selectedUsersTitle,
  handleCloseModal,
  selectedUsers,
  setRefreshUsersCount,
}: ManageAccessModalProps) => {
  const [initialUsersRoles, setInitialUsersRoles] = useState<UsersRolesProps[] | []>([]);
  const [selectedUsersRoles, setSelectedUsersRoles] = useState<UsersRolesProps[] | []>([]);
  const [isTouched, setIsTounched] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const selectedUsersIds = selectedUsers.map((su) => su.subjectId);
  const [showUnsavedModal, setShowUnsavedModal] = useState(false);
  const { setItem, getItem } = useLocalStorage('users');
  const localStorageUsers: SelectedUser[] | [] = getItem() ?? [];
  const isInitialUsersRoles = objectsDeepEqual(initialUsersRoles, selectedUsersRoles);

  const modalOptions = {
    size: 'medium',
    title: 'Manage Access',
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await fetchUsersApplications(selectedUsers);
        const usersRoles: UsersRolesProps[] = [];
        res.forEach((user) => {
          user.applications.forEach((app: PortalProps) => {
            app.roles.forEach((role: PortalRoleProps) => {
              const userRole = {
                subjectId: user.subjectId,
                roleId: role.id,
              };
              usersRoles.push(userRole);
            });
          });
        });

        setInitialUsersRoles(usersRoles);
        setSelectedUsersRoles([...usersRoles]);
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, [selectedUsers, isSubmitted]);

  const portals = useApplicationsRoles() as PortalProps[];

  const getRoleDataByRoleId = (roleId: string) => {
    const fullData = portals.find((portal) => portal.roles.some((role) => role.id === roleId));
    const applicationId = fullData?.id;
    const applicationName = fullData?.displayName;
    const role = fullData?.roles.find((r) => r.id === roleId);
    const roleDisplayName = role?.displayName;
    const roleName = role?.name;
    return { applicationId, applicationName, roleDisplayName, roleName };
  };

  const handleCheck = (event: React.ChangeEvent<HTMLInputElement>, roleId: string): void => {
    setIsTounched(true);
    setIsError(false);
    if (event.target.checked) {
      const newAccess = selectedUsersIds.map((subjectId) => ({ subjectId, roleId }));
      const filteredPrevAccess = selectedUsersRoles.filter((a) => a.roleId !== roleId);
      setSelectedUsersRoles([...filteredPrevAccess, ...newAccess]);
    } else {
      setSelectedUsersRoles((prev) => prev.filter((p) => p.roleId !== roleId));
    }
  };

  const handleSubmit = () => {
    setIsSubmitting(true);
    setIsSubmitted(false);
    setIsError(false);

    const operations: { subjectId: string; operation: RoleOperation }[] = [];

    selectedUsersRoles.forEach((role) => {
      const initialRole = initialUsersRoles.findIndex(
        (i) => i.subjectId === role.subjectId && i.roleId === role.roleId
      );
      initialRole === -1 && operations.push({ subjectId: role.subjectId, operation: new RoleAddition(role.roleId) });
    });

    initialUsersRoles.forEach((role) => {
      const selectedRole = selectedUsersRoles.findIndex(
        (s) => s.subjectId === role.subjectId && s.roleId === role.roleId
      );
      selectedRole === -1 && operations.push({ subjectId: role.subjectId, operation: new RoleRemoval(role.roleId) });
    });

    const usersRolesChanges: { [key: string]: UserRoleChanges } = {};

    operations.forEach((operation) => {
      const subjectId = operation.subjectId;
      const type = operation.operation.getType();
      const role = getRoleDataByRoleId(operation.operation.roleId);
      if (!usersRolesChanges[subjectId]) {
        usersRolesChanges[subjectId] = { added: [], removed: [] };
      }
      if (type === 'add') {
        usersRolesChanges[subjectId].added.push(role);
      } else {
        usersRolesChanges[subjectId].removed.push(role);
      }
    });

    const usersToChange: SelectedUser[] = [];

    selectedUsers.forEach((user) => {
      if (!!usersRolesChanges[user.subjectId]) {
        const addedRoles = usersRolesChanges[user.subjectId].added;
        const removedRoles = usersRolesChanges[user.subjectId].removed;
        const updatedRoles = user.roles.filter(
          (role: UserRoleProps) =>
            !removedRoles.some(
              (removedRole) =>
                removedRole.applicationId?.toLowerCase() === role.applicationId?.toLowerCase() &&
                removedRole.roleName === role.roleName
            )
        );
        updatedRoles.push(...addedRoles);
        usersToChange.push({ ...user, roles: updatedRoles });
      }
    });
    const filteredLocalstorageUsers = localStorageUsers.filter(
      (localStorageUser) =>
        !usersToChange.some((userToChange: SelectedUser) => userToChange.subjectId === localStorageUser.subjectId)
    );

    const promises = Array.from(groupBy(operations, (it) => it.subjectId)).map(([subjectId, values]) =>
      manageUserRoles(
        subjectId,
        values.map((it) => it.operation)
      )
    );

    promises.length > 0 &&
      Promise.all(promises)
        .then(() => {
          const updatedLocalStorageUsers = usersToChange.map((u) => ({
            ...u,
            isLocalStorageItem: true,
            timestamp: currentTime,
          }));
          setItem([...updatedLocalStorageUsers, ...filteredLocalstorageUsers]);
          setRefreshUsersCount && setRefreshUsersCount((prev) => prev + 1);
          setIsTounched(false);
          //set timeout because of the delayed response from the server
          setTimeout(() => {
            setIsSubmitted(true);
            setIsSubmitting(false);
          }, 2000);
          setTimeout(() => {
            handleCloseModal();
          }, 3000);
        })
        .catch((error) => {
          setIsSubmitting(false);
          setIsSubmitted(false);
          setIsError(true);
        });
  };

  const allUsersHaveRole = (roleId: string) =>
    selectedUsersIds.every((id) =>
      selectedUsersRoles.find((access) => access.subjectId === id && access.roleId === roleId)
    );

  const someUsersHaveRole = (roleId: string) =>
    selectedUsersIds.some((id) =>
      selectedUsersRoles.find((access) => access.subjectId === id && access.roleId === roleId)
    );

  const handleClose = () => {
    isTouched && setShowUnsavedModal(true);
    !isTouched && !showUnsavedModal && handleCloseModal();
  };

  const sortedSelectedUsers = selectedUsers.slice().sort(sortUsers);

  return (
    <ModalLayout closeModal={handleClose} options={modalOptions}>
      <ErrorBoundary component={DefaultErrorMessage}>
        <SelectedUsersTable
          color="green"
          title={selectedUsersTitle}
          selectedUsers={sortedSelectedUsers}
          startCollapsed={true}
        />
        {portals ? (
          portals.map((portal: PortalProps) => {
            const countRoles = new Set(
              selectedUsersRoles
                .filter((role) => portal.roles.some((appRole) => appRole.id === role.roleId))
                .map((role) => role.roleId)
            ).size;

            const roleCountDisplay = countRoles > 0 ? ` (${countRoles})` : '';
            const title = `${portal.displayName}${roleCountDisplay}`;
            return (
              <CollapsiblePane key={portal.id} title={title} startCollapsed={true}>
                <ApplicationRoles
                  roles={portal.roles}
                  allUsersHaveRole={allUsersHaveRole}
                  someUsersHaveRole={someUsersHaveRole}
                  portalId={portal.id}
                  handleCheck={handleCheck}
                />
              </CollapsiblePane>
            );
          })
        ) : (
          <Loader />
        )}
        <StyledButtonGroup alignRight>
          <SlimButton variant="secondary" onClick={handleClose}>
            CANCEL
          </SlimButton>
          <SlimButton variant="primary" onClick={handleSubmit} disabled={!isTouched || isInitialUsersRoles}>
            {isSubmitting ? <Loader size="small" /> : 'SAVE'}
          </SlimButton>
        </StyledButtonGroup>
        {isSubmitted && !isTouched && (
          <SuccessMessageWrapper>
            <Check />
            Changes saved successfully.
          </SuccessMessageWrapper>
        )}
        {isError && <ErrorMessageWrapper>Something went wrong.</ErrorMessageWrapper>}
        {isTouched && showUnsavedModal && (
          <ConfirmModalDialog
            text="Are you sure you want to leave? You have unsaved changes."
            cancelButtonText="Cancel"
            confirmButtonText="Leave"
            onCancel={() => setShowUnsavedModal(false)}
            onConfirm={handleCloseModal}
          />
        )}
      </ErrorBoundary>
    </ModalLayout>
  );
};
