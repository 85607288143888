import { useModalContext } from '../../components/modal/ModalContext';
import { Loader } from '../../components/shared/Loader';
import { createEmptyDraft } from '../../services/announcementsServices';
import React, { useEffect, useState } from 'react';
import { Route, Outlet, useNavigate } from 'react-router-dom';
import { CancelUpdateConfirmationModalData, openConfirmationModal } from './core/ConfirmationModal';
import { ProgressProvider } from './core/ProgressContext';
import { AnnouncementStateProvider } from './core/AnnouncementStateContext';
import { PreviewPublish } from './PreviewPublish';
import { SelectRecipients } from './SelectRecipients';
import { AnnouncementContents } from './AnnouncementContents';
import { routes, useCurrentPath } from './utils';

export type Step = { step: number; name: string; route: string };
const steps: Step[] = [
  {
    step: 1,
    name: 'Product & Recipients',
    route: 'recipients',
  },
  {
    step: 2,
    name: 'Text, Images & Video',
    route: 'content',
  },
  {
    step: 3,
    name: 'Preview & Publish',
    route: 'preview',
  },
];

const CreateDraftAndRedirect = ({ children }: any) => {
  const [announcementId, setAnnouncementId] = useState<string | null>(null);
  const navigate = useNavigate();

  useEffect(() => {
    createEmptyDraft()
      .then((result) => setAnnouncementId(result.id))
      .catch((error) => {
        throw new Error(error);
      });
  }, []);

  useEffect(() => {
    if (announcementId) {
      navigate(`${announcementId}`);
    }
  }, [announcementId]);

  if (!announcementId) return <Loader />;

  return children;
};

export const NewAnnouncementPage = () => {
  const path = useCurrentPath();

  return (
    <ProgressProvider initialPath={path}>
      <AnnouncementStateProvider>
        <Outlet />
      </AnnouncementStateProvider>
    </ProgressProvider>
  );
};

export const NewAnnouncementRoutes = () => {
  const { open } = useModalContext();
  const handleCancel = (returnToCallBack?: () => void) => {
    const args = { ...CancelUpdateConfirmationModalData, returnToCallBack };
    open(openConfirmationModal({ title: 'Discard changes' }, args));
  };

  return [
    <Route key="new-announcement-parent" path={routes.newAnnouncementParent} element={<NewAnnouncementPage />}>
      <Route key="new-announcement" path={routes.newAnnouncement} element={<CreateDraftAndRedirect />} />
      <Route
        key="recipients"
        path={routes.recipients}
        element={<SelectRecipients steps={steps} stepNum={0} handleCancel={handleCancel} />}
      />
      <Route
        key="content"
        path={routes.content}
        element={<AnnouncementContents steps={steps} stepNum={1} handleCancel={handleCancel} />}
      />
      <Route
        key="preview"
        path={routes.preview}
        element={<PreviewPublish steps={steps} stepNum={2} handleCancel={handleCancel} />}
      />
    </Route>,
  ];
};
