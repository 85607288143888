import React from 'react';
import styled from 'styled-components';
import { getFriendlyDate, SlimButton } from '@instech/components';
import { Loader } from '../../../shared/Loader';
import { useModalContext } from '../../../modal/ModalContext';
import { Announcement } from '../../../../types';
import { ButtonGroup, Header, ModalLayoutWithClose } from '../../core/ModalComponents';

const getDesription = (announcement: Announcement) => {
  const dateTime =
    announcement.notVisibleBefore && getFriendlyDate(announcement.notVisibleBefore, 'D MMM. YYYY - HH:mm');
  const { selectedCount } = announcement.recipientStructure;
  return `The update ${dateTime ? 'will be' : 'was'} published to ${selectedCount} recipients ${
    dateTime ? `on the ${dateTime} (UTC)` : ''
  }`;
};

const Description = styled.div`
  max-width: 350px;
  text-align: center;
  font-size: 18px;
  color: ${(props) => props.theme.marineBlue};
`;

interface Props {
  announcement: Announcement;
  returnToAnnouncementsPage: () => void;
}
const NotificationModal = ({ announcement, returnToAnnouncementsPage }: Props) => {
  const { close } = useModalContext();

  if (!announcement) return <Loader />;

  const onClick = () => {
    close();
    returnToAnnouncementsPage();
  };

  return (
    <ModalLayoutWithClose close={onClick}>
      <Header>{`Update ${announcement.notVisibleBefore ? 'added to Scheduled Publish' : 'published'}`}</Header>
      <Description>{getDesription(announcement)}</Description>
      <ButtonGroup>
        <SlimButton width="180px" onClick={onClick}>
          Close
        </SlimButton>
      </ButtonGroup>
    </ModalLayoutWithClose>
  );
};

export const openNotificationModal = ({ announcement, returnToAnnouncementsPage }: Props) => ({
  component: NotificationModal,
  options: {
    title: '',
    size: 'small',
    padding: 0,
  },
  args: { announcement, returnToAnnouncementsPage },
});
