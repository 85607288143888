import * as Yup from 'yup';
import moment from 'moment';

export const validationSchema = Yup.object().shape({
  date: Yup.string()
    .nullable()
    .required('You must pick a date')
    .test('Is valid  ISO date', 'Invalid date', (value) => moment.utc(value, moment.ISO_8601, true).isValid())
    .test('Is in the future', 'The date must be in the future', (value) =>
      moment.utc(value).isSameOrAfter(moment().subtract(1, 'days'))
    ),
  time: Yup.string()
    .required('You must pick a time')
    .when('date', {
      is: (date: string) => moment(date).isSame(moment(), 'day'),
      then: (schema) =>
        schema.test('Is in the future', 'The time must be in the future', (value) =>
          moment.utc(value, ['HH:mm']).isAfter(moment.utc())
        ),
    }),
});
