import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

export const useRedirect = (to: string) => {
  const navigate = useNavigate();
  const [shouldRedirect, setShouldRedirect] = useState<boolean>(false);

  useEffect(() => {
    if (shouldRedirect) {
      setShouldRedirect(false);
      navigate(to);
    }
  }, [shouldRedirect, navigate, to]);

  return setShouldRedirect;
};
