import styled from 'styled-components';
import { Pen, Enlarge, CharacterOrder, CharacterReverseOrder, Close } from '@instech/icons';
import { ButtonGroup } from '@instech/components';

export const EditIcon = styled(Pen)`
  cursor: pointer;
  height: 14px;
`;
export const EnlargeIcon = styled(Enlarge)`
  width: 16px;
  cursor: pointer;
  margin: 4px 0 0 4px;
`;
export const CharacterReverseOrderIcon = styled(CharacterReverseOrder)`
  cursor: pointer;
  width: 18px;
  height: 18px;
  color: ${(props) => props.theme.marineBlue75};
  margin: 4px 0 0 4px;
`;
export const CharacterOrderIcon = styled(CharacterOrder)`
  cursor: pointer;
  width: 18px;
  height: 18px;
  color: ${(props) => props.theme.marineBlue75};
  margin: 4px 0 0 4px;
`;
export const CloseIcon = styled(Close)`
  cursor: pointer;
  width: 14px;
  height: 14px;
  color: ${(props) => props.theme.marineBlue75};
  margin: 0 4px 0 4px;
`;
export const SuccessMessageWrapper = styled.div`
  display: flex;
  color: ${(props) => props.theme.green};
  justify-content: right;
  margin-top: 32px;
`;
export const ErrorMessageWrapper = styled.div`
  display: flex;
  color: ${(props) => props.theme.red};
  margin-top: 32px;
  justify-content: right;
`;
export const StyledLabel = styled.div`
  display: block;
  color: ${(props) => props.theme.marineBlue};
  font-weight: bold;
  margin-bottom: 12px;
`;
export const SelectWrapper = styled.div`
  margin: 32px 0;
`;
export const NoDataWrapper = styled.div`
  color: ${(props) => props.theme.marineBlue};
  padding: 16px;
  font-size: 18px;
  background-color: ${(props) => props.theme.white};
`;
export const Column = styled.div`
  display: flex;
  flex-direction: column;
  grid-row-gap: 16px;
  justify-content: space-between;
  button {
    text-transform: uppercase;
  }
`;
export const Tooltip = styled.div`
  position: relative;
  cursor: pointer;
  padding: 4px;
  :hover {
    span {
      visibility: visible;
      opacity: 1;
    }
  }
`;
export const TooltipText = styled.span`
  position: absolute;
  left: 50%;
  top: -30px;
  transform: translateX(-50%);
  background: ${(props) => props.theme.marineBlue};
  color: ${(props) => props.theme.white};
  white-space: nowrap;
  padding: 4px;
  border-radius: 4px;
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.5s ease;
  ::before {
    content: '';
    position: absolute;
    left: 50%;
    top: 100%;
    transform: translateX(-50%);
    border: 8px solid;
    border-color: #003c71 #3c71 #3c71 #3c71;
  }
`;
export const CrmLabel = styled.div`
  padding: 4px;
  border-radius: 4px;
  background: ${(props) => props.theme.background.primary};
`;
export const StyledButtonGroup = styled(ButtonGroup)`
  margin-top: ${(props) => (props.marginTop ? props.marginTop : '32px')};
  display: flex;
  grid-column-gap: 16px;
  justify-content: ${(props) => (props.alignRight ? 'flex-end' : 'flex-start')};
  button {
    padding: 16px;
    min-width: 120px;
    height: 46px;
    div {
      padding: 0;
    }
  }
`;
