import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import { PageContentWide, MainColumn } from '../layout/Page';
import { useDrafts, usePublished, useScheduled, useUpdateApplicationNames } from '../../services/announcementsServices';
import { PageLoader } from '../shared/Loader';
import { ApplicationRole, ContextMenuCallbacks } from '@/types';
import { CategoryPane } from './core/CategoryPane';
import { AnnouncementCards } from './core/AnnouncementCards/AnnouncementCards';
import { PublishedTable } from './core/PublishedTable';
import { useRefreshAfterScheduledIsPublished } from './core/useRefreshAfterScheduledIsPublished';

const getNumberedHeading = (title: string, array: any[]) => {
  if (!array.length) return `${title} - 0`;
  return `${title} - ${array.length}`;
};

const Subheading = styled.div`
  color: ${(props) => props.theme.marineBlue};
  font-weight: bold;
  font-size: 18px;
  margin: 20px 0px 8px;
`;

export const AnnouncementsPage = () => {
  const draftsSectionRef = useRef<null | HTMLDivElement>(null);
  const [activeTab, setActiveTab] = useState({ displayName: 'All Announcements', name: 'All' });
  const { data: drafts, mutate: refreshDrafts } = useDrafts(activeTab.displayName);
  const { data: scheduled, mutate: refreshScheduled } = useScheduled(activeTab.displayName);
  const { data: published, mutate: refreshPublished } = usePublished(activeTab.displayName);
  const { data: applications } = useUpdateApplicationNames();

  useRefreshAfterScheduledIsPublished(refreshPublished, refreshScheduled, scheduled);

  if (!drafts || !scheduled || !published || !applications) return <PageLoader />;

  const draftsHeading = getNumberedHeading('Drafts', drafts);
  const scheduledHeading = getNumberedHeading('Scheduled Publish', scheduled);
  const publishedHeading = getNumberedHeading('Published', published);

  const handleSettingActiveTab = (portal: ApplicationRole) => {
    setActiveTab(portal);
  };

  const handleRefreshingScheduledUpdates = async () => {
    const refreshScheduledPromise = refreshScheduled();
    const refreshDraftsPromise = refreshDrafts();

    await Promise.all([refreshScheduledPromise, refreshDraftsPromise]);
  };

  const handleUpdateDuplication = async () => {
    await refreshDrafts();
    draftsSectionRef.current!.scrollIntoView({ behavior: 'smooth' });
  };

  const handleRefreshingPublishedUpdates = async () => {
    const refreshPublishedPromise = refreshPublished();
    const refreshDraftsPromise = refreshDrafts();

    await Promise.all([refreshPublishedPromise, refreshDraftsPromise]);
  };

  const refreshingPublishedUpdatesCallbacks: ContextMenuCallbacks = {
    onWithdrawn: handleRefreshingPublishedUpdates,
    onDublicate: handleUpdateDuplication,
  };

  const refreshingScheduledUpdatesCallbacks: ContextMenuCallbacks = {
    onWithdrawn: handleRefreshingScheduledUpdates,
    onDublicate: handleUpdateDuplication,
  };

  const refreshingDraftsUpdatesCallbacks: ContextMenuCallbacks = {
    onDraftDelete: refreshDrafts,
    onDublicate: handleUpdateDuplication,
  };

  return (
    <PageContentWide>
      <MainColumn>
        <CategoryPane applications={applications} activeTab={activeTab} setActiveTab={handleSettingActiveTab} />
        <Subheading ref={draftsSectionRef}>{draftsHeading}</Subheading>
        <AnnouncementCards announcements={drafts} status="draft" callbacks={refreshingDraftsUpdatesCallbacks} />
        <Subheading>{scheduledHeading}</Subheading>
        <AnnouncementCards
          announcements={scheduled}
          status="scheduled"
          callbacks={refreshingScheduledUpdatesCallbacks}
        />
        <Subheading>{publishedHeading}</Subheading>
        <PublishedTable announcements={published} status="published" callbacks={refreshingPublishedUpdatesCallbacks} />
      </MainColumn>
    </PageContentWide>
  );
};
