import React from 'react';
import styled from 'styled-components';
import { PathMatch, useMatch } from 'react-router-dom';
import { CompaniesPage } from '../CompaniesPage/CompaniesPage';
import { UsersPage } from '../UsersPage/UsersPage';

const PageContent = styled.div`
  margin: 0 5%;
`;

export const Onboarding = () => {
  const homeMatch: PathMatch<string> | null = useMatch('/');
  const companiesMatch: PathMatch<string> | null = useMatch('/companies/*');
  const usersMatch: PathMatch<string> | null = useMatch('/users/*');
  const isCompaniesPage = homeMatch !== null || companiesMatch !== null;
  return (
    <PageContent>
      {isCompaniesPage && <CompaniesPage isCompaniesPage={isCompaniesPage} />}
      {usersMatch && <UsersPage isCompaniesPage={isCompaniesPage} />}
    </PageContent>
  );
};
