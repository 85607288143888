import React, { useState } from 'react';
import { TableCell, TableRow } from '../shared/Table';
import { AddCompanyModal } from '../modal/AddCompanyModal';
import { CrmLabel } from '../shared/Components';
import { Company, SourceSystem } from '../../types';
import { useNavigate } from 'react-router-dom';
import { EditCompanyModal } from '../modal/EditCompanyModal';
import { EditIcon } from '../shared/Components';
import styled from 'styled-components';

const StyledTableCell = styled(TableCell)`
  justify-content: right;
  padding-right: 16px;
`;

interface CompanyRowProps {
  company: Company;
  even: boolean;
  addCompanyId: string | null;
  setAddCompanyId: (id: string | null) => void;
  setRefreshCompaniesCount: React.Dispatch<React.SetStateAction<number>>;
}

export const CompanyRow = ({
  company,
  even,
  addCompanyId,
  setAddCompanyId,
  setRefreshCompaniesCount,
}: CompanyRowProps) => {
  const { id, name, type, companyNumber, city, country, email, sourceSystem, isLocalStorageItem } = company;
  const isCrmCompany = sourceSystem === SourceSystem.Crm;

  const [isEditCompanyVisible, setIsEditCompanyVisible] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const openEditCompany = () => setIsEditCompanyVisible(true);

  const navigate = useNavigate();

  const handleRowClick = () => {
    if (isCrmCompany) {
      id && setAddCompanyId(id);
    } else {
      navigate(`/companies/${id}`);
    }
  };
  type TableCellProps = {
    content: React.ReactNode;
    clickHandler: () => void;
    key: number;
  };

  const renderTableCell = ({ content, clickHandler, key }: TableCellProps) => (
    <TableCell key={key} onClick={clickHandler}>
      {content}
    </TableCell>
  );
  const companyDetails = [name, type, companyNumber, city, country, email];

  return (
    <>
      {addCompanyId === id && (
        <AddCompanyModal
          company={company}
          handleCloseModal={() => setAddCompanyId(null)}
          isCrmCompany={isCrmCompany}
          setRefreshCompaniesCount={setRefreshCompaniesCount}
        />
      )}
      {isEditCompanyVisible && (
        <EditCompanyModal
          handleCloseModal={() => setIsEditCompanyVisible(false)}
          company={company}
          setRefreshCompaniesCount={setRefreshCompaniesCount}
        />
      )}
      <TableRow
        even={isLocalStorageItem ? !even : even}
        isLocalStorageItem={isLocalStorageItem}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        {companyDetails.map((detail, i) => renderTableCell({ content: detail, clickHandler: handleRowClick, key: i }))}

        {isCrmCompany ? (
          <TableCell>
            <CrmLabel>Import From CRM</CrmLabel>
          </TableCell>
        ) : (
          <StyledTableCell onClick={openEditCompany}>{isHovered && !isCrmCompany && <EditIcon />}</StyledTableCell>
        )}
      </TableRow>
    </>
  );
};
