import { duplicateAnnouncement } from '../../../services/announcementsServices';
import { ContextMenuCallbacks, Announcement, AnnouncementStatus } from '../../../types';
import { MenuOption } from '@instech/components';
import { useNavigate } from 'react-router-dom';
import { useModalContext } from '../../modal/ModalContext';
import { routes } from '../../NewAnnouncementPage/utils';
import { openConfirmDeleteDraftModal } from './ConfirmDeleteDraftModal';
import { openConfirmWithdrawModal } from './ConfirmWithdrawModal';

export const useContextMenuOptions = (
  announcement: Announcement,
  status: AnnouncementStatus,
  callbacks: ContextMenuCallbacks
) => {
  const navigate = useNavigate();
  const editRoute = routes.content.replace(':announcementId', announcement.id);
  const { open } = useModalContext();

  const options: MenuOption[] = [
    {
      label: 'Edit',
      onClick: () => navigate(editRoute),
    },
    {
      label: 'Duplicate',
      onClick: announcement.operations.duplicate
        ? () => {
            duplicateAnnouncement(announcement.id)
              .then(() => {
                if (callbacks.onDublicate) callbacks.onDublicate();
              })
              .catch((error) => {
                throw new Error(error);
              });
          }
        : undefined,
    },
  ];

  if (status === 'draft' && callbacks.onDraftDelete) {
    const confirmDeleteModal = openConfirmDeleteDraftModal(announcement.id, callbacks.onDraftDelete);
    options.push({
      label: 'Delete',
      onClick: announcement.operations.delete ? () => open(confirmDeleteModal) : undefined,
    });
  }

  if ((status === 'scheduled' || status === 'published') && callbacks.onWithdrawn) {
    const confirmWithdrawModal = openConfirmWithdrawModal(
      announcement.id,
      announcement.recipientStructure.selectedCount,
      callbacks.onWithdrawn
    );
    options.push({
      label: 'Withdraw',
      onClick: announcement.operations.withdraw ? () => open(confirmWithdrawModal) : undefined,
    });
  }

  return options;
};
