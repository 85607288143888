import React from 'react';
import styled from 'styled-components';
import { usePagination, DOTS } from './usePagination';

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
const PageNumber = styled.button<{
  selected?: boolean;
  isPageChecked?: boolean;
}>`
  background: ${(props) =>
    props.selected ? props.theme.border.blueGray : props.isPageChecked ? props.theme.lightGreen : props.theme.white};
  color: ${(props) => props.theme.marineBlue};
  font-size: 16px;
  padding: 0;
  margin: 0;
  border: 1px solid ${(props) => props.theme.lightBlue};
  width: 48px;
  height: 48px;
  cursor: pointer;

  :hover {
    background: ${(props) => props.theme.lightBlue};
    border: 1px solid transparent;
  }
  :focus,
  :active {
    border: 1px solid transparent;
    outline: none;
  }

  :disabled {
    opacity: 0.5;
  }
`;
interface PagingMetadata {
  currentPage: number;
  hasPrevious: boolean;
  hasNext: boolean;
  pageSize: number;
  totalCount: number;
  totalPages: number;
}
interface PaginationProps {
  headerData: PagingMetadata;
  currentPage: number;
  setCurrentPage: (newPage: number) => void;
}
export const Pagination = ({ headerData, currentPage, setCurrentPage }: PaginationProps) => {
  const totalCount = headerData?.totalCount;
  const pageSize = headerData?.pageSize;

  const numberOfPagesArray = usePagination({
    currentPage,
    totalCount,
    pageSize,
  });

  const lastPage = numberOfPagesArray && numberOfPagesArray[numberOfPagesArray.length - 1];

  const onNext = () => {
    setCurrentPage(currentPage + 1);
  };

  const onPrevious = () => {
    setCurrentPage(currentPage - 1);
  };
  return (
    <Wrapper>
      <PageNumber onClick={onPrevious} disabled={currentPage === 1}>
        &#60;
      </PageNumber>
      {numberOfPagesArray?.map((n, i) => {
        return (
          <div key={i}>
            {n === DOTS ? (
              DOTS
            ) : (
              <PageNumber
                onClick={() => {
                  setCurrentPage(Number(n));
                }}
                selected={n === currentPage}
              >
                {n}
              </PageNumber>
            )}
          </div>
        );
      })}
      <PageNumber onClick={onNext} disabled={currentPage === lastPage}>
        &#62;
      </PageNumber>
    </Wrapper>
  );
};
