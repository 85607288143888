import React from 'react';
import styled from 'styled-components';
import { Table, TableCell, TableRow } from '../shared/Table';
import { CollapsiblePane } from '@instech/components';
import { SelectedUser } from '../UsersPage/userTypes';

const StyledTableRow = styled(TableRow)`
  span {
    font-weight: 600;
    border-top: 2px solid ${(props) => props.theme.marineBlue};
    border-bottom: 2px solid ${(props) => props.theme.marineBlue};
    background: ${(props) => props.theme.lightGreen};
  }
`;

interface Props {
  selectedUsers: SelectedUser[];
  title?: string;
  startCollapsed?: boolean;
  color?: string;
}
export const SelectedUsersTable = ({ color, title, selectedUsers, startCollapsed }: Props) => {
  return (
    <CollapsiblePane title={title} startCollapsed={startCollapsed} color={color}>
      <Table columns={2}>
        {selectedUsers.map((user, i) => (
          <StyledTableRow even={i % 2 === 0} key={user.subjectId}>
            <TableCell>{user.name}</TableCell>
            <TableCell>{user.email}</TableCell>
          </StyledTableRow>
        ))}
      </Table>
    </CollapsiblePane>
  );
};
