import useSWR, { useSWRConfig, mutate as globalMutate, SWRConfig } from 'swr';
import client, { getJsonAsync, getWithRetry, postFormAsync, putJsonAsync, RetryConfig } from '../client/client';
import { Guid } from '../../types';
import { useAnnouncementIdFromUrl } from '../../hooks/useAnnouncementIdFromUrl';
import { trimString } from '../../utils/text';
import { AxiosResponse } from 'axios';

export type Crop = 'small' | 'medium' | 'large' | 'original';

export type DeleteImageStatus = 'Inuse' | 'Error' | 'Success';

export interface ImageForm {
  image: File;
  title: string;
  description: string;
}

export interface ImageModel {
  url?: string;
  id: Guid;
  announcementId: Guid;
  title: string;
  description: string;
  uploadedAt: string;
  uploadedBy: string;
  fileName: string;
  fileSizeInBytes: number;
  width: number;
  height: number;
}

export type CropStatus = 'Pending' | 'Error' | 'Success';
export interface ImagePreview {
  id: Guid;
  announcementId: Guid;
  crop: Crop;
  title: string;
  description: string;
  url: string;
  cropStatus: CropStatus;
}

const apiRoute = (announcementId: Guid) => `announcements/${announcementId}/images`;
const imageRoute = (announcementId: Guid, imageId: Guid) => `announcements/${announcementId}/images/${imageId}`;

const isImageInUseAsync = async (announcementId: Guid, imageId: Guid) => {
  const route = imageRoute(announcementId, imageId);
  const check = await getJsonAsync(`${route}/inuse`);
  return check.inUse;
};

export const deleteImageAsync = async (announcementId: Guid, imageId: Guid) => {
  const route = imageRoute(announcementId, imageId);
  const result = await client.delete(route);
  return result;
};

export const uploadImageAsync = async (announcementId: Guid, form: ImageForm) => {
  const imageListUrl = apiRoute(announcementId);
  const formData = new FormData();
  formData.append('image', form.image);
  formData.set('title', trimString(form.title));
  formData.set('description', form.description);

  return globalMutate(imageListUrl, async (currentData: Guid[] | undefined) => {
    const result: ImageModel = await postFormAsync(imageListUrl, formData);
    return [result.id, ...(currentData || [])];
  });
};

export const useImageList = (announcementId: Guid) => {
  const swrImageListUrl = `${apiRoute(announcementId)}`;

  const { data, mutate, error } = useSWR<Guid[]>(swrImageListUrl);

  const deleteImage = async (imageId: Guid): Promise<DeleteImageStatus> => {
    const imageInUse = await isImageInUseAsync(announcementId, imageId);
    if (imageInUse) {
      return 'Inuse';
    }
    const result = await deleteImageAsync(announcementId, imageId);
    if (result) {
      await mutate((prev) => prev?.filter((i) => i !== imageId));
      return 'Success';
    }
    return 'Error';
  };

  return {
    images: data,
    deleteImage,
    error,
  };
};

const updateCropMetadataAsync = (imageKey: Guid, title: string, description: string) => {
  /*   const { cache: globalCache } = useSWRConfig();
  const base = `${imageKey}/link?crop`;
  const keys =
    globalCache.keys().filter((k) => typeof k === 'string' && !k.startsWith('err') && k.includes(base)) || [];
  const updates = [];

  for (let x = 0; x < keys?.length; x += 1) {
    const key = keys[x];
    const value = globalCache.get(keys[x]);
    const data = {
      ...value,
      title,
      description,
    };
    const mutatePromise = globalMutate(key, data, false);
    //updates.push(mutatePromise);
  }
  return Promise.all(updates); */
  return;
};

export interface UpdateImageRequest {
  title: string;
  description: string;
}

export const updateImageMetadataAsync = async (announcementId: Guid, imageId: Guid, data: UpdateImageRequest) => {
  const swrKey = imageRoute(announcementId, imageId);
  const result = await putJsonAsync(swrKey, data);
  updateCropMetadataAsync(swrKey, result.title, result.description);
  return result;
};

export const defaultFetchConfig = {
  retryCount: 10,
  errorRetryInterval: 800,
  retryCondition: (response: AxiosResponse<ImagePreview>) => response.data.cropStatus === 'Pending',
};

const getImagePreview = (config?: RetryConfig<ImagePreview>) => async (url: string) => {
  const configOrDefault = config ?? {};
  const response = await getWithRetry<ImagePreview>(url, {
    ...defaultFetchConfig,
    ...configOrDefault,
  });
  return response.data;
};

const imagePreviewUrl = (announcementId: string, id: string, crop: Crop) =>
  `${imageRoute(announcementId, id)}/link?crop=${crop}`;

// Fetch ImagePreview crop data, announcementId from URL
export const useImagePreviewById = (id: string, crop: Crop, fetchConfig?: RetryConfig<ImagePreview>) => {
  const announcementId = useAnnouncementIdFromUrl();
  const url = imagePreviewUrl(announcementId, id, crop);
  return useSWR<ImagePreview>(url, getImagePreview(fetchConfig));
};

// Fetch ImagePreview crop data
export const useImagePreviewByIdAndAnnouncementId = (
  announcementId: string,
  id: string,
  crop: Crop,
  fetchConfig?: RetryConfig<ImagePreview>
) => {
  const url = imagePreviewUrl(announcementId, id, crop);
  return useSWR<ImagePreview>(url, getImagePreview(fetchConfig));
};

// Fetch ImageModel metadata
export const useImageMetaById = (id: string) => {
  const announcementId = useAnnouncementIdFromUrl();
  const swrImageMeta = imageRoute(announcementId, id);
  const { data } = useSWR<ImageModel>(swrImageMeta);
  return { data };
};
