import { useLocation, matchRoutes } from 'react-router-dom';

const rootUrl = '/announcementsPage/new-announcement';
const getRoute = (route: string) => `${rootUrl}${route}`;

export const routes = {
  newAnnouncementParent: getRoute('/'),
  newAnnouncement: getRoute('/recipients'),
  recipients: getRoute('/recipients/:announcementId'),
  content: getRoute('/content/:announcementId'),
  preview: getRoute('/preview/:announcementId'),
};

export const getReturnToCallBack = (setShouldRedirect: (val: boolean) => void, resetForm: () => void) => async () => {
  resetForm();
  setShouldRedirect(true);
};

export const useCurrentPath = () => {
  const location = useLocation();
  const currentLocation = location.pathname;
  const allRoutes = Object.values(routes).map((r) => ({ path: r }));
  const match = matchRoutes(allRoutes, currentLocation);
  if (match) {
    const [{ route }] = match;
    return route.path;
  } else {
    return '';
  }
};
