import React, { useEffect, useState, createContext, useContext, ReactNode, useMemo } from 'react';
import { UserInfo, UserRole } from '../types';
import { getUser, getUserRoles } from '../services/userService';
import { PageLoader } from '@instech/components';

interface AuthContextProps {
  user: UserInfo | null;
  userRole: UserRole | null;
  account: { user: { email: string | undefined; name: string | undefined }; logout: () => void };
  isLoading: boolean;
  hasUserAccess: boolean;
}

const AuthContext = createContext<AuthContextProps>({
  user: null,
  userRole: null,
  account: { user: { email: '', name: '' }, logout: () => {} },
  isLoading: true,
  hasUserAccess: false,
});

interface AuthContextProviderProps {
  children: ReactNode;
  intervalValue: number;
}

export const AuthContextProvider = ({ children, intervalValue }: AuthContextProviderProps) => {
  const [user, setUser] = useState<UserInfo | null>(null);
  const [userRole, setUserRole] = useState<UserRole | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [hasUserAccess, setHasUserAccess] = useState<boolean>(false);

  const account = {
    user: {
      email: user?.email,
      name: user?.name,
    },
    logout: () => {
      window.open(`/management/logout?sid=${user?.sid}`);
    },
  };

  useEffect(() => {
    const intervalId = setInterval(async () => {
      const userInfo = await getUser();
      if (userInfo) {
        setUser(userInfo);
        const userRoles = await getUserRoles();
        const isAccess = userRoles?.roles.length > 0;
        setHasUserAccess(isAccess);
      }
    }, intervalValue);

    return () => clearInterval(intervalId);
  }, [intervalValue]);

  useEffect(() => {
    const fetchUser = async () => {
      const userInfo = await getUser();
      if (userInfo) {
        setUser(userInfo);
        const userRoles = await getUserRoles();
        const isAccess = userRoles?.roles.length > 0;
        setHasUserAccess(isAccess);
        userRoles && setUserRole(userRoles.roles[0]);
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    };
    fetchUser();
  }, []);

  const value: AuthContextProps = useMemo(
    () => ({
      user,
      userRole,
      account,
      isLoading,
      hasUserAccess,
    }),
    [user, userRole, account, isLoading, hasUserAccess]
  );

  if (isLoading) {
    return <PageLoader />;
  }

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuthContext = () => useContext<AuthContextProps>(AuthContext);
